/**
 * 		File:		./src/context/campaigns/campaignsReducer.js
 *
 * 		Subject:	Campaign's state reducer
 */

import {
	
	/* CAMPAIGNS */
	CLOSE_DELETE_CAMPAIGN_CONFIRM,
	DELETE_CAMPAIGN_CONFIRM,
	EDIT_CAMPAIGN,
  	GET_CAMPAIGNS,
	SET_CAMPAIGN_FOREIGNS,

	/* CAMPAIGN DETAILS */
	CAMPAIGN_DIVISIONS_ID_CHANGE,
	CAMPAIGN_NAME_CHANGE,
	CLOSE_CAMPAIGN_ASSIGNMENT_DELETE_DIALOG,
	CLOSE_CAMPAIGN_DETAIL,
	DELETE_CAMPAIGN_ASSIGNMENT_CONFIRM,
	EDIT_ENERGY_CAMPAIGN_ASSIGNMENT,
	EDIT_GAS_CAMPAIGN_ASSIGNMENT,
	GET_CAMPAIGN_ASSIGNMENTS,
	SET_CAMPAIGN_DETAIL_ERROR,

	/* GAS CAMPAIGN ASSIGNMENT */
	CLOSE_GAS_CAMPAIGN_ASSIGNMENT,
	CLOSE_GAS_CAMPAIGN_ASSIGNMENT_DELETE_CONFIRM,
	EDIT_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME,
	GAS_CAMPAIGN_ASSIGNMENT_LOAD_PROFILES_ID_CHANGE,
	GAS_CAMPAIGN_ASSIGNMENT_PRODUCTS_ID_CHANGE,
	GAS_CAMPAIGN_ASSIGNMENT_PRODUCT_TYPES_ID_CHANGE,
	GAS_CAMPAIGN_ASSIGNMENT_PRODUCT_VALID_FROM_CHANGE,
	GAS_CAMPAIGN_ASSIGNMENT_TARIF_OLD_CHANGE,
	SET_GAS_CAMPAIGN_ASSIGNMENT_ERROR,

	/* ENERGY CAMPAIGN ASSIGNMENT */
	CLOSE_ENERGY_CAMPAIGN_ASSIGNMENT,
	EDIT_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME,
	SET_ENERGY_CAMPAIGN_ASSIGNMENT_ERROR,
	ENERGY_CAMPAIGN_ASSIGNMENT_LOAD_PROFILES_ID_CHANGE,
	ENERGY_CAMPAIGN_ASSIGNMENT_PRODCUTS_ID_CHANGE,
	ENERGY_CAMPAIGN_ASSIGNMENT_PRODUCT_TYPES_ID_CHANGE,
	ENERGY_CAMPAIGN_ASSIGNMENT_PRODUCT_VALID_FROM_CHANGE,
	ENERGY_CAMPAIGN_ASSIGNMENT_SWITCHING_TIMES_ID_CHANGE,
	ENERGY_CAMPAIGN_ASSIGNMENT_TARIF_OLD_CHANGE,

	/*	GAS CAMPAIGN ASSIGNMENT TIMEFRAMES */
	CLOSE_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_DIALOG,
	CLOSE_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_DELETE_DIALOG,
	DELETE_GAS_ASSIGNMENT_TIMEFRAME_CONFIRM,
	GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_AP_CHANGE,
	GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_BEHG_CHANGE,
	GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_BIO5_CHANGE,
	GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_BIO10_CHANGE,
	GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_CLIMATE_PLUS_CHANGE,
	GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_GP_CHANGE,
	GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_TEHG_CHANGE,
	GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_VALID_FROM_CHANGE,
	GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_VALID_TO_CHANGE,
	GET_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAMES,
	SET_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_ERROR,

	/*	ENERGY CAMPAIGN ASSIGNMENT TIMEFRAMES */
	CLOSE_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_DIALOG,
	CLOSE_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_DELETE_DIALOG,
	DELETE_ENERGY_ASSIGNMENT_TIMEFRAME_CONFIRM,
	ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_AP_CHANGE,
	ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_AP_NT_CHANGE,
	ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_CLIMATE_PLUS_CHANGE,
	ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_GP_CHANGE,
	ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_VALID_FROM_CHANGE,
	ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_VALID_TO_CHANGE,
	GET_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAMES,
	SET_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_ERROR,
	
} from "../types";

export default (state, action) => {
  switch (action.type) {

	/*---------------------------------
		CAMPAIGNS
	---------------------------------*/

	case CLOSE_DELETE_CAMPAIGN_CONFIRM:
		return {
			...state,
			deleteCampaignConfirmName:	"",
			activeCampaignId: 			0,
			deleteCampaignConfirmState: false,
		};

	case DELETE_CAMPAIGN_CONFIRM:
		return {
			...state,
			deleteCampaignConfirmName:	action.payload.deleteCampaignConfirmName,
			activeCampaignId: 			action.payload.id,
			deleteCampaignConfirmState: true,
		};

	case EDIT_CAMPAIGN:
		return {
			...state,
			campaignDetailDialogError:	"",
			campaignDetailDialogState: 	true,
			campaignDetailDialogTitle: 	action.payload.title,
			activeCampaignId: 			action.payload.id,
			campaignName: 				action.payload.campaignName,
			campaignDivisionsId: 		action.payload.campaignDivisionsId,
		};

    case GET_CAMPAIGNS:
		return {
			...state,
			campaigns: action.payload,
		};

	case SET_CAMPAIGN_FOREIGNS:
		return {
			...state,
			gasCampaignAssignmentProductTypes:		action.payload.gasProductTypes,
			gasCampaignAssignmentProducts:			action.payload.gasProducts,
			gasCampaignAssignmentLoadProfiles:		action.payload.loadProfiles,

			energyCampaignAssignmentProductTypes:	action.payload.energyProductTypes,
			energyCampaignAssignmentProducts:		action.payload.energyProducts,
			energyCampaignAssignmentLoadProfiles:	action.payload.loadProfiles,
			energyCampaignAssignmentSwitchingTimes:	action.payload.switchingTimes,
		};


	/*--------------------------------
		CAMPAIGN DETAILS
	--------------------------------*/

	case CAMPAIGN_DIVISIONS_ID_CHANGE:
		return {
			...state,
			campaignDivisionsId: action.payload,
		};

	case CAMPAIGN_NAME_CHANGE:
	return {
		...state,
		campaignName: action.payload,
	};

	case CLOSE_CAMPAIGN_ASSIGNMENT_DELETE_DIALOG:
		return {
			...state,
			deleteCampaignAssignmentConfirmName:	"",
			deleteCampaignAssignmentConfirmState:	false,
			activeCampaignAssignmentId:				0,
		}

	case CLOSE_CAMPAIGN_DETAIL:
		return {
			...state,
			campaignDetailDialogError:	"",
			campaignDetailDialogState: 	false,
			campaignDetailDialogTitle: 	"",
			campaignName: 				"",
			campaignDivisionsId: 		-1,
			activeCampaignId: 			0,
		};

	case DELETE_CAMPAIGN_ASSIGNMENT_CONFIRM:
		return {
			...state,
			deleteCampaignAssignmentConfirmName:	action.payload,
			activeCampaignAssignmentId: 			action.payload,
			deleteCampaignAssignmentConfirmState: 	true,
		};

	case EDIT_ENERGY_CAMPAIGN_ASSIGNMENT:
		return{
			...state,
			activeCampaignAssignmentId:				action.payload.id,
			energyCampaignAssignmentDialogError:	"",
			energyCampaignAssignmentDialogState:	true,
			energyCampaignAssignmentDialogTitle:	action.payload.title,

			energyCampaignAssignmentLoadProfilesId:		action.payload.energyCampaignAssignmentLoadProfilesId,
			energyCampaignAssignmentSwitchingTimesId:	action.payload.energyCampaignAssignmentSwitchingTimesId,
			energyCampaignAssignmentProductsId:			action.payload.energyCampaignAssignmentProductsId,
			energyCampaignAssignmentProductTypesId:		action.payload.energyCampaignAssignmentProductTypesId,
			energyCampaignAssignmentProductValidFrom:	action.payload.energyCampaignAssignmentProductValidFrom,
			energyCampaignAssignmentTarifOld:			action.payload.energyCampaignAssignmentTarifOld,
		};

	case EDIT_GAS_CAMPAIGN_ASSIGNMENT:
		return {
			...state,
			activeCampaignAssignmentId:				action.payload.id,
			gasCampaignAssignmentDialogError: 		"",
			gasCampaignAssignmentDialogState:		true,
			gasCampaignAssignmentDialogTitle:		action.payload.title,
			
			gasCampaignAssignmentLoadProfilesId:	action.payload.gasCampaignAssignmentLoadProfilesId,
			gasCampaignAssignmentProductsId:		action.payload.gasCampaignAssignmentProductsId,
			gasCampaignAssignmentProductTypesId:	action.payload.gasCampaignAssignmentProductTypesId,
			gasCampaignAssignmentProductValidFrom:	action.payload.gasCampaignAssignmentProductValidFrom,
			gasCampaignAssignmentTarifOld:			action.payload.gasCampaignAssignmentTarifOld,
	};

	case GET_CAMPAIGN_ASSIGNMENTS:
		return {
			...state,
			gasCampaignAssignments:		action.payload.gasCampaignAssignments,
			energyCampaignAssignments:	action.payload.energyCampaignAssignments,
	};

	case SET_CAMPAIGN_DETAIL_ERROR:
		return {
			...state,
			campaignDetailDialogError: action.payload,
		};


	/*----------------------------------------
		GAS CAMPAIGN ASSIGNMENTS
	----------------------------------------*/
	
	case CLOSE_GAS_CAMPAIGN_ASSIGNMENT:
		return {
			...state,
			gasCampaignAssignmentDialogError: 	"",
			gasCampaignAssignmentDialogState: 	false,
			gasCampaignAssignmentDialogTitle: 	"",

			gasCampaignAssignmentLoadProfilesId:	-1,
			gasCampaignAssignmentProductsId:		-1,
			gasCampaignAssignmentProductTypesId:	-1,
			gasCampaignAssignmentProductValidFrom:	"",
			gasCampaignAssignmentTarifOld:			"",
		}

	case CLOSE_GAS_CAMPAIGN_ASSIGNMENT_DELETE_CONFIRM:
		return {
			...state,
			gasCampaignAssignmentDeleteDialogName: 	"",
			gasCampaignAssignmentDeleteDialogState:	false,
		}

	case EDIT_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME:
		return{
			...state,
			gasCampaignAssignmentTimeframeDialogError:	"",
			gasCampaignAssignmentTimeframeDialogState:	true,
			gasCampaignAssignmentTimeframeDialogTitle:	action.payload.gasCampaignAssignmentTimeframeDialogTitle,
			activeGasCampaignAssignmentTimeframeId:		action.payload.activeGasCampaignAssignmentTimeframeId,
			gasCampaignAssignmentTimeframeClimatePlus:	action.payload.gasCampaignAssignmentTimeframeClimatePlus,
			gasCampaignAssignmentTimeframeValidFrom:	action.payload.gasCampaignAssignmentTimeframeValidFrom,
			gasCampaignAssignmentTimeframeValidTo:		action.payload.gasCampaignAssignmentTimeframeValidTo,
			gasCampaignAssignmentTimeframeIsNewYearsOnly:	action.payload.gasCampaignAssignmentTimeframeIsNewYearsOnly,
			gasCampaignAssignmentTimeframeGp:			action.payload.gasCampaignAssignmentTimeframeGp,
			gasCampaignAssignmentTimeframeAp:			action.payload.gasCampaignAssignmentTimeframeAp,
			gasCampaignAssignmentTimeframeBio5:			action.payload.gasCampaignAssignmentTimeframeBio5,
			gasCampaignAssignmentTimeframeBio10:		action.payload.gasCampaignAssignmentTimeframeBio10,
			gasCampaignAssignmentTimeframeBehg:			action.payload.gasCampaignAssignmentTimeframeBehg,
			gasCampaignAssignmentTimeframeTehg:			action.payload.gasCampaignAssignmentTimeframeTehg,
		} 

	case GAS_CAMPAIGN_ASSIGNMENT_LOAD_PROFILES_ID_CHANGE:
		return {
			...state,
			gasCampaignAssignmentLoadProfilesId: action.payload,
		}

	case GAS_CAMPAIGN_ASSIGNMENT_PRODUCTS_ID_CHANGE:
		return {
			...state,
			gasCampaignAssignmentProductsId: action.payload,
		}

	case GAS_CAMPAIGN_ASSIGNMENT_PRODUCT_TYPES_ID_CHANGE:
		return {
			...state,
			gasCampaignAssignmentProductTypesId: action.payload,
		}

	case GAS_CAMPAIGN_ASSIGNMENT_PRODUCT_VALID_FROM_CHANGE:
		return {
			...state,
			gasCampaignAssignmentProductValidFrom: action.payload,
		}

	case GAS_CAMPAIGN_ASSIGNMENT_TARIF_OLD_CHANGE:
		return {
			...state,
			gasCampaignAssignmentTarifOld: action.payload,
		}

	case SET_GAS_CAMPAIGN_ASSIGNMENT_ERROR:
		return {
			...state,
			gasCampaignAssignmentDialogError: action.payload,
		}


	/*-------------------------------------------
		ENERGY CAMPAIGN ASSIGNMENTS
	-------------------------------------------*/
	
	case CLOSE_ENERGY_CAMPAIGN_ASSIGNMENT:
		return {
			...state,
			energyCampaignAssignmentDialogError: 	"",
			energyCampaignAssignmentDialogState:	false,
			energyCampaignAssignmentDialogTitle:	"",
		}

	case EDIT_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME:
		return{
			...state,
			energyCampaignAssignmentTimeframeDialogError:	"",
			energyCampaignAssignmentTimeframeDialogState:	true,
			energyCampaignAssignmentTimeframeDialogTitle:	action.payload.energyCampaignAssignmentTimeframeDialogTitle,
			activeEnergyCampaignAssignmentTimeframeId:		action.payload.activeEnergyCampaignAssignmentTimeframeId,
			energyCampaignAssignmentTimeframeClimatePlus:	action.payload.energyCampaignAssignmentTimeframeClimatePlus,
			energyCampaignAssignmentTimeframeValidFrom:		action.payload.energyCampaignAssignmentTimeframeValidFrom,
			energyCampaignAssignmentTimeframeValidTo:		action.payload.energyCampaignAssignmentTimeframeValidTo,
			energyCampaignAssignmentTimeframeIsNewYearsOnly:	action.payload.energyCampaignAssignmentTimeframeIsNewYearsOnly,
			energyCampaignAssignmentTimeframeGp:			action.payload.energyCampaignAssignmentTimeframeGp,
			energyCampaignAssignmentTimeframeAp:			action.payload.energyCampaignAssignmentTimeframeAp,
			energyCampaignAssignmentTimeframeApNt:			action.payload.energyCampaignAssignmentTimeframeApNt,
			energyCampaignAssignmentTimeframeIsApNt:		action.payload.energyCampaignAssignmentTimeframeIsApNt,
		} 

	case SET_ENERGY_CAMPAIGN_ASSIGNMENT_ERROR:
		return {
			...state,
			energyCampaignAssignmentDialogError: action.payload,
		}

	case ENERGY_CAMPAIGN_ASSIGNMENT_LOAD_PROFILES_ID_CHANGE:
		return {
			...state,
			energyCampaignAssignmentLoadProfilesId: action.payload,
		}

	case ENERGY_CAMPAIGN_ASSIGNMENT_PRODCUTS_ID_CHANGE:
		return {
			...state,
			energyCampaignAssignmentProductsId: action.payload,
		}

	case ENERGY_CAMPAIGN_ASSIGNMENT_PRODUCT_TYPES_ID_CHANGE:
		return {
			...state,
			energyCampaignAssignmentProductTypesId: action.payload,
		}

	case ENERGY_CAMPAIGN_ASSIGNMENT_PRODUCT_VALID_FROM_CHANGE:
		return {
			...state,
			energyCampaignAssignmentProductValidFrom: action.payload,
		}

	case ENERGY_CAMPAIGN_ASSIGNMENT_SWITCHING_TIMES_ID_CHANGE:
		return {
			...state,
			energyCampaignAssignmentSwitchingTimesId: action.payload,
		}

	case ENERGY_CAMPAIGN_ASSIGNMENT_TARIF_OLD_CHANGE:
		return {
			...state,
			energyCampaignAssignmentTarifOld: action.payload,
		}

	/*-------------------------------------------
		GAS CAMPAIGN ASSIGNMENT TIMEFRAMES
	-------------------------------------------*/

	case CLOSE_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_DIALOG:
		return {
			...state,
			gasCampaignAssignmentTimeframeDialogError:	"",
			gasCampaignAssignmentTimeframeDialogState:	false,
			gasCampaignAssignmentTimeframeDialogTitle:	"",
		}

	case CLOSE_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_DELETE_DIALOG:
		return {
			...state,
			gasCampaignAssignmentTimeframeDeleteDialogState: false,
			gasCampaignAssignmentTimeframeDeleteDialogName: "",
			activeGasCampaignAssignmentTimeframeId: 0,
		}

	case DELETE_GAS_ASSIGNMENT_TIMEFRAME_CONFIRM:
		return {
			...state,
			gasCampaignAssignmentTimeframeDeleteDialogState: true,
			gasCampaignAssignmentTimeframeDeleteDialogName:	 action.payload,
			activeGasCampaignAssignmentTimeframeId: action.payload,
		}

	case GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_AP_CHANGE:
		return {
			...state,
			gasCampaignAssignmentTimeframeAp: action.payload,
		}

	case GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_BEHG_CHANGE:
		return {
			...state,
			gasCampaignAssignmentTimeframeBehg: action.payload,
		}

	case GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_BIO5_CHANGE:
		return {
			...state,
			gasCampaignAssignmentTimeframeBio5: action.payload,
		}

	case GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_BIO10_CHANGE:
		return {
			...state,
			gasCampaignAssignmentTimeframeBio10: action.payload,
		}

	case GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_CLIMATE_PLUS_CHANGE:
		return {
			...state,
			gasCampaignAssignmentTimeframeClimatePlus: action.payload,
		}

	case GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_GP_CHANGE:
		return {
			...state,
			gasCampaignAssignmentTimeframeGp: action.payload,
		}

	case GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_TEHG_CHANGE:
		return {
			...state,
			gasCampaignAssignmentTimeframeTehg: action.payload,
		}

	case GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_VALID_FROM_CHANGE:
		return {
			...state,
			gasCampaignAssignmentTimeframeValidFrom: action.payload,
		}

	case GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_VALID_TO_CHANGE:
		return{
			...state,
			gasCampaignAssignmentTimeframeValidTo: action.payload,
		} 

	case GET_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAMES:
		return {
			...state,
			gasCampaignAssignmentTimeframes: action.payload.gasCampaignAssignmentTimeframes,
		}

	case SET_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_ERROR:
		return {
			...state,
			gasCampaignAssignmentTimeframeDialogError: action.payload,
		}

	/*----------------------------------------
		ENERGY CAMPAIGN ASSIGNMENT TIMEFRAMES
	----------------------------------------*/

	case CLOSE_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_DIALOG:
		return {
			...state,
			energyCampaignAssignmentTimeframeDialogError:	"",
			energyCampaignAssignmentTimeframeDialogState:	false,
			energyCampaignAssignmentTimeframeDialogTitle:	"",
		}

	case CLOSE_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_DELETE_DIALOG:
		return {
			...state,
			energyCampaignAssignmentTimeframeDeleteDialogState: false,
			energyCampaignAssignmentTimeframeDeleteDialogName: "",
			activeEnergyCampaignAssignmentTimeframeId: 0,
		}

	case DELETE_ENERGY_ASSIGNMENT_TIMEFRAME_CONFIRM:
		return {
			...state,
			energyCampaignAssignmentTimeframeDeleteDialogState: 	true,
			energyCampaignAssignmentTimeframeDeleteDialogName:	 	action.payload,
			activeEnergyCampaignAssignmentTimeframeId: 				action.payload,
		}

	case ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_AP_CHANGE:
		return {
			...state,
			energyCampaignAssignmentTimeframeAp: action.payload,
		}

	case ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_AP_NT_CHANGE:
		return {
			...state,
			energyCampaignAssignmentTimeframeApNt: action.payload,
		}

	case ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_CLIMATE_PLUS_CHANGE:
		return {
			...state,
			energyCampaignAssignmentTimeframeClimatePlus: action.payload,
		}

	case ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_GP_CHANGE:
		return {
			...state,
			energyCampaignAssignmentTimeframeGp: action.payload,
		}

	case ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_VALID_FROM_CHANGE:
		return {
			...state,
			energyCampaignAssignmentTimeframeValidFrom: action.payload,
		}

	case ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_VALID_TO_CHANGE:
		return {
			...state,
			energyCampaignAssignmentTimeframeValidTo: action.payload,
		}

	case GET_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAMES:
		return {
			...state,
			energyCampaignAssignmentTimeframes: action.payload.energyCampaignAssignmentTimeframes,
		}

	case SET_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_ERROR:
		return {
			...state,
			energyCampaignAssignmentTimeframeDialogError: action.payload,
		}

    //  Default Path
    default:
      return state;
  }
};
