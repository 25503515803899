//  General
export const GET_ENTITIES = "GET_ENTITIES";
export const SET_ACTIVE_ENTITY = "SET_ACTIVE_ENTITY";
export const SET_DELETE_ENTITY = "SET_DELETE_ENTITY";
export const SET_DELETE_MODAL_ENTITY_NAME = "SET_DELETE_MODAL_ENTITY_NAME";
export const SET_EDIT_DIALOG_TITLE = "SET_EDIT_DIALOG_TITLE";
export const SET_EDIT_ENTITY = "SET_EDIT_ENTITY";
export const SET_ENTITY_DIALOG_FIELDS = "SET_ENTITY_DIALOG_FIELDS";
export const SET_ERROR = "SET_ERROR";
export const SET_FOREIGNS = "SET_FOREIGNS";

//  Customers
export const IS_READING_TYPE_CHANGE_COMMENT = 'IS_READING_TYPE_CHANGE_COMMENT';
export const ABC_ID_CHANGE = "ABC_ID_CHANGE";
export const ACTIVE_ENERGY_CAMPAIGNS_ID_CHANGE =
  "ACTIVE_ENERGY_CAMPAIGNS_ID_CHANGE";
export const ACTIVE_GAS_CAMPAIGNS_ID_CHANGE = "ACTIVE_GAS_CAMPAIGNS_ID_CHANGE";
export const IS_CUSTOMER_ENERGY_TRANSITION_CHANGE = "IS_CUSTOMER_ENERGY_TRANSITION_CHANGE";
export const CUSTOMER_ENERGY_TRANSITION_DATE_CHANGE = "CUSTOMER_ENERGY_TRANSITION_DATE_CHANGE";
export const IS_CUSTOMER_ENERGY_TRANSITION_TEN_PERCENT_CHANGE = "IS_CUSTOMER_ENERGY_TRANSITION_TEN_PERCENT_CHANGE";
export const CUSTOMER_ENERGY_TRANSITION_TEN_PERCENT_DATE_CHANGE = "CUSTOMER_ENERGY_TRANSITION_TEN_PERCENT_DATE_CHANGE";
export const IS_CUSTOMER_GAS_TRANSITION_CHANGE = "IS_CUSTOMER_GAS_TRANSITION_CHANGE";
export const CUSTOMER_GAS_TRANSITION_DATE_CHANGE = "CUSTOMER_GAS_TRANSITION_DATE_CHANGE";
export const IS_CUSTOMER_GAS_TRANSITION_TEN_PERCENT_CHANGE = "IS_CUSTOMER_GAS_TRANSITION_TEN_PERCENT_CHANGE";
export const CUSTOMER_GAS_TRANSITION_TEN_PERCENT_DATE_CHANGE = "CUSTOMER_GAS_TRANSITION_TEN_PERCENT_DATE_CHANGE";
export const ADDRESS_ADDITIONAL_CHANGE = "ADDRESS_ADDITIONAL_CHANGE";
export const ADMINISTRATIONS1_ID_CHANGE = "ADMINISTRATIONS1_ID_CHANGE";
export const ADMINISTRATIONS2_ID_CHANGE = "ADMINISTRATIONS2_ID_CHANGE";
export const ADMINISTRATIONS3_ID_CHANGE = "ADMINISTRATIONS3_ID_CHANGE";
export const CENTRAL_FAX_CHANGE = "CENTRAL_FAX_CHANGE";
export const CENTRAL_PHONE_CHANGE = "CENTRAL_PHONE_CHANGE";
export const CUSTOMER_TYPES_ID_CHANGE = "CUSTOMER_TYPES_ID_CHANGE";
export const CLOSE_CONTACT_PERSON_DIALOG = "CLOSE_CONTACT_PERSON_DIALOG";
export const CLOSE_CONTRACT_DIALOG = "CLOSE_CONTRACT_DIALOG";
export const CLOSE_CONTRACTS_DIALOG = "CLOSE_CONTRACTS_DIALOG";
export const CLOSE_CUSTOMER_CONFIRM = "CLOSE_CUSTOMER_CONFIRM";
export const CLOSE_CUSTOMER_DETAIL_DIALOG = "CLOSE_CUSTOMER_DETAIL_DIALOG";
export const CLOSE_DELETE_CUSTOMER_CONFIRM = "CLOSE_DELETE_CUSTOMER_CONFIRM";
export const CLOSE_DELETE_CUSTOMER_CONTACT_HISTORY_CONFIRM =
  "CLOSE_DELETE_CUSTOMER_CONTACT_HISTORY_CONFIRM";
export const COMMUNITY_CODE_CHANGE = "COMMUNITY_CODE_CHANGE";
export const CONTACT_PERSON_DIVISION_CHANGE = "CONTACT_PERSON_DIVISION_CHANGE";
export const CONTACT_PERSON_EMAIL_CHANGE = "CONTACT_PERSON_EMAIL_CHANGE";
export const CONTACT_PERSON_FAX_CHANGE = "CONTACT_PERSON_FAX_CHANGE";
export const CONTACT_PERSON_FIRSTNAME_CHANGE =
  "CONTACT_PERSON_FIRSTNAME_CHANGE";
export const CONTACT_PERSON_FUNCTION_CHANGE = "CONTACT_PERSON_FUNCTION_CHANGE";
export const CONTACT_PERSON_IS_INFO_READING_CHANGE = 'CONTACT_PERSON_IS_INFO_READING_CHANGE';
export const CONTACT_PERSON_IS_PORTAL_ACCESS_CHANGE =
  "CONTACT_PERSON_IS_PORTAL_ACCESS_CHANGE";
export const CONTACT_PERSON_LASTNAME_CHANGE = "CONTACT_PERSON_LASTNAME_CHANGE";
export const CONTACT_PERSON_MOBILE_CHANGE = "CONTACT_PERSON_MOBILE_CHANGE";
export const CONTACT_PERSON_PHONE_CHANGE = "CONTACT_PERSON_PHONE_CHANGE";
export const CONTACT_PERSON_PORTAL_ACCESS_VALID_TO_CHANGE =
  "CONTACT_PERSON_PORTAL_ACCESS_VALID_TO_CHANGE";
export const CONTACT_PERSON_SALUTATIONS_ID_CHANGE =
  "CONTACT_PERSON_SALUTATIONS_ID_CHANGE";
export const CONTACT_PERSON_TITLE_CHANGE = "CONTACT_PERSON_TITLE_CHANGE";
export const COUNTRY_CHANGE = "COUNTRY_CHANGE";
export const CUSTOMER_CONTRACT_BILLING_ADDITIONAL_CHANGE =
  "CUSTOMER_CONTRACT_BILLING_ADDITIONAL_CHANGE";
export const CUSTOMER_CONTRACT_BILLING_CITY_CHANGE =
  "CUSTOMER_CONTRACT_BILLING_CITY_CHANGE";
export const CUSTOMER_CONTRACT_BILLING_COUNTRY_CHANGE =
  "CUSTOMER_CONTRACT_BILLING_COUNTRY_CHANGE";
export const CUSTOMER_CONTRACT_BILLING_DISTRICT_CHANGE =
  "CUSTOMER_CONTRACT_BILLING_DISTRICT_CHANGE";
export const CUSTOMER_CONTRACT_BILLING_HOUSE_NUMBER_CHANGE =
  "CUSTOMER_CONTRACT_BILLING_HOUSE_NUMBER_CHANGE";
export const CUSTOMER_CONTRACT_BILLING_NAME1_CHANGE =
  "CUSTOMER_CONTRACT_BILLING_NAME1_CHANGE";
export const CUSTOMER_CONTRACT_BILLING_NAME2_CHANGE =
  "CUSTOMER_CONTRACT_BILLING_NAME2_CHANGE";
export const CUSTOMER_CONTRACT_BILLING_NAME3_CHANGE =
  "CUSTOMER_CONTRACT_BILLING_NAME3_CHANGE";
export const CUSTOMER_CONTRACT_BILLING_POSTAL_CODE_CHANGE =
  "CUSTOMER_CONTRACT_BILLING_POSTAL_CODE_CHANGE";
export const CUSTOMER_CONTRACT_BILLING_POSTBOX_CITY_CHANGE =
  "CUSTOMER_CONTRACT_BILLING_POSTBOX_CITY_CHANGE";
export const CUSTOMER_CONTRACT_BILLING_POSTBOX_NO_CHANGE =
  "CUSTOMER_CONTRACT_BILLING_POSTBOX_NO_CHANGE";
export const CUSTOMER_CONTRACT_BILLING_POSTBOX_POSTAL_CODE_CHANGE =
  "CUSTOMER_CONTRACT_BILLING_POSTBOX_POSTAL_CODE_CHANGE";
export const CUSTOMER_CONTRACT_BILLING_STREET_CHANGE =
  "CUSTOMER_CONTRACT_BILLING_STREET_CHANGE";
export const CUSTOMER_CONTRACT_CUSTOMER_TYPES_ID_CHANGE =
  "CUSTOMER_CONTRACT_CUSTOMER_TYPES_ID_CHANGE";
export const CUSTOMER_CONTRACT_DIVISIONS_ID_CHANGE =
  "CUSTOMER_CONTRACT_DIVISIONS_ID_CHANGE";
export const CUSTOMER_CONTRACT_GROSS_BILL_AMOUNT_CHANGE =
  "CUSTOMER_CONTRACT_GROSS_BILL_AMOUNT_CHANGE";
export const CUSTOMER_CONTRACT_IS_BIO10_CHANGE =
  "CUSTOMER_CONTRACT_IS_BIO10_CHANGE";
export const CUSTOMER_CONTRACT_BIO5_START_DATE_CHANGE =
  "CUSTOMER_CONTRACT_BIO5_START_DATE_CHANGE";
export const CUSTOMER_CONTRACT_IS_BIO5_CHANGE =
  "CUSTOMER_CONTRACT_IS_BIO5_CHANGE";
export const CUSTOMER_CONTRACT_BIO10_START_DATE_CHANGE =
  "CUSTOMER_CONTRACT_BIO10_START_DATE_CHANGE";
export const CUSTOMER_CONTRACT_IS_CLIMATE_PLUS_CHANGE =
  "CUSTOMER_CONTRACT_IS_CLIMATE_PLUS_CHANGE";
export const CUSTOMER_CONTRACT_PERIOD_CONSUMPTION_CHANGE =
  "CUSTOMER_CONTRACT_PERIOD_CONSUMPTION_CHANGE";
export const CUSTOMER_CONTRACT_POTS_ID_CHANGE =
  "CUSTOMER_CONTRACT_POTS_ID_CHANGE";
export const CUSTOMER_CONTRACT_PRODUCTS_ID_CHANGE =
  "CUSTOMER_CONTRACT_PRODUCTS_ID_CHANGE";
export const CUSTOMER_CONTRACT_PROFILE_TYPE_CHANGE =
  "CUSTOMER_CONTRACT_PROFILE_TYPE_CHANGE";
export const CUSTOMER_CONTRACT_SALUTATIONS_ID_CHANGE =
  "CUSTOMER_CONTRACT_SALUTATIONS_ID_CHANGE";
export const CUSTOMER_CONTRACT_VALID_FROM_CHANGE =
  "CUSTOMER_CONTRACT_VALID_FROM_CHANGE";
export const CUSTOMER_CONTRACT_VALID_TO_CHANGE =
  "CUSTOMER_CONTRACT_VALID_TO_CHANGE";
export const IS_CUSTOMER_CONTRACT_TRANSITION_CHANGE = "IS_CUSTOMER_CONTRACT_TRANSITION_CHANGE";
export const CUSTOMER_CONTRACT_TRANSITION_DATE_CHANGE = "CUSTOMER_CONTRACT_TRANSITION_DATE_CHANGE";
export const IS_CUSTOMER_CONTRACT_TRANSITION_TEN_PERCENT_CHANGE = "IS_CUSTOMER_CONTRACT_TRANSITION_TEN_PERCENT_CHANGE";
export const CUSTOMER_CONTRACT_TRANSITION_TEN_PERCENT_DATE_CHANGE = "CUSTOMER_CONTRACT_TRANSITION_TEN_PERCENT_DATE_CHANGE";
export const CUSTOMER_COUNTER_ACCOUNT_NO_CHANGE =
  "CUSTOMER_COUNTER_ACCOUNT_NO_CHANGE";
export const CUSTOMER_COUNTER_ACCOUNT_OWNER_CHANGE =
  "CUSTOMER_COUNTER_ACCOUNT_OWNER_CHANGE";
export const CUSTOMER_COUNTER_BANK_CODE_CHANGE =
  "CUSTOMER_COUNTER_BANK_CODE_CHANGE";
export const CUSTOMER_COUNTER_BHKW_CHANGE = "CUSTOMER_COUNTER_BHKW_CHANGE";
export const CUSTOMER_COUNTER_CHANGE_REASONS_ID_CHANGE =
  "CUSTOMER_COUNTER_CHANGE_REASONS_ID_CHANGE";
export const CUSTOMER_COUNTER_CHANGE_SUPPLY_START_CHANGE =
  "CUSTOMER_COUNTER_CHANGE_SUPPLY_START_CHANGE";
export const CUSTOMER_COUNTER_CONTRACT_ACCOUNT_CHANGE =
  "CUSTOMER_COUNTER_CONTRACT_ACCOUNT_CHANGE";
export const CUSTOMER_COUNTER_CONTRACT_ACCOUNT_DESCRIPTION_CHANGE =
  "CUSTOMER_COUNTER_CONTRACT_ACCOUNT_DESCRIPTION_CHANGE";
export const CUSTOMER_COUNTER_CONTRACT_END = "CUSTOMER_COUNTER_CONTRACT_END";
export const CUSTOMER_COUNTER_CONTRACT_TERMINATION_REASONS_ID_CHANGE =
  "CUSTOMER_COUNTER_CONTRACT_TERMINATION_REASONS_ID_CHANGE";
export const CUSTOMER_COUNTER_DOUBLINGS_LIST =
  "CUSTOMER_COUNTER_DOUBLINGS_LIST";
export const CUSTOMER_COUNTER_FORMER_SUPPLIER_ID_CHANGE =
  "CUSTOMER_COUNTER_FORMER_SUPPLIER_ID_CHANGE";
export const CUSTOMER_COUNTER_IBAN_CHANGE = "CUSTOMER_COUNTER_IBAN_CHANGE";
export const CUSTOMER_COUNTER_INSTALLMENT_CYCLE_CHANGE =
  "CUSTOMER_COUNTER_INSTALLMENT_CYCLE_CHANGE";
export const CUSTOMER_COUNTER_INTENDED_USE_CHANGE =
  "CUSTOMER_COUNTER_INTENDED_USE_CHANGE";
export const CUSTOMER_COUNTER_LOAD_PROFILES_ID_CHANGE =
  "CUSTOMER_COUNTER_LOAD_PROFILES_ID_CHANGE";
export const CUSTOMER_COUNTER_LOCATION_ADDITIONAL_CHANGE =
  "CUSTOMER_COUNTER_LOCATION_ADDITIONAL_CHANGE";
export const CUSTOMER_COUNTER_LOCATION_CITY_CHANGE =
  "CUSTOMER_COUNTER_LOCATION_CITY_CHANGE";
export const CUSTOMER_COUNTER_LOCATION_COUNTRY_CHANGE =
  "CUSTOMER_COUNTER_LOCATION_COUNTRY_CHANGE";
export const CUSTOMER_COUNTER_LOCATION_DISTRICT_CHANGE =
  "CUSTOMER_COUNTER_LOCATION_DISTRICT_CHANGE";
export const CUSTOMER_COUNTER_LOCATION_HOUSE_NUMBER_CHANGE =
  "CUSTOMER_COUNTER_LOCATION_HOUSE_NUMBER_CHANGE";
export const CUSTOMER_COUNTER_LOCATION_MARKET_LOCATION_CHANGE =
  "CUSTOMER_COUNTER_LOCATION_MARKET_LOCATION_CHANGE";
export const CUSTOMER_COUNTER_LOCATION_MEASURE_LOCATION_CHANGE =
  "CUSTOMER_COUNTER_LOCATION_MEASURE_LOCATION_CHANGE";
export const CUSTOMER_COUNTER_LOCATION_POSTAL_CODE_CHANGE =
  "CUSTOMER_COUNTER_LOCATION_POSTAL_CODE_CHANGE";
export const CUSTOMER_COUNTER_LOCATION_STREET_CHANGE =
  "CUSTOMER_COUNTER_LOCATION_STREET_CHANGE";
export const CUSTOMER_COUNTER_MEDIUM_VOLTAGE_CHANGE =
  "CUSTOMER_COUNTER_MEDIUM_VOLTAGE_CHANGE";
export const CUSTOMER_COUNTER_NETWORKS_ID_CHANGE =
  "CUSTOMER_COUNTER_NETWORKS_ID_CHANGE";
export const CUSTOMER_COUNTER_NUMBER_CURRENT_CHANGE =
  "CUSTOMER_COUNTER_NUMBER_CURRENT_CHANGE";
export const CUSTOMER_COUNTER_NUMBER_FORMER_CHANGE =
  "CUSTOMER_COUNTER_NUMBER_FORMER_CHANGE";
export const CUSTOMER_COUNTER_VALUE_CHANGE = "CUSTOMER_COUNTER_VALUE_CHANGE";
export const CUSTOMER_COUNTER_VALUE_NT_CHANGE =
  "CUSTOMER_COUNTER_VALUE_NT_CHANGE";
export const CUSTOMER_DOUBLE_COUNTER_DIALOG_CLOSE =
  "CUSTOMER_DOUBLE_COUNTER_DIALOG_CLOSE";
export const CUSTOMER_OBJECT_LIST_DIALOG = "CUSTOMER_OBJECT_LIST_DIALOG";
export const CUSTOMER_OBJECT_LIST_IS_FINISHED =
  "CUSTOMER_OBJECT_LIST_IS_FINISHED";
export const DELETE_CONTACT_PERSON = "DELETE_CONTACT_PERSON";
export const DELETE_CUSTOMER_CONFIRM = "DELETE_CUSTOMER_CONFIRM";
export const DISTRICT_CHANGE = "DISTRICT_CHANGE";
export const EDIT_CONTACT_PERSON = "EDIT_CONTACT_PERSON";
export const EDIT_CUSTOMER = "EDIT_CUSTOMER";
export const EDIT_CUSTOMER_CONTRACT = "EDIT_CUSTOMER_CONTRACT";
export const EMAIL_GENERAL_CHANGE = "EMAIL_GENERAL_CHANGE";
export const GET_ADMINISTRATIONS_1 = "GET_ADMINISTRATIONS_1";
export const GET_ADMINISTRATION2 = "GET_ADMINISTRATION2";
export const GET_ADMINISTRATION3 = "GET_ADMINISTRATION3";
export const GET_CONTACT_PERSONS = "GET_CONTACT_PERSONS";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_PRODUCT_HISTORIES = "GET_PRODUCT_HISTORIES";
export const GP_NUMBER_CHANGE = "GP_NUMBER_CHANGE";
export const IS_CUSTOMER_COUNTER_LEAVE_DONE_CHANGE =
  "IS_CUSTOMER_COUNTER_LEAVE_DONE_CHANGE";
export const IS_PORTAL_ACCESS_CHANGE = "IS_PORTAL_ACCESS_CHANGE";
export const NAME_1_CHANGE = "NAME_1_CHANGE";
export const NAME_2_CHANGE = "NAME_2_CHANGE";
export const NAME_3_CHANGE = "NAME_3_CHANGE";
export const OFFERED_ENERGY_CAMPAIGNS_ID_CHANGE =
  "OFFERED_ENERGY_CAMPAIGNS_ID_CHANGE";
export const OFFERED_GAS_CAMPAIGNS_ID_CHANGE =
  "OFFERED_GAS_CAMPAIGNS_ID_CHANGE";
export const OWNER_ID_CHANGE = "OWNER_ID_CHANGE";
export const POSTBOX_CHANGE = "POSTBOX_CHANGE";
export const POSTBOX_POSTAL_CODE_CHANGE = "POSTBOX_POSTAL_CODE_CHANGE";
export const POSTBOX_CITY_CHANGE = "POSTBOX_CITY_CHANGE";
export const SALUATIONS_ID_CHANGE = "SALUATIONS_ID_CHANGE";
export const SET_ACTIVE_ADDRESS_TAB = "SET_ACTIVE_ADDRESS_TAB";
export const SET_ACTIVE_CONTACT_PERSON = "SET_ACTIVE_CONTACT_PERSON";
export const SET_ACTIVE_CONTRACT_COUNTER_TAB =
  "SET_ACTIVE_CONTRACT_COUNTER_TAB";
export const SET_ACTIVE_CONTRACT_TAB = "SET_ACTIVE_CONTRACT_TAB";
export const SET_ACTIVE_PRODUCT_TAB = "SET_ACTIVE_PRODUCT_TAB";
export const SET_CONTRACT_ERR_MSG = "SET_CONTRACT_ERR_MSG";
export const SET_CONTACT_PERSON_ERROR = "SET_CONTACT_PERSON_ERROR";
export const SET_CUSTOMER_DETAIL_DIALOG_ACTIVE_TAB =
  "SET_CUSTOMER_DETAIL_DIALOG_ACTIVE_TAB";
export const SUPERVISORS_ID_CHANGE = "SUPERVISORS_ID_CHANGE";
export const SUPERVISORS_PV_ID_CHANGE = "SUPERVISORS_PV_ID_CHANGE";
export const CLOSE_CUSTOMER_CONTRACT_BILLING_ADDRESS_PASTE_DIALOG = 'CLOSE_CUSTOMER_CONTRACT_BILLING_ADDRESS_PASTE_DIALOG';
export const CLOSE_CUSTOMER_CONTRACT_BILLING_ADDRESS_SAVED_DIALOG = 'CLOSE_CUSTOMER_CONTRACT_BILLING_ADDRESS_SAVED_DIALOG';
export const CUSTOMER_CONTRACT_BILLING_ADDRESS_SAVED = 'CUSTOMER_CONTRACT_BILLING_ADDRESS_SAVED';
export const CUSTOMER_CONTRACT_DELETE_BILLING_ADDRESS = 'CUSTOMER_CONTRACT_DELETE_BILLING_ADDRESS';
export const CUSTOMER_CONTRACT_PASTE_BILLING_ADDRESS = 'CUSTOMER_CONTRACT_PASTE_BILLING_ADDRESS';
export const CUSTOMER_CONTRACT_TAKEOVER_BILLING_ADDRESS = 'CUSTOMER_CONTRACT_TAKEOVER_BILLING_ADDRESS';
export const CUSTOMER_COPY_DIALOG = 'CUSTOMER_COPY_DIALOG';
export const CUSTOMER_ACTIVE_CAMPAIGN_CHANGE_DIALOG = 'CUSTOMER_ACTIVE_CAMPAIGN_CHANGE_DIALOG';

//  Leads
export const ACCEPT_OFFER = "ACCEPT_OFFER";
export const ACCOUNT_NO_CHANGE = "ACCOUNT_NO_CHANGE";
export const ACCOUNT_OWNER_CHANGE = "ACCOUNT_OWNER_CHANGE";
export const ADDRESS_ADDITION_CHANGE = "ADDRESS_ADDITION_CHANGE";
export const ADMINISTRATIONS_1_ID_CHANGE = "ADMINISTRATIONS_1_ID_CHANGE";
export const ADMINISTRATIONS_2_ID_CHANGE = "ADMINISTRATIONS_2_ID_CHANGE";
export const ADMINISTRATIONS_3_ID_CHANGE = "ADMINISTRATIONS_3_ID_CHANGE";
export const BANK_CODE_CHANGE = "BANK_CODE_CHANGE";
export const BUSINESS_PARTNER_TYPES_ID_CHANGE =
  "BUSINESS_PARTNER_TYPES_ID_CHANGE";
export const CLOSE_DELETE = "CLOSE_DELETE";
export const CLOSE_LEAD_DETAIL_DIALOG = "CLOSE_LEAD_DETAIL_DIALOG";
export const CONFIRM_ACCEPT_OFFER = "CONFIRM_ACCEPT_OFFER";
export const CLOSE_LEAD_CONTRACT_BILLING_ADDRESS_SAVED_DIALOG = 'CLOSE_LEAD_CONTRACT_BILLING_ADDRESS_SAVED_DIALOG';
export const CLOSE_LEAD_CONTRACT_BILLING_ADDRESS_PASTE_DIALOG = 'CLOSE_LEAD_CONTRACT_BILLING_ADDRESS_PASTE_DIALOG';
export const CLOSE_LEAD_CONTRACT_OFFER_ACCEPT_DIALOG =
  "CLOSE_LEAD_CONTRACT_OFFER_ACCEPT_DIALOG";
export const DELETE_CONTACT_PERSON_CONFIRM = "DELETE_CONTACT_PERSON_CONFIRM";
export const EDIT_LEAD = "EDIT_LEAD";
export const EDIT_LEAD_CONTRACT = "EDIT_LEAD_CONTRACT";
export const ENERGY_CAMPAIGNS_ID_CHANGE = "ENERGY_CAMPAIGNS_ID_CHANGE";
export const IS_LEAD_ENERGY_TRANSITION_CHANGE = "IS_LEAD_ENERGY_TRANSITION_CHANGE";
export const LEAD_ENERGY_TRANSITION_DATE_CHANGE = "LEAD_ENERGY_TRANSITION_DATE_CHANGE";
export const IS_LEAD_ENERGY_TRANSITION_TEN_PERCENT_CHANGE = "IS_LEAD_ENERGY_TRANSITION_TEN_PERCENT_CHANGE";
export const LEAD_ENERGY_TRANSITION_TEN_PERCENT_DATE_CHANGE = "LEAD_ENERGY_TRANSITION_TEN_PERCENT_DATE_CHANGE";
export const ENERGY_POTS_ID_CHANGE = "ENERGY_POTS_ID_CHANGE";
export const FAX_CHANGE = "FAX_CHANGE";
export const FOLLOW_UP_DATE_CHANGE = "FOLLOW_UP_DATE_CHANGE";
export const FOLLOW_UP_DONE_CHANGE = "FOLLOW_UP_DONE_CHANGE";
export const GAS_CAMPAIGNS_ID_CHANGE = "GAS_CAMPAIGNS_ID_CHANGE";
export const IS_LEAD_GAS_TRANSITION_CHANGE = "IS_LEAD_GAS_TRANSITION_CHANGE";
export const LEAD_GAS_TRANSITION_DATE_CHANGE = "LEAD_GAS_TRANSITION_DATE_CHANGE";
export const IS_LEAD_GAS_TRANSITION_TEN_PERCENT_CHANGE = "IS_LEAD_GAS_TRANSITION_TEN_PERCENT_CHANGE";
export const LEAD_GAS_TRANSITION_TEN_PERCENT_DATE_CHANGE = "LEAD_GAS_TRANSITION_TEN_PERCENT_DATE_CHANGE";
export const GAS_POTS_ID_CHANGE = "GAS_POTS_ID_CHANGE";
export const GET_ADMINISTRATIONS_2 = "GET_ADMINISTRATIONS_2";
export const GET_ADMINISTRATIONS_3 = "GET_ADMINISTRATIONS_3";
export const IBAN_CHANGE = "IBAN_CHANGE";
export const IS_CONTACT_PERSON_CHANGE = 'IS_CONTACT_PERSON_CHANGE';
export const IS_PORTAL_ACCESS_ADMINISTRATION_CHANGE =
  "IS_PORTAL_ACCESS_ADMINISTRATION_CHANGE";
export const LEAD_CONTRACT_DELETE_BILLING_ADDRESS = 'LEAD_CONTRACT_DELETE_BILLING_ADDRESS';
export const LEAD_CONTRACT_BILLING_ADDRESS_SAVED = 'LEAD_CONTRACT_BILLING_ADDRESS_SAVED';
export const LEAD_CONTRACT_BILLING_NAME1_CHANGE =
  "LEAD_CONTRACT_BILLING_NAME1_CHANGE";
export const LEAD_CONTRACT_BILLING_NAME2_CHANGE =
  "LEAD_CONTRACT_BILLING_NAME2_CHANGE";
export const LEAD_CONTRACT_BILLING_NAME3_CHANGE =
  "LEAD_CONTRACT_BILLING_NAME3_CHANGE";
export const LEAD_CONTRACT_CUSTOMER_TYPES_ID_CHANGE =
  "LEAD_CONTRACT_CUSTOMER_TYPES_ID_CHANGE";
export const LEAD_CONTRACT_DIVISIONS_ID_CHANGE =
  "LEAD_CONTRACT_DIVISIONS_ID_CHANGE";
export const LEAD_CONTRACT_IS_BIO5_CHANGE = "LEAD_CONTRACT_IS_BIO5_CHANGE";
export const LEAD_CONTRACT_BIO5_START_DATE_CHANGE =
  "LEAD_CONTRACT_BIO5_START_DATE_CHANGE";
export const LEAD_CONTRACT_IS_BIO10_CHANGE = "LEAD_CONTRACT_IS_BIO10_CHANGE";
export const LEAD_CONTRACT_BIO10_START_DATE_CHANGE =
  "LEAD_CONTRACT_BIO10_START_DATE_CHANGE";
export const LEAD_CONTRACT_IS_CLIMATE_PLUS_CHANGE =
  "LEAD_CONTRACT_IS_CLIMATE_PLUS_CHANGE";
export const LEAD_CONTRACT_IS_CO2_CHANGE = "LEAD_CONTRACT_IS_CO2_CHANGE";
export const LEAD_CONTRACT_PASTE_BILLING_ADDRESS = 'LEAD_CONTRACT_PASTE_BILLING_ADDRESS';
export const LEAD_CONTRACT_PERIOD_CONSUMPTION_CHANGE =
  "LEAD_CONTRACT_PERIOD_CONSUMPTION_CHANGE";
export const LEAD_CONTRACT_POTS_ID_CHANGE = "LEAD_CONTRACT_POTS_ID_CHANGE";
export const LEAD_CONTRACT_PRODUCTS_ID_CHANGE =
  "LEAD_CONTRACT_PRODUCTS_ID_CHANGE";
export const LEAD_CONTRACT_SALUTATIONS_ID_CHANGE =
  "LEAD_CONTRACT_SALUTATIONS_ID_CHANGE";
export const LEAD_CONTRACT_TAKEOVER_BILLING_ADDRESS = 'LEAD_CONTRACT_TAKEOVER_BILLING_ADDRESS';
export const LEAD_CONTRACT_VALID_FROM_CHANGE =
  "LEAD_CONTRACT_VALID_FROM_CHANGE";
export const LEAD_CONTRACT_VALID_TO_CHANGE = "LEAD_CONTRACT_VALID_TO_CHANGE";
export const IS_LEAD_CONTRACT_TRANSITION_CHANGE = "IS_LEAD_CONTRACT_TRANSITION_CHANGE";
export const LEAD_CONTRACT_TRANSITION_DATE_CHANGE = "LEAD_CONTRACT_TRANSITION_DATE_CHANGE";
export const IS_LEAD_CONTRACT_TRANSITION_TEN_PERCENT_CHANGE = "IS_LEAD_CONTRACT_TRANSITION_TEN_PERCENT_CHANGE";
export const LEAD_CONTRACT_TRANSITION_TEN_PERCENT_DATE_CHANGE = "LEAD_CONTRACT_TRANSITION_TEN_PERCENT_DATE_CHANGE";
export const LEAD_COUNTER_ACCOUNT_NO_CHANGE = "LEAD_COUNTER_ACCOUNT_NO_CHANGE";
export const LEAD_COUNTER_ACCOUNT_OWNER_CHANGE =
  "LEAD_COUNTER_ACCOUNT_OWNER_CHANGE";
export const LEAD_COUNTER_BANK_CODE_CHANGE = "LEAD_COUNTER_BANK_CODE_CHANGE";
export const LEAD_COUNTER_BHKW = "LEAD_COUNTER_BHKW";
export const LEAD_COUNTER_CHANGE_REASON_ID_CHANGE =
  "LEAD_COUNTER_CHANGE_REASON_ID_CHANGE";
export const LEAD_COUNTER_CHANGE_SUPPLY_START_CHANGE =
  "LEAD_COUNTER_CHANGE_SUPPLY_START_CHANGE";
export const LEAD_COUNTER_CONTRACT_ACCOUNT_CHANGE =
  "LEAD_COUNTER_CONTRACT_ACCOUNT_CHANGE";
export const LEAD_COUNTER_DOUBLINGS_LIST = "LEAD_COUNTER_DOUBLINGS_LIST";
export const LEAD_COUNTER_FORMER_SUPPLIER_ID_CHANGE =
  "LEAD_COUNTER_FORMER_SUPPLIER_ID_CHANGE";
export const LEAD_COUNTER_IBAN_CHANGE = "LEAD_COUNTER_IBAN_CHANGE";
export const LEAD_COUNTER_ID_CHANGE = "LEAD_COUNTER_ID_CHANGE";
export const LEAD_COUNTER_INSTALLMENT_CYCLE_CHANGE =
  "LEAD_COUNTER_INSTALLMENT_CYCLE_CHANGE";
export const LEAD_COUNTER_INTENDED_USE_CHANGE =
  "LEAD_COUNTER_INTENDED_USE_CHANGE";
export const LEAD_COUNTRY_LOCATION_ADDITIONAL_CHANGE =
  "LEAD_COUNTRY_LOCATION_ADDITIONAL_CHANGE";
export const LEAD_COUNTER_LOCATION_CITY_CHANGE =
  "LEAD_COUNTER_LOCATION_CITY_CHANGE";
export const LEAD_COUNTER_LOCATION_COUNTRY_CHANGE =
  "LEAD_COUNTER_LOCATION_COUNTRY_CHANGE";
export const LEAD_COUNTER_LOCATION_DISTRICT_CHANGE =
  "LEAD_COUNTER_LOCATION_DISTRICT_CHANGE";
export const LEAD_COUNTER_LOCATION_HOUSE_NUMBER_CHANGE =
  "LEAD_COUNTER_LOCATION_HOUSE_NUMBER_CHANGE";
export const LEAD_COUNTER_LOCATION_MARKET_LOCATION_CHANGE =
  "LEAD_COUNTER_LOCATION_MARKET_LOCATION_CHANGE";
export const LEAD_COUNTER_LOCATION_MEASURE_LOCATION_CHANGE =
  "LEAD_COUNTER_LOCATION_MEASURE_LOCATION_CHANGE";
export const LEAD_COUNTER_LOCATION_POSTAL_CODE_CHANGE =
  "LEAD_COUNTER_LOCATION_POSTAL_CODE_CHANGE";
export const LEAD_COUNTER_LOCATION_STREET_CHANGE =
  "LEAD_COUNTER_LOCATION_STREET_CHANGE";
export const LEAD_COUNTER_MEDIUM_VOLTAGE_CHANGE =
  "LEAD_COUNTER_MEDIUM_VOLTAGE_CHANGE";
export const LEAD_COUNTER_NETWORKS_ID_CHANGE =
  "LEAD_COUNTER_NETWORKS_ID_CHANGE";
export const LEAD_COUNTER_NUMBER_CURRENT_CHANGE =
  "LEAD_COUNTER_NUMBER_CURRENT_CHANGE";
export const LEAD_COUNTER_LOAD_PROFILES_ID_CHANGE =
  "LOAD_COUNTER_LEAD_PROFILES_ID_CHANGE";
export const LEAD_COUNTER_VALUE_CHANGE = "LEAD_COUNTER_VALUE_CHANGE";
export const LEAD_DOUBLE_COUNTER_DIALOG_CLOSE =
  "LEAD_DOUBLE_COUNTER_DIALOG_CLOSE";
export const LEAD_OBJECT_LIST_DIALOG = "LEAD_OBJECT_LIST_DIALOG";
export const LEAD_OBJECT_LIST_IS_FINISHED = "LEAD_OBJECT_LIST_IS_FINISHED";
export const LEGAL_FORMS_ID_CHANGE = 'LEGAL_FORMS_ID_CHANGE';
export const READING_TYPE_NAME_CHANGE = 'READING_TYPE_NAME_CHANGE';
export const PHONE_CHANGE = "PHONE_CHANGE";
export const READING_TYPES_ID_CHANGE = 'READING_TYPES_ID_CHANGE';
export const SALUTATIONS_ID_CHANGE = "SALUTATIONS_ID_CHANGE";
export const SET_ACTIVE_LEAD_CONTRACT_TAB = "SET_ACTIVE_LEAD_CONTRACT_TAB";
export const SET_ERR_MSG = "SET_ERR_MSG";
export const SET_LEAD_DETAIL_DIALOG_ACTIVE_TAB =
  "SET_LEAD_DETAIL_DIALOG_ACTIVE_TAB";
export const UPDATE_CONTRACT_EXTERNALS = "UPDATE_CONTRACT_EXTERNALS";

export const LEAD_CONTRACT_BILLING_STREET_CHANGE =
  "LEAD_CONTRACT_BILLING_STREET_CHANGE";
export const LEAD_CONTRACT_BILLING_HOUSE_NUMBER_CHANGE =
  "LEAD_CONTRACT_BILLING_HOUSE_NUMBER_CHANGE";
export const LEAD_CONTRACT_BILLING_POSTAL_CODE_CHANGE =
  "LEAD_CONTRACT_BILLING_POSTAL_CODE_CHANGE";
export const LEAD_CONTRACT_BILLING_CITY_CHANGE =
  "LEAD_CONTRACT_BILLING_CITY_CHANGE";
export const LEAD_CONTRACT_BILLING_DISTRICT_CHANGE =
  "LEAD_CONTRACT_BILLING_DISTRICT_CHANGE";
export const LEAD_CONTRACT_BILLING_COUNTRY_CHANGE =
  "LEAD_CONTRACT_BILLING_COUNTRY_CHANGE";
export const LEAD_CONTRACT_BILLING_ADDITIONAL_CHANGE =
  "LEAD_CONTRACT_BILLING_ADDITIONAL_CHANGE";
export const LEAD_CONTRACT_BILLING_POSTBOX_NO_CHANGE =
  "LEAD_CONTRACT_BILLING_POSTBOX_NO_CHANGE";
export const LEAD_CONTRACT_BILLING_POSTBOX_POSTAL_CODE_CHANGE =
  "LEAD_CONTRACT_BILLING_POSTBOX_POSTAL_CODE_CHANGE";
export const LEAD_CONTRACT_BILLING_POSTBOX_CITY_CHANGE =
  "LEAD_CONTRACT_BILLING_POSTBOX_CITY_CHANGE";

export const CONTACT_HISTORY_DETAIL_DOCUMENT_CHANGE =
  "CONTACT_HISTORY_DETAIL_DOCUMENT_CHANGE";
export const CONTACT_HISTORY_DETAIL_DOCUMENT_DELETE =
  "CONTACT_HISTORY_DETAIL_DOCUMENT_DELETE";
export const CHANGE_CONTACT_HISTORY_DETAIL_OBJECT_ID =
  "CHANGE_CONTACT_HISTORY_DETAIL_OBJECT_ID";
export const CHANGE_CONTACT_HISTORY_DETAIL_REMARKS =
  "CHANGE_CONTACT_HISTORY_DETAIL_REMARKS";
export const CHANGE_CONTACT_HISTORY_DETAIL_TOPIC =
  "CHANGE_CONTACT_HISTORY_DETAIL_TOPIC";
export const CLOSE_CONTACT_HISTORY_DIALOG = "CLOSE_CONTACT_HISTORY_DIALOG";
export const CLOSE_DELETE_LEAD_CONTACT_HISTORY_CONFIRM =
  "CLOSE_DELETE_LEAD_CONTACT_HISTORY_CONFIRM";
export const DELETE_LEAD_CONTACT_HISTORY_CONFIRM =
  "DELETE_LEAD_CONTACT_HISTORY_CONFIRM";
export const EDIT_CONTACT_HISTORY = "EDIT_CONTACT_HISTORY";
export const GET_CONTACT_HISTORIES = "GET_CONTACT_HISTORIES";
export const SET_CONTACT_HISTORY_ERROR = "SET_CONTACT_HISTORY_ERROR";

export const CLOSE_LEAD_CONTRACTS_DIALOG = "CLOSE_LEAD_CONTRACTS_DIALOG";
export const GET_CONTRACTS = "GET_CONTRACTS";
export const GET_LEADS = "GET_LEADS";
export const DELETE_CONTRACT_CONFIRM = "DELETE_CONTRACT_CONFIRM";
export const CLOSE_DELETE_CONTRACT = "CLOSE_DELETE_CONTRACT";
export const CLOSE_DELETE_LEAD_CONFIRM = "CLOSE_DELETE_LEAD_CONFIRM";
export const DELETE_LEAD_CONFIRM = "DELETE_LEAD_CONFIRM";

//  CAMPAIGNS
export const ADMINISTRATIONS_ID_CHANGE = "ADMINISTRATIONS_ID_CHANGE";
export const AP_CHANGE = "AP_CHANGE";
export const AP_NT_CHANGE = "AP_NT_CHANGE";
export const BIO5_CHANGE = "BIO5_CHANGE";
export const BIO10_CHANGE = "BIO10_CHANGE";
export const CAMPAIGN_DIVISIONS_ID_CHANGE = "CAMPAIGN_DIVISIONS_ID_CHANGE";
export const CAMPAIGN_ENERGY_PRODUCTS = "CAMPAIGN_ENERGY_PRODUCTS";
export const CAMPAIGN_GAS_PRODUCTS = "CAMPAIGN_GAS_PRODUCTS";
export const CAMPAIGN_NAME_CHANGE = "CAMPAIGN_NAME_CHANGE";
export const CAMPAIGN_NO_PRODUCTS = "CAMPAIGN_NO_PRODUCTS";
export const CLIMATE_PLUS_CHANGE = "CLIMATE_PLUS_CHANGE";
export const CLOSE_CAMPAIGN_ASSIGNMENT_DELETE_DIALOG = "CLOSE_CAMPAIGN_ASSIGNMENT_DELETE_DIALOG";
export const CLOSE_CAMPAIGN_DETAIL = "CLOSE_CAMPAIGN_DETAIL";
export const CLOSE_DELETE_CAMPAIGN_CONFIRM = "CLOSE_DELETE_CAMPAIGN_CONFIRM";
export const CLOSE_ENERGY_CAMPAIGN_ASSIGNMENT = "CLOSE_ENERGY_CAMPAIGN_ASSIGNMENT";
export const CLOSE_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_DIALOG = "CLOSE_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_DIALOG";
export const CLOSE_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_DELETE_DIALOG = "CLOSE_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_DELETE_DIALOG";
export const CLOSE_GAS_CAMPAIGN_ASSIGNMENT_DELETE_CONFIRM = "CLOSE_GAS_CAMPAIGN_ASSIGNMENT_DELETE_CONFIRM";
export const CLOSE_GAS_CAMPAIGN_ASSIGNMENT = "CLOSE_GAS_CAMPAIGN_ASSIGNMENT";
export const CLOSE_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_DIALOG = "CLOSE_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_DIALOG";
export const CLOSE_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_DELETE_DIALOG = "CLOSE_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_DELETE_DIALOG";
export const CLOSE_GAS_PRODUCT_CONFIRM = "CLOSE_GAS_PRODUCT_CONFIRM";
export const CLOSE_GAS_PRODUCT_DETAIL = "CLOSE_GAS_PRODUCT_DETAIL";
export const CLOSE_PRODUCT_CONFIRM = "CLOSE_PRODUCT_CONFIRM";
export const CLOSE_PRODUCT_DETAIL = "CLOSE_PRODUCT_DETAIL";
export const CO2_CHANGE = "CO2_CHANGE";
export const DELETE_CAMPAIGN_ASSIGNMENT_CONFIRM = "DELETE_CAMPAIGN_ASSIGNMENT_CONFIRM";
export const DELETE_CAMPAIGN_CONFIRM = "DELETE_CAMPAIGN_CONFIRM";
export const DELETE_ENERGY_ASSIGNMENT_TIMEFRAME_CONFIRM = "DELETE_ENERGY_ASSIGNMENT_TIMEFRAME_CONFIRM";
export const DELETE_GAS_PRODUCT_CONFIRM = "DELETE_GAS_PRODUCT_CONFIRM";
export const DELETE_GAS_ASSIGNMENT_TIMEFRAME_CONFIRM = "DELETE_GAS_ASSIGNMENT_TIMEFRAME_CONFIRM";
export const DELETE_PRODUCT_CONFIRM = "DELETE_PRODUCT_CONFIRM";
export const EDIT_CAMPAIGN = "EDIT_CAMPAIGN";
export const EDIT_ENERGY_CAMPAIGN_ASSIGNMENT = "EDIT_ENERGY_CAMPAIGN_ASSIGNMENT";
export const EDIT_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME = "EDIT_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME";
export const EDIT_GAS_CAMPAIGN_ASSIGNMENT = "EDIT_GAS_CAMPAIGN_ASSIGNMENT";
export const EDIT_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME = "EDIT_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME";
export const EDIT_GAS_PRODUCT = "EDIT_GAS_PRODUCT";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const GAS_CAMPAIGN_ASSIGNMENT_LOAD_PROFILES_ID_CHANGE = "GAS_CAMPAIGN_ASSIGNMENT_LOAD_PROFILES_ID_CHANGE";
export const GAS_CAMPAIGN_ASSIGNMENT_PRODUCTS_ID_CHANGE = "GAS_CAMPAIGN_ASSIGNMENT_PRODUCTS_ID_CHANGE";
export const GAS_CAMPAIGN_ASSIGNMENT_PRODUCT_TYPES_ID_CHANGE = "GAS_CAMPAIGN_ASSIGNMENT_PRODUCT_TYPES_ID_CHANGE";
export const GAS_CAMPAIGN_ASSIGNMENT_PRODUCT_VALID_FROM_CHANGE = "GAS_CAMPAIGN_ASSIGNMENT_PRODUCT_VALID_FROM_CHANGE";
export const GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_AP_CHANGE = "GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_AP_CHANGE";
export const GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_BEHG_CHANGE = "GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_BEHG_CHANGE";
export const GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_BIO5_CHANGE = "GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_BIO5_CHANGE";
export const GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_BIO10_CHANGE = "GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_BIO10_CHANGE";
export const GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_CLIMATE_PLUS_CHANGE = "GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_CLIMATE_PLUS_CHANGE";
export const GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_GP_CHANGE = "GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_GP_CHANGE";
export const GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_TEHG_CHANGE = "GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_TEHG_CHANGE";
export const GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_VALID_TO_CHANGE = "GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_VALID_TO_CHANGE";
export const GAS_CAMPAIGN_ASSIGNMENT_TARIF_OLD_CHANGE = "GAS_CAMPAIGN_ASSIGNMENT_TARIF_OLD_CHANGE";
export const GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_VALID_FROM_CHANGE = "GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_VALID_FROM_CHANGE";
export const GAS_PRODUCT_TYPES_ID_CHANGE = "GAS_PRODUCT_TYPES_ID_CHANGE";
export const GAS_PRODUCTS_ID_CHANGE = "GAS_PRODUCTS_ID_CHANGE";
export const GET_CAMPAIGN_ASSIGNMENTS = "GET_CAMPAIGN_ASSIGNMENTS";
export const GET_CAMPAIGNS = "GET_CAMPAIGNS";
export const GET_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAMES = "GET_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAMES";
export const GET_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAMES = "GET_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAMES";
export const GP_CHANGE = "GP_CHANGE";
export const LOAD_PROFILES_ID_CHANGE = "LOAD_PROFILES_ID_CHANGE";
export const PRODUCT_TYPES_ID_CHANGE = "PRODUCT_TYPES_ID_CHANGE";
export const PRODUCT_VALID_FROM_CHANGE = "PRODUCT_VALID_FROM_CHANGE";
export const SET_CAMPAIGN_DETAIL_ERROR = "SET_CAMPAIGN_DETAIL_ERROR";
export const SET_CAMPAIGN_ERROR = "SET_CAMPAIGN_ERROR";
export const SET_CAMPAIGN_FOREIGNS = "SET_CAMPAIGN_FOREIGNS";
export const SET_INDIVIDUAL_PRODUCT_FOREIGNS = "SET_INDIVIDUAL_PRODUCT_FOREIGNS";
export const SET_DIVISION_ASSIGNMENT = "SET_DIVISION_ASSIGNMENT";
export const SET_ENERGY_ERROR = "SET_ENERGY_ERROR";
export const SET_ENERGY_CAMPAIGN_ASSIGNMENT_ERROR = "SET_ENERGY_CAMPAIGN_ASSIGNMENT_ERROR";
export const SET_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_ERROR = "SET_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_ERROR";
export const ENERGY_CAMPAIGN_ASSIGNMENT_LOAD_PROFILES_ID_CHANGE = "ENERGY_CAMPAIGN_ASSIGNMENT_LOAD_PROFILES_ID_CHANGE";
export const ENERGY_CAMPAIGN_ASSIGNMENT_PRODCUTS_ID_CHANGE = "ENERGY_CAMPAIGN_ASSIGNMENT_PRODCUTS_ID_CHANGE";
export const ENERGY_CAMPAIGN_ASSIGNMENT_PRODUCT_TYPES_ID_CHANGE = "ENERGY_CAMPAIGN_ASSIGNMENT_PRODUCT_TYPES_ID_CHANGE";
export const ENERGY_CAMPAIGN_ASSIGNMENT_PRODUCT_VALID_FROM_CHANGE = "ENERGY_CAMPAIGN_ASSIGNMENT_PRODUCT_VALID_FROM_CHANGE";
export const ENERGY_CAMPAIGN_ASSIGNMENT_SWITCHING_TIMES_ID_CHANGE = "ENERGY_CAMPAIGN_ASSIGNMENT_SWITCHING_TIMES_ID_CHANGE";
export const ENERGY_CAMPAIGN_ASSIGNMENT_TARIF_OLD_CHANGE = "ENERGY_CAMPAIGN_ASSIGNMENT_TARIF_OLD_CHANGE";
export const ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_AP_CHANGE = "ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_AP_CHANGE";
export const ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_AP_NT_CHANGE = "ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_AP_NT_CHANGE";
export const ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_CLIMATE_PLUS_CHANGE = "ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_CLIMATE_PLUS_CHANGE";
export const ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_GP_CHANGE = "ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_GP_CHANGE";
export const ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_VALID_FROM_CHANGE = "ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_VALID_FROM_CHANGE";
export const ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_VALID_TO_CHANGE = "ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_VALID_TO_CHANGE";
export const SET_GAS_CAMPAIGN_ASSIGNMENT_ERROR = "SET_GAS_CAMPAIGN_ASSIGNMENT_ERROR";
export const SET_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_ERROR = "SET_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_ERROR";
export const SWITCHING_TIMES_ID_CHANGE = "SWITCHING_TIMES_ID_CHANGE";
export const TARIF_OLD_CHANGE = "TARIF_OLD_CHANGE";
export const INDIVIDUAL_PRODUCT_NAME_CHANGE = "INDIVIDUAL_PRODUCT_NAME_CHANGE";
export const VALID_FROM_CHANGE = "VALID_FROM_CHANGE";
export const VALID_TO_CHANGE = "VALID_TO_CHANGE";
// neu
export const EDIT_GAS_TIMEFRAME = "EDIT_GAS_TIMEFRAME";
export const SET_TIMEFRAME_ERROR = "SET_TIMEFRAME_ERROR";
export const CLOSE_GAS_TIMEFRAME = "CLOSE_GAS_TIMEFRAME";
export const GET_GAS_CAMPAIGN_TIMEFRAMES = "GET_GAS_CAMPAIGN_TIMEFRAMES";
export const CLOSE_GAS_PRODUCT_TIMEFRAME = "CLOSE_GAS_PRODUCT_TIMEFRAME";
export const DELETE_GAS_TIMEFRAME_CONFIRM = "DELETE_GAS_TIMEFRAME_CONFIRM";
export const CLOSE_GAS_PRODUCT_TIMEFRAME_CONFIRM =
  "CLOSE_GAS_PRODUCT_TIMEFRAME_CONFIRM";
export const CLOSE_DELETE_INDIVIDUAL_PRODUCT_CONFIRM = "CLOSE_DELETE_INDIVIDUAL_PRODUCT_CONFIRM";
export const EDIT_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME = "EDIT_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME";
export const GET_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAMES = "GET_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAMES";
export const CLOSE_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT = "CLOSE_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT";
export const SET_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_ERROR = "SET_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_ERROR";
export const DELETE_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_CONFIRM = "DELETE_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_CONFIRM";
export const GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODUCT_TYPES_ID_CHANGE = "GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODUCT_TYPES_ID_CHANGE";
export const GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_LOAD_PROFILES_ID_CHANGE = "GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_LOAD_PROFILES_ID_CHANGE";
export const GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODUCTS_ID_CHANGE = "GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODUCTS_ID_CHANGE";
export const GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TARIF_OLD_CHANGE = "GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TARIF_OLD_CHANGE";
export const GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODUCT_VALID_FROM_CHANGE = "GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODUCT_VALID_FROM_CHANGE";
export const CLOSE_INDIVIDUAL_PRODUCT_ASSIGNMENT_CONFIRM = "CLOSE_INDIVIDUAL_PRODUCT_ASSIGNMENT_CONFIRM";
export const CLOSE_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_DIALOG = "CLOSE_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_DIALOG";
export const SET_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_ERROR = "SET_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_ERROR";
export const GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_VALID_FROM_CHANGE = "GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_VALID_FROM_CHANGE";
export const GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_VALID_TO_CHANGE = "GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_VALID_TO_CHANGE";
export const GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_GP_CHANGE = "GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_GP_CHANGE";
export const GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_AP_CHANGE = "GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_AP_CHANGE";
export const GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_BEHG_CHANGE = "GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_BEHG_CHANGE";
export const GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_CLIMATE_PLUS_CHANGE = "GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_CLIMATE_PLUS_CHANGE";
export const GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_BIO5_CHANGE = "GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_BIO5_CHANGE";
export const GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_BIO10_CHANGE = "GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_BIO10_CHANGE";
export const GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_TEHG_CHANGE = "GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_TEHG_CHANGE";
export const CLOSE_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_DELETE_DIALOG = "CLOSE_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_DELETE_DIALOG";
export const EDIT_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT = "EDIT_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT";
export const CLOSE_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT = "CLOSE_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT";
export const EDIT_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME = "EDIT_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME";
export const GET_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAMES = "GET_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAMES";
export const SET_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_ERROR = "SET_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_ERROR";
export const ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODUCT_TYPES_ID_CHANGE = "ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODUCT_TYPES_ID_CHANGE";
export const ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_LOAD_PROFILES_ID_CHANGE = "ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_LOAD_PROFILES_ID_CHANGE";
export const ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODCUTS_ID_CHANGE = "ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODCUTS_ID_CHANGE";
export const ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_SWITCHING_TIMES_ID_CHANGE = "ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_SWITCHING_TIMES_ID_CHANGE";
export const ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TARIF_OLD_CHANGE = "ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TARIF_OLD_CHANGE";
export const ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODUCT_VALID_FROM_CHANGE = "ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODUCT_VALID_FROM_CHANGE";
export const CLOSE_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_DIALOG = "CLOSE_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_DIALOG";
export const SET_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_ERROR = "SET_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_ERROR";
export const ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_VALID_FROM_CHANGE = "ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_VALID_FROM_CHANGE";
export const ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_VALID_TO_CHANGE = "ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_VALID_TO_CHANGE";
export const ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_GP_CHANGE = "ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_GP_CHANGE";
export const ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_AP_CHANGE = "ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_AP_CHANGE";
export const ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_CLIMATE_PLUS_CHANGE = "ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_CLIMATE_PLUS_CHANGE";
export const ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_AP_NT_CHANGE = "ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_AP_NT_CHANGE";
export const CLOSE_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_DELETE_DIALOG = "CLOSE_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_DELETE_DIALOG";

//  INDIVIDUAL PRODUCTS
export const INDIVIDUAL_ENERGY_PRODUCTS = "INDIVIDUAL_ENERGY_PRODUCTS";
export const INDIVIDUAL_GAS_PRODUCTS = "INDIVIDUAL_GAS_PRODUCTS";
export const INDIVIDUAL_NO_PRODUCTS = "INDIVIDUAL_NO_PRODUCTS";
export const CLOSE_INDIVIDUAL_PRODUCT_DETAIL = "CLOSE_INDIVIDUAL_PRODUCT_DETAIL";
export const CLOSE_INDIVIDUAL_PRODUCT_CONFIRM = "CLOSE_INDIVIDUAL_PRODUCT_CONFIRM";
export const DELETE_INDIVIDUAL_PRODUCT_CONFIRM = "DELETE_INDIVIDUAL_PRODUCT_CONFIRM";
export const EDIT_INDIVIDUAL_PRODUCT = "EDIT_INDIVIDUAL_PRODUCT";
export const CUSTOMER_KIND_IDS_ID_CHANGE = "CUSTOMER_KIND_IDS_ID_CHANGE";
export const CUSTOMER_KINDS_ID_CHANGE = "CUSTOMER_KINDS_ID_CHANGE";
export const GET_INDIVIDUAL_PRODUCT_ASSIGNMENTS = "GET_INDIVIDUAL_PRODUCT_ASSIGNMENTS";
export const GET_INDIVIDUAL_PRODUCTS = "GET_INDIVIDUAL_PRODUCTS";
export const SET_INDIVIDUAL__PRODUCT_ERROR = "SET_INDIVIDUAL__PRODUCT_ERROR";
export const INDIVIDUAL_PRODUCT_DIVISIONS_ID_CHANGE = "INDIVIDUAL_PRODUCT_DIVISIONS_ID_CHANGE";
export const DELETE_INDIVIDUAL_PRODUCT_ASSIGNMENT_CONFIRM = "DELETE_INDIVIDUAL_PRODUCT_ASSIGNMENT_CONFIRM";
export const EDIT_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT = "EDIT_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT";

//	EXPORTS
export const GET_EXPORTS_NEW_ONES = "GET_EXPORTS_NEW_ONES";
export const GET_EXPORTS_EXISTING_ONES = "GET_EXPORTS_EXISTING_ONES";
export const GET_EXPORTS_TERMINATES = "GET_EXPORTS_TERMINATES";
export const GET_EXPORTS_PORTAL = "GET_EXPORTS_PORTAL";
export const GET_EXPORTS_NAVIGATION = "GET_EXPORTS_NAVIGATION";
export const GET_EXPORTS_PRODUCT_GROUPS = "GET_EXPORTS_PRODUCT_GROUPS";
export const GET_EXPORTS_PRODUCTS = "GET_EXPORTS_PRODUCTS";

//  Regulation Zones
export const ZONE_NAME_CHANGE = "ZONE_NAME_CHANGE";
export const ZONE_SHORTCUT_CHANGE = "ZONE_SHORTCUT_CHANGE";

//  Networks
export const SET_NETWORK_FOREIGNS = "SET_NETWORK_FOREIGNS";
export const NETWORK_SHORTCUT_CHANGE = "NETWORK_SHORTCUT_CHANGE";
export const NETWORK_NAME_CHANGE = "NETWORK_NAME_CHANGE";
export const REGULATION_ZONES_ID_CHANGE = "REGULATION_ZONES_ID_CHANGE";

//  Suppliers
export const SET_SUPPLIER_FOREIGNS = "SET_SUPPLIER_FOREIGNS";
export const SUPPLIER_SHORTCUT_CHANGE = "SUPPLIER_SHORTCUT_CHANGE";
export const NAME_ADDITION_1_CHANGE = "NAME_ADDITION_1_CHANGE";
export const NAME_ADDITION_2_CHANGE = "NAME_ADDITION_2_CHANGE";
export const STREET_CHANGE = "STREET_CHANGE";
export const HOUSE_NUMBER_CHANGE = "HOUSE_NUMBER_CHANGE";
export const POSTAL_CODE_CHANGE = "POSTAL_CODE_CHANGE";
export const CITY_CHANGE = "CITY_CHANGE";

//  Administrations
export const SET_ADMINISTRATION_FOREIGNS = "SET_ADMINISTRATION_FOREIGNS";
export const ADMINISTRATION_NAME_CHANGE = "ADMINISTRATION_NAME_CHANGE";
export const CUSTOMERS_ID_CHANGE = "CUSTOMERS_ID_CHANGE";
export const IS_OWNER_CHANGE = "IS_OWNER_CHANGE";
export const ADMINISTRATION_TYPES_ID_CHANGE = "ADMINISTRATION_TYPES_ID_CHANGE";
export const ADMINISTRATIONS_ID_TOP_CHANGE = "ADMINISTRATIONS_ID_TOP_CHANGE";

//  Energy Supplier
export const SUPPLIER_NAME_CHANGE = "SUPPLIER_NAME_CHANGE";
export const BALANCING_GROUPS_ID_GAS_CHANGE = "BALANCING_GROUPS_ID_GAS_CHANGE";
export const BALANCING_GROUPS_ID_POWER_CHANGE =
  "BALANCING_GROUPS_ID_POWER_CHANGE";
export const SET_ENERGY_SUPPLIER_FOREIGNS = "SET_ENERGY_SUPPLIER_FOREIGNS";

//  Markets
export const MARKET_ID_CHANGE = "MARKET_ID_CHANGE";
export const MARKET_NAME_CHANGE = "MARKET_NAME_CHANGE";
export const DIVISIONS_ID_CHANGE = "DIVISIONS_ID_CHANGE";
export const SET_MARKET_FOREIGNS = "SET_MARKET_FOREIGNS";

//  Load Profiles
export const LOAD_PROFILE_NAME_CHANGE = "LOAD_PROFILE_NAME_CHANGE";
export const LOAD_PROFILE_SHORTCUT_CHANGE = "LOAD_PROFILE_SHORTCUT_CHANGE";

//  Energy Assignments
export const SET_ENERGY_ASSIGNMENT_FOREIGNS = "SET_ENERGY_ASSIGNMENT_FOREIGNS";
export const MARKETS_ID_CHANGE = "MARKETS_ID_CHANGE";
export const BALANCING_GROUPS_ID_CHANGE = "BALANCING_GROUPS_ID_CHANGE";

//	Legal Forms
export const LEGAL_FORM_NAME_CHANGE = "LEGAL_FORM_NAME_CHANGE";

//  Transaction Reasons
export const REASON_NAME_CHANGE = "REASON_NAME_CHANGE";
export const REASON_SHORTCUT_CHANGE = "REASON_SHORTCUT_CHANGE";
export const T_SWITCH_TYPE_CHANGE = "T_SWITCH_TYPE_CHANGE";
export const IS_NETWORK_OPERATOR_NEEDED_CHANGE = "IS_NETWORK_OPERATOR_NEEDED_CHANGE";

//  Divisions
export const DIVISION_NAME_CHANGE = "DIVISION_NAME_CHANGE";
export const INDEX_SAP_CHANGE = "INDEX_SAP_CHANGE";

//  Balancing Groups
export const BALANCING_GROUP_NAME_CHANGE = "BALANCING_GROUP_NAME_CHANGE";

//  Salutations
export const SALUTATION_CHANGE = "SALUTATION_CHANGE";
export const SALUTATION_INDEX_SAP_CHANGE = "SALUTATION_INDEX_SAP_CHANGE";

//  Business Partner Types
export const TYPE_INDEX_SAP_CHANGE = "TYPE_INDEX_SAP_CHANGE";
export const TYPE_NAME_CHANGE = "TYPE_NAME_CHANGE";
export const TYPE_ABBREVIATION_CHANGE = "TYPE_ABBREVIATION_CHANGE";

//	Tarif Types
export const SHORTCUT_CHANGE = "SHORTCUT_CHANGE";
export const TARIF_TYPE_NAME_CHANGE = "TARIF_TYPE_NAME_CHANGE";

//  Product Types
export const IS_BIO_5_CHANGE = "IS_BIO_5_CHANGE";
export const IS_BIO_10_CHANGE = "IS_BIO_10_CHANGE";
export const IS_CLIMATE_PLUS_CHANGE = "IS_CLIMATE_PLUS_CHANGE";
export const IS_CO2_CHANGE = "IS_CO2_CHANGE";
export const PRODUCT_TYPE_NAME_CHANGE = "PRODUCT_TYPE_NAME_CHANGE";
export const SET_MULTI_OPTION = "SET_MULTI_OPTION";

//  Product Type Options
export const IS_AP_HT_CHANGE = "IS_AP_HT_CHANGE";
export const IS_AP_NT_CHANGE = "IS_AP_NT_CHANGE";
export const IS_GP_CHANGE = "IS_GP_CHANGE";
export const PRODUCT_TYPE_OPTION_NAME_CHANGE =
  "PRODUCT_TYPE_OPTION_NAME_CHANGE";

//  Product Groups
export const PRODUCT_GROUP_NAME_CHANGE = "PRODUCT_GROUP_NAME_CHANGE";
export const DESCRIPTION_CHANGE = "DESCRIPTION_CHANGE";
export const TARIF_TYPES_ID_CHANGE = "TARIF_TYPES_ID_CHANGE";
export const ENERGY_SUPPLIER_ID_CHANGE = "ENERGY_SUPPLIER_ID_CHANGE";
export const NO_CHANGE_STATE = "NO_CHANGE_STATE";

//  Products
export const PRODUCT_NAME_CHANGE = "PRODUCT_NAME_CHANGE";

//  Product Assignments
export const PRODUCTS_ID_CHANGE = "PRODUCTS_ID_CHANGE";
export const PRODUCT_GROUPS_ID_CHANGE = "PRODUCT_GROUPS_ID_CHANGE";
export const SET_PRODUCT_ASSIGNMENT_FOREIGNS =
  "SET_PRODUCT_ASSIGNMENT_FOREIGNS";

// Parameters
export const PARAMETER_NAME_CHANGE = "PARAMETER_NAME_CHANGE";
export const URL_CHANGE = "URL_CHANGE";

//  Users
export const CUSTOMER_PORTAL_ID_CHANGE = "CUSTOMER_PORTAL_ID_CHANGE";
export const GENDERS_ID_CHANGE = "GENDERS_ID_CHANGE";
export const EMAIL_CHANGE = "EMAIL_CHANGE";
export const FIRSTNAME_CHANGE = "FIRSTNAME_CHANGE";
export const IS_SUPPORTER_CHANGE = "IS_SUPPORTER_CHANGE";
export const IS_SUPPORTER_PV_CHANGE = "IS_SUPPORTER_PV_CHANGE";
export const LASTNAME_CHANGE = "LASTNAME_CHANGE";
export const PW1_CHANGE = "PW1_CHANGE";
export const PW2_CHANGE = "PW2_CHANGE";
export const PW_CHANGE_DIALOG = "PW_CHANGE_DIALOG";
export const SET_PW_ERROR = "SET_PW_ERROR";
export const SET_USER_FOREIGNS = "SET_USER_FOREIGNS";
export const USER_ROLES_ID_CHANGE = "USER_ROLES_ID_CHANGE";

//  User Roles
export const CLOSE_USER_ROLE_DETAIL_DIALOG = "CLOSE_USER_ROLE_DETAIL_DIALOG";
export const EDIT_ENTITY = "EDIT_ENTITY";
export const SET_IS_READING_RIGHT = "SET_IS_READING_RIGHT";
export const SET_IS_WRITING_RIGHT = "SET_IS_WRITING_RIGHT";
export const USER_ROLE_NAME_CHANGE = "USER_ROLE_NAME_CHANGE";


export const IMS_KEY_CHANGE = 'IMS_KEY_CHANGE';
export const IMS_NAME_CHANGE = 'IMS_NAME_CHANGE';

//	Contact History / Sales
export const CHANGE_ADMINISTRATION_1 = 'CHANGE_ADMINISTRATION_1';
export const CHANGE_ADMINISTRATION_1_OPTIONS = 'CHANGE_ADMINISTRATION_1_OPTIONS';
export const CHANGE_ADMINISTRATION_1_OPTIONS_SELECTED = 'CHANGE_ADMINISTRATION_1_OPTIONS_SELECTED';
export const CHANGE_CONTACT_AGREE_TYPES_FLAG = 'CHANGE_CONTACT_AGREE_TYPES_FLAG';
export const CHANGE_CONTACT_DATE = 'CHANGE_CONTACT_DATE';
export const CHANGE_CONTACT_TYPES_FLAG = 'CHANGE_CONTACT_TYPES_FLAG';
export const CHANGE_TITLE = 'CHANGE_TITLE';
export const CHANGE_CUSTOMER_TYPES_FLAG = 'CHANGE_CUSTOMER_TYPES_FLAG';
export const SET_ACTIVE_CONTACT = 'SET_ACTIVE_CONTACT';

export const ADMIN1_TS = 'ADMIN1_TS';