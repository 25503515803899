/**
 * 		File:		./src/context/campaigns/individualsReducer.js
 *
 * 		Subject:	Individual product's state reducer
 */

import {
  ADMINISTRATIONS_ID_CHANGE,
  AP_CHANGE,
  AP_NT_CHANGE,
  BIO5_CHANGE,
  BIO10_CHANGE,
  INDIVIDUAL_ENERGY_PRODUCTS,
  INDIVIDUAL_GAS_PRODUCTS,
  INDIVIDUAL_NO_PRODUCTS,
  CLIMATE_PLUS_CHANGE,
  CLOSE_DELETE_INDIVIDUAL_PRODUCT_CONFIRM,
  CLOSE_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT,
  CLOSE_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_DELETE_DIALOG,
  CLOSE_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_DIALOG,
  CLOSE_INDIVIDUAL_PRODUCT_ASSIGNMENT_CONFIRM,
  CLOSE_INDIVIDUAL_PRODUCT_DETAIL,
  CLOSE_INDIVIDUAL_PRODUCT_CONFIRM,
  CLOSE_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT,
  CLOSE_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_DELETE_DIALOG,
  CLOSE_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_DIALOG,
  CLOSE_GAS_PRODUCT_CONFIRM,
  CLOSE_GAS_PRODUCT_DETAIL,
  CLOSE_PRODUCT_CONFIRM,
  CLOSE_PRODUCT_DETAIL,
  CO2_CHANGE,
  DELETE_ENERGY_ASSIGNMENT_TIMEFRAME_CONFIRM,
  DELETE_GAS_PRODUCT_CONFIRM,
  DELETE_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_CONFIRM,
  DELETE_INDIVIDUAL_PRODUCT_CONFIRM,
  DELETE_INDIVIDUAL_PRODUCT_ASSIGNMENT_CONFIRM,
  DELETE_PRODUCT_CONFIRM,
  INDIVIDUAL_PRODUCT_DIVISIONS_ID_CHANGE,
  EDIT_INDIVIDUAL_PRODUCT,
  EDIT_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT,
  EDIT_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME,
  EDIT_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT,
  EDIT_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME,
  EDIT_GAS_PRODUCT,
  EDIT_PRODUCT,
  CUSTOMER_KIND_IDS_ID_CHANGE,
  CUSTOMER_KINDS_ID_CHANGE,
  ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_LOAD_PROFILES_ID_CHANGE,
  ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODCUTS_ID_CHANGE,
  ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODUCT_TYPES_ID_CHANGE,
  ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODUCT_VALID_FROM_CHANGE,
  ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_SWITCHING_TIMES_ID_CHANGE,
  ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TARIF_OLD_CHANGE,
  ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_AP_CHANGE,
  ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_AP_NT_CHANGE,
  ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_CLIMATE_PLUS_CHANGE,
  ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_GP_CHANGE,
  ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_VALID_FROM_CHANGE,
  ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_VALID_TO_CHANGE,
  GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_LOAD_PROFILES_ID_CHANGE,
  GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODUCTS_ID_CHANGE,
  GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TARIF_OLD_CHANGE,
  GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODUCT_TYPES_ID_CHANGE,
  GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODUCT_VALID_FROM_CHANGE,
  GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_AP_CHANGE,
  GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_BEHG_CHANGE,
  GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_BIO5_CHANGE,
  GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_BIO10_CHANGE,
  GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_CLIMATE_PLUS_CHANGE,
  GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_GP_CHANGE,
  GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_TEHG_CHANGE,
  GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_VALID_FROM_CHANGE,
  GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_VALID_TO_CHANGE,
  GAS_PRODUCT_TYPES_ID_CHANGE,
  GAS_PRODUCTS_ID_CHANGE,
  GET_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAMES,
  GET_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAMES,
  GET_INDIVIDUAL_PRODUCT_ASSIGNMENTS,
  GET_INDIVIDUAL_PRODUCTS,
  GP_CHANGE,
  LOAD_PROFILES_ID_CHANGE,
  PRODUCTS_ID_CHANGE,
  PRODUCT_TYPES_ID_CHANGE,
  PRODUCT_VALID_FROM_CHANGE,
  SET_INDIVIDUAL__PRODUCT_ERROR,
  SET_DIVISION_ASSIGNMENT,
  SET_ENERGY_ERROR,
  SET_ERROR,
  SET_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_ERROR,
  SET_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_ERROR,
  SET_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_ERROR,
  SET_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_ERROR,
  SET_INDIVIDUAL_PRODUCT_FOREIGNS,
  SWITCHING_TIMES_ID_CHANGE,
  TARIF_OLD_CHANGE,
  INDIVIDUAL_PRODUCT_NAME_CHANGE,
  VALID_FROM_CHANGE,
  VALID_TO_CHANGE,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case ADMINISTRATIONS_ID_CHANGE:
      return {
        ...state,
        administrationsId: action.payload,
      };

    case AP_CHANGE:
      return {
        ...state,
        ap: action.payload,
      };

    case AP_NT_CHANGE:
      return {
        ...state,
        apNt: action.payload,
      };

    case BIO5_CHANGE:
      return {
        ...state,
        bio5: action.payload,
      };

    case BIO10_CHANGE:
      return {
        ...state,
        bio10: action.payload,
      };

    case INDIVIDUAL_ENERGY_PRODUCTS:
      return {
        ...state,
        editProductState: true,
        isEnergyIndividual: true,
        isGasIndividual: false,
      };

    case INDIVIDUAL_GAS_PRODUCTS:
      return {
        ...state,
        editProductState: true,
        isGasIndividual: true,
        isEnergyIndividual: false,
      };

    case INDIVIDUAL_NO_PRODUCTS:
      return {
        ...state,
        editProductState: false,
        isEnergyIndividual: false,
        isGasIndividual: false,
      };

    case CLIMATE_PLUS_CHANGE:
      return {
        ...state,
        climatePlus: action.payload,
      };

	case CLOSE_DELETE_INDIVIDUAL_PRODUCT_CONFIRM:
		return {
			...state,
			deleteIndividualProductConfirmName:		"",
			deleteIndividualProductConfirmState:	false,
			activeIndividualProductId:				0, 
		}

	case CLOSE_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT:
		return {
			...state,
			energyIndividualProductAssignmentDialogError: 	"",
			energyIndividualProductAssignmentDialogState:	false,
			energyIndividualProductAssignmentDialogTitle:	"",
		}

	case CLOSE_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_DELETE_DIALOG:
		return {
			...state,
			energyIndividualProductAssignmentTimeframeDeleteDialogState: false,
			energyIndividualProductAssignmentTimeframeDeleteDialogName: "",
			activeEnergyIndividualProductAssignmentTimeframeId: 0,
		}

	case CLOSE_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_DIALOG:
		return {
			...state,
			energyIndividualProductAssignmentTimeframeDialogError:	"",
			energyIndividualProductAssignmentTimeframeDialogState:	false,
			energyIndividualProductAssignmentTimeframeDialogTitle:	"",
		}

	case CLOSE_INDIVIDUAL_PRODUCT_ASSIGNMENT_CONFIRM:
		return {
			...state,
			deleteIndividualProductAssignmentConfirmName:	"",
			deleteIndividualProductAssignmentConfirmState:	false,
			activeIndividualProductAssignmentId:				0,
		}

    case CLOSE_INDIVIDUAL_PRODUCT_DETAIL:
      return {
        ...state,
        individualProductDetailDialogError: "",
        individualProductDetailDialogState: false,
        individualProductDetailDialogTitle: "",
        individualProductName: "",
        individualProductDivisionsId: -1,
        activeIndividualProductId: 0,
        customerKindsId: -1,
        customerKindIdsId: -1,
      };

    case CLOSE_INDIVIDUAL_PRODUCT_CONFIRM:
      return {
        ...state,
        deleteIndividualProductConfirmName: "",
        activeIndividualProductId: 0,
        deleteIndividualProductConfirmState: false,
      };

	case CLOSE_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT:
		return {
			...state,
			gasIndividualProductAssignmentDialogError: 	"",
			gasIndividualProductAssignmentDialogState: 	false,
			gasIndividualProductAssignmentDialogTitle: 	"",

			gasIndividualProductAssignmentLoadProfilesId:	-1,
			gasIndividualProductAssignmentProductsId:		-1,
			gasIndividualProductAssignmentProductTypesId:	-1,
			gasIndividualProductAssignmentProductValidFrom:	"",
			gasIndividualProductAssignmentTarifOld:			"",
		}

	case CLOSE_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_DELETE_DIALOG:
		return {
			...state,
			gasIndividualProductAssignmentTimeframeDeleteDialogState: false,
			gasIndividualProductAssignmentTimeframeDeleteDialogName: "",
			activeGasIndividualProductAssignmentTimeframeId: 0,
		}

	case CLOSE_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_DIALOG:
		return {
			...state,
			gasIndividualProductAssignmentTimeframeDialogError:	"",
			gasIndividualProductAssignmentTimeframeDialogState:	false,
			gasIndividualProductAssignmentTimeframeDialogTitle:	"",
		}

    case CLOSE_GAS_PRODUCT_CONFIRM:
      return {
        ...state,
        deleteGasProductConfirmName: "",
        activeProduct: 0,
        deleteGasProductConfirmState: false,
      };

    case CLOSE_GAS_PRODUCT_DETAIL:
      return {
        ...state,
        editGasProductDetailError: "",
        editGasProductDetailState: false,
        editGasProductDetailTitle: "",
        administrationsId: -1,
        ap: "",
        apNt: "",
        climatePlus: "",
        bio5: "",
        bio10: "",
        co2: "",
        gasProductsId: -1,
        gasProductTypesId: -1,
        gp: "",
        productValidFrom: "",
        tarifOld: "",
        validFrom: "",
        validTo: "",
      };

    case CLOSE_PRODUCT_CONFIRM:
      return {
        ...state,
        deleteProductConfirmName: "",
        activeProduct: 0,
        deleteProductConfirmState: false,
      };

    case CLOSE_PRODUCT_DETAIL:
      return {
        ...state,
        editProductDetailError: "",
        editProductDetailState: false,
        editProductDetailTitle: "",
        administrationsId: -1,
        ap: "",
        apNt: "",
        climatePlus: "",
        loadProfilesId: -1,
        energyProductsId: -1,
        productTypesId: -1,
        gp: "",
        productValidFrom: "",
        switchingTimesId: -1,
        tarifOld: "",
        validFrom: "",
        validTo: "",
      };

    case CO2_CHANGE:
      return {
        ...state,
        co2: action.payload,
      };

    case DELETE_INDIVIDUAL_PRODUCT_CONFIRM:
      return {
        ...state,
        deleteIndividualProductConfirmName: action.payload.deleteIndividualProductConfirmName,
        activeIndividualProductId: action.payload.id,
        deleteIndividualProductConfirmState: true,
      };

	case DELETE_ENERGY_ASSIGNMENT_TIMEFRAME_CONFIRM:
		return {
			...state,
			energyIndividualProductAssignmentTimeframeDeleteDialogState: 	true,
			energyIndividualProductAssignmentTimeframeDeleteDialogName:	 	action.payload,
			activeEnergyIndividualProductAssignmentTimeframeId: 				action.payload,
		}

    case DELETE_GAS_PRODUCT_CONFIRM:
      return {
        ...state,
        deleteGasProductConfirmName: action.payload.deleteGasProductConfirmName,
        activeProduct: action.payload.id,
        deleteGasProductConfirmState: true,
      };

	case DELETE_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_CONFIRM:
		return {
			...state,
			gasIndividualProductAssignmentTimeframeDeleteDialogState: true,
			gasIndividualProductAssignmentTimeframeDeleteDialogName:	 action.payload,
			activeGasIndividualProductAssignmentTimeframeId: action.payload,
		}

    case DELETE_PRODUCT_CONFIRM:
      return {
        ...state,
        deleteProductConfirmName: action.payload.deleteProductConfirmName,
        activeProduct: action.payload.id,
        deleteProductConfirmState: true,
      };

    case INDIVIDUAL_PRODUCT_DIVISIONS_ID_CHANGE:
      return {
        ...state,
        individualProductDivisionsId: action.payload,
      };

    case EDIT_INDIVIDUAL_PRODUCT:
      return {
        ...state,
        individualProductDetailDialogError: "",
        individualProductDetailDialogState: true,
        individualProductDetailDialogTitle: action.payload.title,
        activeIndividualProductId: action.payload.id,
        individualProductName: action.payload.individualProductName,
        individualProductDivisionsId: action.payload.individualProductDivisionsId,
        customerKindsId: action.payload.customerKindsId,
        customerKindIds: action.payload.customerKindIds,
        customerKindIdsId: action.payload.customerKindIdsId,
      };

	case EDIT_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT:
		return{
			...state,
			activeIndividualProductAssignmentId:				action.payload.id,
			energyIndividualProductAssignmentDialogError:	"",
			energyIndividualProductAssignmentDialogState:	true,
			energyIndividualProductAssignmentDialogTitle:	action.payload.title,

			energyIndividualProductAssignmentLoadProfilesId:		action.payload.energyIndividualProductAssignmentLoadProfilesId,
			energyIndividualProductAssignmentSwitchingTimesId:	action.payload.energyIndividualProductAssignmentSwitchingTimesId,
			energyIndividualProductAssignmentProductsId:			action.payload.energyIndividualProductAssignmentProductsId,
			energyIndividualProductAssignmentProductTypesId:		action.payload.energyIndividualProductAssignmentProductTypesId,
			energyIndividualProductAssignmentProductValidFrom:	action.payload.energyIndividualProductAssignmentProductValidFrom,
			energyIndividualProductAssignmentTarifOld:			action.payload.energyIndividualProductAssignmentTarifOld,
		};

	case EDIT_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME:
		return{
			...state,
			energyIndividualProductAssignmentTimeframeDialogError:	"",
			energyIndividualProductAssignmentTimeframeDialogState:	true,
			energyIndividualProductAssignmentTimeframeDialogTitle:	action.payload.energyIndividualProductAssignmentTimeframeDialogTitle,
			activeEnergyIndividualProductAssignmentTimeframeId:		action.payload.activeEnergyIndividualProductAssignmentTimeframeId,
			energyIndividualProductAssignmentTimeframeClimatePlus:	action.payload.energyIndividualProductAssignmentTimeframeClimatePlus,
			energyIndividualProductAssignmentTimeframeValidFrom:		action.payload.energyIndividualProductAssignmentTimeframeValidFrom,
			energyIndividualProductAssignmentTimeframeValidTo:		action.payload.energyIndividualProductAssignmentTimeframeValidTo,
			energyIndividualProductAssignmentTimeframeIsNewYearsOnly: 	action.payload.energyIndividualProductAssignmentTimeframeIsNewYearsOnly,
			energyIndividualProductAssignmentTimeframeGp:			action.payload.energyIndividualProductAssignmentTimeframeGp,
			energyIndividualProductAssignmentTimeframeAp:			action.payload.energyIndividualProductAssignmentTimeframeAp,
			energyIndividualProductAssignmentTimeframeApNt:			action.payload.energyIndividualProductAssignmentTimeframeApNt,
			energyIndividualProductAssignmentTimeframeIsApNt:		action.payload.energyIndividualProductAssignmentTimeframeIsApNt,
		} 

	case EDIT_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT:
		return {
        ...state,
        activeIndividualProductAssignmentId: action.payload.id,
		gasIndividualProductAssignmentDialogError: "",
		gasIndividualProductAssignmentDialogState:	true,
		gasIndividualProductAssignmentDialogTitle:	action.payload.title,

		gasIndividualProductAssignmentLoadProfilesId: action.payload.gasIndividualProductAssignmentLoadProfilesId,
		gasIndividualProductAssignmentProductsId: action.payload.gasIndividualProductAssignmentProductsId,
		gasIndividualProductAssignmentProductTypesId: action.payload.gasIndividualProductAssignmentProductTypesId,
		gasIndividualProductAssignmentProductValidFrom: action.payload.gasIndividualProductAssignmentProductValidFrom,
		gasIndividualProductAssignmentTarifOld: action.payload.gasIndividualProductAssignmentTarifOld
      };

	case EDIT_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME:
		return{
			...state,
			gasIndividualProductAssignmentTimeframeDialogError:	"",
			gasIndividualProductAssignmentTimeframeDialogState:	true,
			gasIndividualProductAssignmentTimeframeDialogTitle:	action.payload.gasIndividualProductAssignmentTimeframeDialogTitle,
			activeGasIndividualProductAssignmentTimeframeId:		action.payload.activeGasIndividualProductAssignmentTimeframeId,
			gasIndividualProductAssignmentTimeframeClimatePlus:	action.payload.gasIndividualProductAssignmentTimeframeClimatePlus,
			gasIndividualProductAssignmentTimeframeValidFrom:	action.payload.gasIndividualProductAssignmentTimeframeValidFrom,
			gasIndividualProductAssignmentTimeframeValidTo:		action.payload.gasIndividualProductAssignmentTimeframeValidTo,
			gasIndividualProductAssignmentTimeframeIsNewYearsOnly:	action.payload.gasIndividualProductAssignmentTimeframeIsNewYearsOnly,
			gasIndividualProductAssignmentTimeframeGp:			action.payload.gasIndividualProductAssignmentTimeframeGp,
			gasIndividualProductAssignmentTimeframeAp:			action.payload.gasIndividualProductAssignmentTimeframeAp,
			gasIndividualProductAssignmentTimeframeBio5:			action.payload.gasIndividualProductAssignmentTimeframeBio5,
			gasIndividualProductAssignmentTimeframeBio10:		action.payload.gasIndividualProductAssignmentTimeframeBio10,
			gasIndividualProductAssignmentTimeframeBehg:		action.payload.gasIndividualProductAssignmentTimeframeBehg,
			gasIndividualProductAssignmentTimeframeTehg:		action.payload.gasIndividualProductAssignmentTimeframeTehg,
		} 

    case EDIT_GAS_PRODUCT:
      return {
        ...state,
        activeProduct: action.payload.id,
        editGasProductDetailError: "",
        editGasProductDetailState: true,
        editGasProductDetailTitle: action.payload.title,

        administrationsId: action.payload.administrationsId,
        ap: action.payload.ap,
        apNt: action.payload.apNt,
        climatePlus: action.payload.climatePlus,
        bio5: action.payload.bio5,
        bio10: action.payload.bio10,
        co2: action.payload.co2,
        // customerKindsId: action.payload.customerKindsId,
        // customerKindIdsId: action.payload.customerKindIdsId,
        gasProductsId: action.payload.gasProductsId,
        gasProductTypesId: action.payload.gasProductTypesId,
        loadProfilesId: action.payload.loadProfilesId,
        gp: action.payload.gp,
        productValidFrom: action.payload.productValidFrom,
        tarifOld: action.payload.tarifOld,
        validFrom: action.payload.validFrom,
        validTo: action.payload.validTo,

        // customerKindIds: action.payload.customerKindIds,
      };

    case EDIT_PRODUCT:
      return {
        ...state,
        activeProduct: action.payload.id,
        editProductDetailError: "",
        editProductDetailState: true,
        editProductDetailTitle: action.payload.title,

        // customerKindsId: action.payload.customerKindsId,
        // customerKindIdsId: action.payload.customerKindIdsId,
        administrationsId: action.payload.administrationsId,
        ap: action.payload.ap,
        apNt: action.payload.apNt,
        climatePlus: action.payload.climatePlus,
        energyProductsId: action.payload.productsId,
        productTypesId: action.payload.productTypesId,
        gp: action.payload.gp,
        productValidFrom: action.payload.productValidFrom,
        tarifOld: action.payload.tarifOld,
        validFrom: action.payload.validFrom,
        validTo: action.payload.validTo,
        loadProfilesId: action.payload.loadProfilesId,
        switchingTimesId: action.payload.switchingTimesId,

        // customerKindIds: action.payload.customerKindIds,
      };

    case CUSTOMER_KIND_IDS_ID_CHANGE:
      return {
        ...state,
        customerKindIdsId: action.payload,
      };

    case CUSTOMER_KINDS_ID_CHANGE:
      return {
        ...state,
        customerKindsId: action.payload.customerKindsId,
        customerKindIds: action.payload.customerKindIds,
        customerKindIdsId: -1,
      };

	case DELETE_INDIVIDUAL_PRODUCT_ASSIGNMENT_CONFIRM:
		return{
			...state,
			deleteIndividualProductAssignmentConfirmName:	action.payload,
			activeIndividualProductAssignmentId:			action.payload,
			deleteIndividualProductAssignmentConfirmState:	true,
		}

	case ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_LOAD_PROFILES_ID_CHANGE:
		return {
			...state,
			energyIndividualProductAssignmentLoadProfilesId: action.payload,
		}

	case ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODCUTS_ID_CHANGE:
		return {
			...state,
			energyIndividualProductAssignmentProductsId: action.payload,
		}

	case ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODUCT_TYPES_ID_CHANGE:
		return {
			...state,
			energyIndividualProductAssignmentProductTypesId: action.payload,
		}

	case ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODUCT_VALID_FROM_CHANGE:
		return {
			...state,
			energyIndividualProductAssignmentProductValidFrom: action.payload,
		}

	case ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_SWITCHING_TIMES_ID_CHANGE:
		return {
			...state,
			energyIndividualProductAssignmentSwitchingTimesId: action.payload,
		}

	case ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TARIF_OLD_CHANGE:
		return {
			...state,
			energyIndividualProductAssignmentTarifOld: action.payload,
		}

	case ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_AP_CHANGE:
		return {
			...state,
			energyIndividualProductAssignmentTimeframeAp: action.payload,
		}

	case ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_AP_NT_CHANGE:
		return {
			...state,
			energyIndividualProductAssignmentTimeframeApNt: action.payload,
		}

	case ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_CLIMATE_PLUS_CHANGE:
		return {
			...state,
			energyIndividualProductAssignmentTimeframeClimatePlus: action.payload,
		}

	case ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_GP_CHANGE:
		return {
			...state,
			energyIndividualProductAssignmentTimeframeGp: action.payload,
		}

	case ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_VALID_FROM_CHANGE:
		return {
			...state,
			energyIndividualProductAssignmentTimeframeValidFrom: action.payload,
		}

	case ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_VALID_TO_CHANGE:
		return {
			...state,
			energyIndividualProductAssignmentTimeframeValidTo: action.payload,
		}

	case GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_LOAD_PROFILES_ID_CHANGE:
		return {
			...state,
			gasIndividualProductAssignmentLoadProfilesId: action.payload,
		}

	case GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODUCTS_ID_CHANGE:
		return {
			...state,
			gasIndividualProductAssignmentProductsId: action.payload,
		}

	case GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TARIF_OLD_CHANGE:
		return {
			...state,
			gasIndividualProductAssignmentTarifOld: action.payload,
		}

	case GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODUCT_TYPES_ID_CHANGE:
		return {
			...state,
			gasIndividualProductAssignmentProductTypesId: action.payload,
		}

	case GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODUCT_VALID_FROM_CHANGE:
		return {
			...state,
			gasIndividualProductAssignmentProductValidFrom: action.payload,
		}

	case GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_AP_CHANGE:
		return {
			...state,
			gasIndividualProductAssignmentTimeframeAp: action.payload,
		}

	case GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_BIO5_CHANGE:
		return {
			...state,
			gasIndividualProductAssignmentTimeframeBio5: action.payload,
		}

	case GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_BIO10_CHANGE:
		return {
			...state,
			gasIndividualProductAssignmentTimeframeBio10: action.payload,
		}

	case GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_BEHG_CHANGE:
		return {
			...state,
			gasIndividualProductAssignmentTimeframeBehg: action.payload,
		}

	case GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_CLIMATE_PLUS_CHANGE:
		return {
			...state,
			gasIndividualProductAssignmentTimeframeClimatePlus: action.payload,
		}

	case GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_GP_CHANGE:
		return {
			...state,
			gasIndividualProductAssignmentTimeframeGp: action.payload,
		}

	case GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_TEHG_CHANGE:
		return {
			...state,
			gasIndividualProductAssignmentTimeframeTehg: action.payload,
		}

	case GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_VALID_FROM_CHANGE:
		return {
			...state,
			gasIndividualProductAssignmentTimeframeValidFrom: action.payload,
		}

	case GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_VALID_TO_CHANGE:
		return{
			...state,
			gasIndividualProductAssignmentTimeframeValidTo: action.payload,
		} 

    case GAS_PRODUCT_TYPES_ID_CHANGE:
      return {
        ...state,
        gasProductTypesId: action.payload,
      };

    case GAS_PRODUCTS_ID_CHANGE:
      return {
        ...state,
        gasProductsId: action.payload,
      };

	case GET_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAMES:
		return {
			...state,
			energyIndividualProductAssignmentTimeframes: action.payload.energyIndividualProductAssignmentTimeframes,
		}

	case GET_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAMES:
		return {
			...state,
			gasIndividualProductAssignmentTimeframes: action.payload.gasIndividualProductAssignmentTimeframes,
		}

    case GET_INDIVIDUAL_PRODUCT_ASSIGNMENTS:
      return {
        ...state,
        energyIndividualProductAssignments: action.payload.energyIndividualProductAssignments,
        gasIndividualProductAssignments: action.payload.gasIndividualProductAssignments,
      };

    case GET_INDIVIDUAL_PRODUCTS:
      return {
        ...state,
        individualProducts: action.payload,
      };

    case GP_CHANGE:
      return {
        ...state,
        gp: action.payload,
      };

    case LOAD_PROFILES_ID_CHANGE:
      return {
        ...state,
        loadProfilesId: action.payload,
        switchingTimesId: -1,
        apNt: "",
      };

    case PRODUCTS_ID_CHANGE:
      return {
        ...state,
        energyProductsId: action.payload,
      };

    case PRODUCT_TYPES_ID_CHANGE:
      return {
        ...state,
        productTypesId: action.payload,
      };

    case PRODUCT_VALID_FROM_CHANGE:
      return {
        ...state,
        productValidFrom: action.payload,
      };

    case SET_INDIVIDUAL__PRODUCT_ERROR:
      return {
        ...state,
        individualProductDetailDialogError: action.payload,
      };

    case SET_DIVISION_ASSIGNMENT:
      return {
        ...state,
        isEnergyIndividual: action.payload.isEnergyIndividual,
        isGasIndividual: action.payload.isGasIndividual,
      };

    case SET_ENERGY_ERROR:
      return {
        ...state,
        editProductDetailError: action.payload,
      };

    case SET_ERROR:
      return {
        ...state,
        editGasProductDetailError: action.payload,
      };

	case SET_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_ERROR:
		return {
			...state,
			energyIndividualProductAssignmentDialogError: action.payload,
		}

	case SET_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_ERROR:
		return {
			...state,
			energyIndividualProductAssignmentTimeframeDialogError: action.payload,
		}

	case SET_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_ERROR:
		return {
			...state,
			gasIndividualProductAssignmentDialogError: action.payload,
		}

	case SET_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_ERROR:
		return {
			...state,
			gasIndividualProductAssignmentTimeframeDialogError: action.payload,
		}

    case SET_INDIVIDUAL_PRODUCT_FOREIGNS:
		return {
			...state,
			customerKinds: 		action.payload.customerKinds,

			gasIndividualProductAssignmentProductTypes:	action.payload.gasProductTypes,
			gasIndividualProductAssignmentProducts:		action.payload.gasProducts,
			gasIndividualProductAssignmentLoadProfiles:	action.payload.loadProfiles,

			energyIndividualProductAssignmentProductTypes:		action.payload.energyProductTypes,
			energyIndividualProductAssignmentProducts:			action.payload.energyProducts,
			energyIndividualProductAssignmentLoadProfiles:		action.payload.loadProfiles,
			energyIndividualProductAssignmentSwitchingTimes:	action.payload.switchingTimes,
		};

    case SWITCHING_TIMES_ID_CHANGE:
      return {
        ...state,
        switchingTimesId: action.payload.switchingTimesId,
        apNt: action.payload.apNt,
      };

    case TARIF_OLD_CHANGE:
      return {
        ...state,
        tarifOld: action.payload,
      };

    case INDIVIDUAL_PRODUCT_NAME_CHANGE:
      return {
        ...state,
        individualProductName: action.payload,
      };

    case VALID_FROM_CHANGE:
      return {
        ...state,
        validFrom: action.payload,
      };

    case VALID_TO_CHANGE:
      return {
        ...state,
        validTo: action.payload,
      };

    //  Default Path
    default:
      return state;
  }
};
