/**
 * 		File:		./src/context/campaigns/IndividualsState.js
 *
 * 		Subject:	Individual product's state
 */

import React, { useReducer } from "react";
import axios from "axios";
import IndividualsContext from "./individualsContext";
import individualsReducer from "./individualsReducer";

import {
  ADMINISTRATIONS_ID_CHANGE,
  AP_CHANGE,
  AP_NT_CHANGE,
  BIO5_CHANGE,
  BIO10_CHANGE,
  CLIMATE_PLUS_CHANGE,
  CLOSE_DELETE_INDIVIDUAL_PRODUCT_CONFIRM,
  CLOSE_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT,
  CLOSE_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_DELETE_DIALOG,
  CLOSE_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_DIALOG,
  CLOSE_INDIVIDUAL_PRODUCT_ASSIGNMENT_CONFIRM,
  CLOSE_INDIVIDUAL_PRODUCT_DETAIL,
  CLOSE_INDIVIDUAL_PRODUCT_CONFIRM,
  CLOSE_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT,
  CLOSE_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_DELETE_DIALOG,
  CLOSE_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_DIALOG,
  CLOSE_GAS_PRODUCT_CONFIRM,
  CLOSE_GAS_PRODUCT_DETAIL,
  CLOSE_PRODUCT_CONFIRM,
  CLOSE_PRODUCT_DETAIL,
  CO2_CHANGE,
  DELETE_INDIVIDUAL_PRODUCT_CONFIRM,
  DELETE_INDIVIDUAL_PRODUCT_ASSIGNMENT_CONFIRM,
  DELETE_ENERGY_ASSIGNMENT_TIMEFRAME_CONFIRM,
  DELETE_GAS_PRODUCT_CONFIRM,
  DELETE_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_CONFIRM,
  DELETE_PRODUCT_CONFIRM,
  INDIVIDUAL_PRODUCT_DIVISIONS_ID_CHANGE,
  EDIT_INDIVIDUAL_PRODUCT,
  EDIT_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT,
  EDIT_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME,
  EDIT_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT,
  EDIT_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME,
  EDIT_GAS_PRODUCT,
  ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_LOAD_PROFILES_ID_CHANGE,
  ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODCUTS_ID_CHANGE,
  ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODUCT_TYPES_ID_CHANGE,
  ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODUCT_VALID_FROM_CHANGE,
  ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_SWITCHING_TIMES_ID_CHANGE,
  ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TARIF_OLD_CHANGE,
  ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_AP_CHANGE,
  ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_AP_NT_CHANGE,
  ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_CLIMATE_PLUS_CHANGE,
  ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_GP_CHANGE,
  ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_VALID_FROM_CHANGE,
  ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_VALID_TO_CHANGE,
  CUSTOMER_KIND_IDS_ID_CHANGE,
  CUSTOMER_KINDS_ID_CHANGE,
  GAS_PRODUCT_TYPES_ID_CHANGE,
  GAS_PRODUCTS_ID_CHANGE,
  GET_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAMES,
  GET_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAMES,
  GET_INDIVIDUAL_PRODUCT_ASSIGNMENTS,
  GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_LOAD_PROFILES_ID_CHANGE,
  GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODUCTS_ID_CHANGE,
  GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TARIF_OLD_CHANGE,
  GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODUCT_TYPES_ID_CHANGE,
  GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODUCT_VALID_FROM_CHANGE,
  GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_AP_CHANGE,
  GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_BEHG_CHANGE,
  GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_BIO5_CHANGE,
  GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_BIO10_CHANGE,
  GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_CLIMATE_PLUS_CHANGE,
  GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_GP_CHANGE,
  GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_TEHG_CHANGE,
  GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_VALID_FROM_CHANGE,
  GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_VALID_TO_CHANGE,
  GET_INDIVIDUAL_PRODUCTS,
  GP_CHANGE,
  LOAD_PROFILES_ID_CHANGE,
  PRODUCTS_ID_CHANGE,
  PRODUCT_TYPES_ID_CHANGE,
  PRODUCT_VALID_FROM_CHANGE,
  SWITCHING_TIMES_ID_CHANGE,
  TARIF_OLD_CHANGE,
  INDIVIDUAL_PRODUCT_NAME_CHANGE,
  SET_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_ERROR,
  SET_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_ERROR,
  SET_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_ERROR,
  SET_INDIVIDUAL__PRODUCT_ERROR,
  SET_ENERGY_ERROR,
  SET_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_ERROR,
  SET_ERROR,
  SET_INDIVIDUAL_PRODUCT_FOREIGNS,
  VALID_FROM_CHANGE,
  VALID_TO_CHANGE,
} from "../types";

const IndividualsState = (props) => {
  const initialState = {
    activeIndividualProductId: 0,
	activeIndividualProductAssignmentId: 0,
    activeProduct: -1,
    ap: "",
    apNt: "",
    bio5: "",
    bio10: "",
    individualProductDetailDialogError: "",
    individualProductDetailDialogState: false,
    individualProductDetailDialogTitle: "",
    individualProducts: [],
    individualProductName: "",
    climatePlus: "",
    co2: "",
    customerKindIds: [{id: -1, value: "Bitte erst Kundenart wählen"}],
    customerKindIdsId: -1,
    customerKinds: [],
    customerKindsId: -1,
	deleteIndividualProductAssignmentConfirmName:	"",
	deleteIndividualProductAssignmentConfirmState:	false,
    deleteIndividualProductConfirmName: "",
    deleteIndividualProductConfirmState: false,
    deleteGasProductConfirmName: "",
    deleteGasProductConfirmState: false,
    deleteProductConfirmName: "",
    deleteProductConfirmState: false,
    individualProductDivisions: [
      {id: -1, value: "Bitte wählen..."},
      {id: 1, value: "Erdgas"},
      {id: 2, value: "Strom"}
    ],
    individualProductDivisionsId: -1,
    editGasProductDetailError: "",
    editGasProductDetailState: false,
    editGasProductDetailTitle: "",
    editProductDetailState: false,
    editProductDetailError: "",
    editProductState: false,
    editProductDetailTitle: "",
    energyIndividualProductAssignments: [],
	energyIndividualProductAssignmentDialogError:	"",
	energyIndividualProductAssignmentDialogState:	false,
	energyIndividualProductAssignmentDialogTitle:	"",
    energyIndividualProductAssignmentProducts: [],
    energyIndividualProductAssignmentProductsId: -1,
	energyIndividualProductAssignmentTimeframes:	[],
	energyIndividualProductAssignmentTimeframeAp:	"",
	energyIndividualProductAssignmentTimeframeApNt:	"",
	energyIndividualProductAssignmentTimeframeClimatePlus:	"",
	energyIndividualProductAssignmentTimeframeDeleteDialogName:	"",
	energyIndividualProductAssignmentTimeframeDeleteDialogState:	false,
	energyIndividualProductAssignmentTimeframeDialogError:	"",
	energyIndividualProductAssignmentTimeframeDialogState: false,
	energyIndividualProductAssignmentTimeframeDialogTitle:	"",
	energyIndividualProductAssignmentTimeframeGp:			"",
	energyIndividualProductAssignmentTimeframeIsApNt:		"",
	energyIndividualProductAssignmentTimeframeIsNewYearsOnly: 1,
	energyIndividualProductAssignmentTimeframeValidFrom:	"",
	energyIndividualProductAssignmentTimeframeValidTo:		"",
    gasIndividualProductAssignments: [],
	gasIndividualProductAssignmentDialogError:	"",
	gasIndividualProductAssignmentDialogState: false,
	gasIndividualProductAssignmentDialogTitle: "",
    gasIndividualProductAssignmentProducts: [],
    gasIndividualProductAssignmentProductsId: -1,
	gasIndividualProductAssignmentProductValidFrom:	"",
	gasIndividualProductAssignmentTarifOld:	"",
	gasIndividualProductAssignmentTimeframes: [],
	gasIndividualProductAssignmentTimeframeAp:	"",
	gasIndividualProductAssignmentTimeframeBehg:	"",
	gasIndividualProductAssignmentTimeframeBio5:	"",
	gasIndividualProductAssignmentTimeframeBio10:	"",
	gasIndividualProductAssignmentTimeframeClimatePlus:	"",
	gasIndividualProductAssignmentTimeframeDeleteDialogName:	"",
	gasIndividualProductAssignmentTimeframeDeleteDialogState:	false,
	gasIndividualProductAssignmentTimeframeDialogError:	"",
	gasIndividualProductAssignmentTimeframeDialogState: false,
	gasIndividualProductAssignmentTimeframeDialogTitle:	"",
	gasIndividualProductAssignmentTimeframeGp:	"",
	gasIndividualProductAssignmentTimeframeIsNewYearsOnly: "",
	gasIndividualProductAssignmentTimeframeTehg: "",
	gasIndividualProductAssignmentTimeframeValidFrom:	"",
	gasIndividualProductAssignmentTimeframeValidTo:		"",
    gasProductTypes: [],
    gasProductTypesId: -1,
    gp: "",
    isEnergyIndividual: false,
    isGasIndividual: false,
	gasIndividualProductAssignmentLoadProfiles:	[],
	gasIndividualProductAssignmentLoadProfilesId:	-1,
	energyIndividualProductAssignmentLoadProfiles:	[],
	energyIndividualProductAssignmentLoadProfilesId:	-1,
	gasIndividualProductAssignmentProductTypes:	[],
	gasIndividualProductAssignmentProductTypesId:	-1,
    energyIndividualProductAssignmentProductTypes: [],
    energyIndividualProductAssignmentProductTypesId: -1,
    productValidFrom: "",
    energyIndividualProductAssignmentSwitchingTimes:	[],
	energyIndividualProductAssignmentSwitchingTimesId:	-1,
    energyIndividualProductAssignmentTarifOld: "",
    validFrom: "",
    validTo: "",
  };
  const [state, dispatch] = useReducer(individualsReducer, initialState);

  //	CHANGE ADMINISTRATION ID
  const administrationsIdChange = (e) => {
    //	Reset error message
    if (
      state.editGasProductDetailError !== "" &&
      state.administrationsId === -1
    ) {
      setError("");
    }
    if (state.editProductDetailError !== "" && state.administrationsId === -1) {
      setEnergyError("");
    }

    dispatch({
      type: ADMINISTRATIONS_ID_CHANGE,
      payload: e.target.value,
    });
  };

  //	CHANGE AP
  const apChange = (e) => {
    //	Reset error message
    if (
      state.editGasProductDetailError !== "" &&
      (state.ap === "" || state.ap * 1 === 0)
    ) {
      setError("");
    }
    if (
      state.editProductDetailError !== "" &&
      (state.ap === "" || state.ap * 1 === 0)
    ) {
      setEnergyError("");
    }

    dispatch({
      type: AP_CHANGE,
      payload: e.target.value,
    });
  };

  //	CHANGE AP NT
  const apNtChange = (e) => {
    //	Reset error message
    if (
      state.editProductDetailError !== "" &&
      (state.apNt === "" || state.apNt * 1 === 0)
    ) {
      setEnergyError("");
    }

    dispatch({
      type: AP_NT_CHANGE,
      payload: e.target.value,
    });
  };

  //	CHANGE BIO5 OPTION
  const bio5Change = (e) => {
    dispatch({
      type: BIO5_CHANGE,
      payload: e.target.value,
    });
  };

  //	CHANGE BIO10 OPTION
  const bio10Change = (e) => {
    dispatch({
      type: BIO10_CHANGE,
      payload: e.target.value,
    });
  };

  //	CHANGE DIVISION
  const changeDivision = (divisionsIdOld, divisionsIdNew) => {
    if (divisionsIdOld === -1) return;

    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("divisionsIdOld", divisionsIdOld);
    params.append("divisionsIdNew", divisionsIdNew);
    params.append("individualId", state.activeIndividualId);

    let url =
      process.env.REACT_APP_API_URI +
      "/campaigns/change_individual_product_division.php";
    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);
          getIndividualProductAssignments();
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  //	CHANGE CLIMATE PLUS OPTION
  const climatePlusChange = (e) => {
    dispatch({
      type: CLIMATE_PLUS_CHANGE,
      payload: e.target.value,
    });
  };

const closeDeleteIndividualProductConfirm = () => {

	dispatch({
		type: CLOSE_DELETE_INDIVIDUAL_PRODUCT_CONFIRM,
	});
}

const closeEnergyIndividualProductAssignmentDialog = () => {
	dispatch({
		type: CLOSE_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT,
	});
};

const closeEnergyIndividualProductAssignmentTimeframeDeleteDialog = () => {
	dispatch({
		type: CLOSE_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_DELETE_DIALOG
	});
}

const closeEnergyIndividualProductAssignmentTimeframeDialog = () => {

	dispatch({
		type: CLOSE_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_DIALOG
	});
}

const closeGasIndividualProductAssignmentDialog = () => {

	dispatch({
		type: CLOSE_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT,
	});
};

const closeGasIndividualProductAssignmentTimeframeDeleteDialog = () => {
	dispatch({
		type: CLOSE_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_DELETE_DIALOG
	});
}

const closeGasIndividualProductAssignmentTimeframeDialog = () => {

	dispatch({
		type: CLOSE_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_DIALOG
	});
}

const closeIndividualProductAssignmentConfirm = () => {
	dispatch({
		type: CLOSE_INDIVIDUAL_PRODUCT_ASSIGNMENT_CONFIRM
	});
}

  //	CLOSE DELETE CONFIRM DIALOG
  const closeIndividualProductConfirm = () => {
    dispatch({
      type: CLOSE_INDIVIDUAL_PRODUCT_CONFIRM,
    });
  };

  //	CLOSE INDIVIDUAL PRODUCT DETAIL DIALOG
  const closeIndividualProductDetailDialog = () => {

    dispatch({
		type: CLOSE_INDIVIDUAL_PRODUCT_DETAIL
	});
  };

  //	CLOSE GAS PRODUCT DELETE CONFIRM DIALOG
  const closeGasProductConfirm = () => {
    dispatch({
      type: CLOSE_GAS_PRODUCT_CONFIRM,
    });
  };

  //	CLOSE GAS PRODUCT DETAIL DIALOG
  const closeGasProductDetail = () => {
    dispatch({
      type: CLOSE_GAS_PRODUCT_DETAIL,
    });
  };

  const closeProductConfirm = () => {
    dispatch({
      type: CLOSE_PRODUCT_CONFIRM,
    });
  };

  const closeProductDetail = () => {
    dispatch({
      type: CLOSE_PRODUCT_DETAIL,
    });
  };

  const co2Change = (e) => {
    dispatch({
      type: CO2_CHANGE,
      payload: e.target.value,
    });
  };

const deleteEnergyIndividualProductAssignmentTimeframe = () => {

	let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
	let url = process.env.REACT_APP_API_URI + "/campaigns/delete_energy_individual_product_assignment_timeframe.php";

	const params = new URLSearchParams();
	params.append("jwt", sessionStorage.jwt);
	params.append("id", state.activeEnergyIndividualProductAssignmentTimeframeId);

	axios
		.post(url, params, axiosConfig)
		.then((response) => {
		if (response.status === 200) {

			// console.log(response.data);
			closeEnergyIndividualProductAssignmentTimeframeDeleteDialog();
			getEnergyIndividualProductTimeframes();

		} else if (response.status === 401) {
			window.location.href = "#/logout";
		} else {
			console.log("Anderer Fehler");
		}
		})
		.catch(function (error) {
		if (error.response) {
			// The server answered with an error
			if (error.response.status === 401) {
			window.location.href = "#/logout";
			} else {
			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);
			}
		} else if (error.request) {
			//  The request was made but the server didn't respond
			console.log(error.request);
		} else {
			//  Something else went wrong
			console.log("Error", error.message);
		}
	});
};

const deleteEnergyIndividualProductAssignmentTimeframeConfirm = (id) => {

	dispatch({
		type: DELETE_ENERGY_ASSIGNMENT_TIMEFRAME_CONFIRM,
		payload: id,
	});
}

  const deleteGasIndividualProductAssignmentTimeframe = () => {

	let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
	let url = process.env.REACT_APP_API_URI + "/campaigns/delete_gas_individual_product_assignment_timeframe.php";

	const params = new URLSearchParams();
	params.append("jwt", sessionStorage.jwt);
	params.append("id", state.activeGasIndividualProductAssignmentTimeframeId);

	axios
		.post(url, params, axiosConfig)
		.then((response) => {
		if (response.status === 200) {

			// console.log(response.data);
			closeGasIndividualProductAssignmentTimeframeDeleteDialog();
			getGasIndividualProductTimeframes();

		} else if (response.status === 401) {
			window.location.href = "#/logout";
		} else {
			console.log("Anderer Fehler");
		}
		})
		.catch(function (error) {
		if (error.response) {
			// The server answered with an error
			if (error.response.status === 401) {
			window.location.href = "#/logout";
			} else {
			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);
			}
		} else if (error.request) {
			//  The request was made but the server didn't respond
			console.log(error.request);
		} else {
			//  Something else went wrong
			console.log("Error", error.message);
		}
	});
};

  //	DELETE AN INDIVIDUAL PRODUCT
  const deleteIndividual = () => {
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", state.activeIndividualId);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url =
      process.env.REACT_APP_API_URI + "/campaigns/delete_individual.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);

          closeIndividualProductConfirm();
          getIndividualProducts();
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

const deleteIndividualProductAssignment = () => {
	
	let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
	let url = process.env.REACT_APP_API_URI + "/campaigns/delete_individual_product_assignment.php";

	const params = new URLSearchParams();
	params.append("jwt", sessionStorage.jwt);
	params.append("id", state.activeIndividualProductAssignmentId);
	params.append("activeIndividualProductId", state.activeIndividualProductId);

	axios
		.post(url, params, axiosConfig)
		.then((response) => {
		if (response.status === 200) {
			// console.log(response.data);

			closeIndividualProductAssignmentConfirm();
			getIndividualProductAssignments();
		} else if (response.status === 401) {
			window.location.href = "#/logout";
		} else {
			console.log("Anderer Fehler");
		}
		})
		.catch(function (error) {
		if (error.response) {
			// The server answered with an error
			if (error.response.status === 401) {
			window.location.href = "#/logout";
			} else {
			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);
			}
		} else if (error.request) {
			//  The request was made but the server didn't respond
			console.log(error.request);
		} else {
			//  Something else went wrong
			console.log("Error", error.message);
		}
	});
};

const deleteIndividualProductAssignmentConfirm = (id) => {

	dispatch({
		type: DELETE_INDIVIDUAL_PRODUCT_ASSIGNMENT_CONFIRM,
		payload: id
	});
}

  //	ASK FOR DELETE CONFIRMATION OF A PRODUCT ASSIGNMENT
const deleteIndividualProductConfirm = (id) => {

	let axiosConfig = {headers: {"Content-Type": "application/x-www-form-urlencoded"}};
	let url = process.env.REACT_APP_API_URI + "/campaigns/get_individual_product.php";
	
	const params = new URLSearchParams();
	params.append("jwt", sessionStorage.jwt);
	params.append("id", id);

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
        //   console.log(response.data);

          dispatch({
            type: DELETE_INDIVIDUAL_PRODUCT_CONFIRM,
            payload: {
              id: id,
              deleteIndividualProductConfirmName:
                response.data.individualProduct.individualProductName,
            },
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

const deleteIndividualProductAssignmentTimeframeConfirm = (id) => {

	dispatch({
		type: DELETE_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_CONFIRM,
		payload: id,
	});
}

  //	DELETE A GAS PRODUCT ASSIGNMENT
  const deleteGasProduct = () => {
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", state.activeProduct);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url =
      process.env.REACT_APP_API_URI +
      "/campaigns/delete_gas_individual_assignment.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          //   console.log(response.data);

          closeGasProductConfirm();
          getIndividualProductAssignments();
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  //	GET THE CONFIRMATION FOR DELETION OF A GAS PRODUCT ASSIGNMENT
  const deleteGasProductConfirm = (id) => {
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", id);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url =
      process.env.REACT_APP_API_URI +
      "/campaigns/get_gas_individual_assignment.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);

          dispatch({
            type: DELETE_GAS_PRODUCT_CONFIRM,
            payload: {
              id: id,
              deleteGasProductConfirmName:
                response.data.gasProductAssignment.id,
            },
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  //	DELETE AN ENERGY PRODUCT ASSIGNMENT
  const deleteProduct = () => {
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", state.activeProduct);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url =
      process.env.REACT_APP_API_URI +
      "/campaigns/delete_energy_individual_assignment.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);

          closeProductConfirm();
          getIndividualProductAssignments();
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  //	GET DELETE CONFIRMATION FOR AN ENERGY PRODUCT ASSIGNMENT
  const deleteProductConfirm = (id) => {
    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", id);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url =
      process.env.REACT_APP_API_URI +
      "/campaigns/get_energy_individual_assignment.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);

          dispatch({
            type: DELETE_PRODUCT_CONFIRM,
            payload: {
              id: id,
              deleteProductConfirmName:
                response.data.energyProductAssignment.id,
            },
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  const individualProductDivisionsIdChange = (e) => {

    if (state.individualProductDetailDialogError !== "" && state.individualProductDivisionsId === -1)
      setIndividualProductError("");

    dispatch({
      type: INDIVIDUAL_PRODUCT_DIVISIONS_ID_CHANGE,
      payload: e.target.value,
    });
  };

  //	EDIT AN INDIVIDUAL PRODUCT ASSIGNMENT
  const editIndividualProduct = async (id) => {


    let title = id > 0 ? "Individuelles Produkt bearbeiten" : "Neues individuelle Produkt anlegen";

    if (id > 0) {

		let axiosConfig = {headers: {"Content-Type": "application/x-www-form-urlencoded"}};
		let url = process.env.REACT_APP_API_URI + "/campaigns/get_individual_product.php";
		const params = new URLSearchParams();
      
		params.append("jwt", sessionStorage.jwt);
      	params.append("id", id);

      axios
        .post(url, params, axiosConfig)
        .then((response) => {
          if (response.status === 200) {
            // console.log(response.data);

            dispatch({
              type: EDIT_INDIVIDUAL_PRODUCT,
              payload: {
                id: id,
                individualProductName: response.data.individualProduct.individualProductName,
                individualProductDivisionsId: response.data.individualProduct.divisionsId,
                customerKindsId: response.data.individualProduct.customerKindsId,
                customerKindIds: response.data.individualProduct.customerKindIds,
                customerKindIdsId: response.data.individualProduct.customerKindIdsId,
                title: title,
              },
            });

            state.activeIndividualProductId = id;

            document.getElementById("individualProductDivisionsId").disabled = true;
            document.getElementById("customerKindsId").disabled = true;
            document.getElementById("customerKindIdsId").disabled = true;
            getIndividualProductAssignments();
          } else if (response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log("Anderer Fehler");
          }
        })
        .catch(function (error) {
          if (error.response) {
            // The server answered with an error
            if (error.response.status === 401) {
              window.location.href = "#/logout";
            } else {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            }
          } else if (error.request) {
            //  The request was made but the server didn't respond
            console.log(error.request);
          } else {
            //  Something else went wrong
            console.log("Error", error.message);
          }
        });
    } else {
		await dispatch({
			type: EDIT_INDIVIDUAL_PRODUCT,
			payload: {
				id: id,
				individualProductName: "",
				individualProductDivisionsId: -1,
				customerKindsId: -1,
				customerKindIds: [{id: 1, value: "Bitte erst Kundenart wählen"}],
				customerKindIdsId:	-1,
				title: title,
			},
      });

      getIndividualProductAssignments();
    }
  };

  //	SETUP OR EDIT A GAS PRODUCT ASSIGNMENT
  const editGasProduct = (id) => {
    let title = "Neue Gas-Produkt-Zuordnung";
    if (id > 0) {
      title = "Gas-Produkt-Zuordnung bearbeiten";
      const params = new URLSearchParams();
      params.append("jwt", sessionStorage.jwt);
      params.append("id", id);

      let url =
        process.env.REACT_APP_API_URI +
        "/campaigns/get_gas_individual_assignment.php";
      let axiosConfig = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      axios
        .post(url, params, axiosConfig)
        .then((response) => {
          if (response.status === 200) {
            //   console.log(response.data);

            let productValidFrom = "";
            if (response.data.gasProductAssignment.productValidFrom !== "") {
              productValidFrom = new Date(
                response.data.gasProductAssignment.productValidFrom
              );
            }

            dispatch({
              type: EDIT_GAS_PRODUCT,
              payload: {
                id: id,
                title: title,

                customerKindsId:
                  response.data.gasProductAssignment.customerKindsId,
                customerKindIdsId:
                  response.data.gasProductAssignment.customerKindIdsId,
                administrationsId:
                  response.data.gasProductAssignment.administrationsId,
                ap: response.data.gasProductAssignment.ap,
                apNt: response.data.gasProductAssignment.apNt,
                climatePlus: response.data.gasProductAssignment.climatePlus,
                bio5: response.data.gasProductAssignment.bio5,
                bio10: response.data.gasProductAssignment.bio10,
                co2: response.data.gasProductAssignment.co2,
                gasProductsId: response.data.gasProductAssignment.gasProductsId,
                gasProductTypesId:
                  response.data.gasProductAssignment.gasProductTypesId,
                gp: response.data.gasProductAssignment.gp,
                loadProfilesId:
                  response.data.gasProductAssignment.loadProfilesId,
                productValidFrom: productValidFrom,
                tarifOld: response.data.gasProductAssignment.tarifOld,
                validFrom: response.data.gasProductAssignment.validFrom,
                validTo: response.data.gasProductAssignment.validTo,

                customerKindIds: response.data.customerKindIds,
              },
            });

            if (response.data.gasProductAssignment.isClimatePlus === "1") {
              document.getElementById("climatePlus").disabled = false;
            } else {
              document.getElementById("climatePlus").disabled = true;
            }

            if (response.data.gasProductAssignment.isBio5 === "1") {
              document.getElementById("bio5").disabled = false;
            } else {
              document.getElementById("bio5").disabled = true;
            }

            if (response.data.gasProductAssignment.isBio10 === "1") {
              document.getElementById("bio10").disabled = false;
            } else {
              document.getElementById("bio10").disabled = true;
            }
          } else if (response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log("Anderer Fehler");
          }
        })
        .catch(function (error) {
          if (error.response) {
            // The server answered with an error
            if (error.response.status === 401) {
              window.location.href = "#/logout";
            } else {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            }
          } else if (error.request) {
            //  The request was made but the server didn't respond
            console.log(error.request);
          } else {
            //  Something else went wrong
            console.log("Error", error.message);
          }
        });
    } else {
      dispatch({
        type: EDIT_GAS_PRODUCT,
        payload: {
          id: id,
          title: title,
          administrationsId: -1,
          ap: "",
          apNt: "",
          climatePlus: "",
          bio5: "",
          bio10: "",
          co2: "",
          //   customerKindsId: -1,
          //   customerKindIds: [{ id: -1, value: "Bitte erst Kundenart wählen" }],
          //   customerKindIdsId: -1,
          gasProductsId: -1,
          gasProductTypesId: -1,
          gp: "",
          loadProfilesId: -1,
          productValidFrom: "",
          tarifOld: "",
          validFrom: "",
          validTo: "",
        },
      });
    }
  };

const editEnergyIndividualProductAssignment = (id) => {

	let title = "Neue Produkt-Zuordnung";

	if (id > 0) {

		title = "Produkt-Zuordnung bearbeiten";

		let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
		let url = process.env.REACT_APP_API_URI + "/campaigns/get_energy_individual_product_assignment.php";

		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("id", id);


		axios
			.post(url, params, axiosConfig)
			.then((response) => {
			if (response.status === 200) {
				// console.log(response.data);

				let energyIndividualProductAssignmentProductValidFrom = "";
				if (response.data.energyProductAssignment.energyIndividualProductAssignmentProductValidFrom !== "") {
					energyIndividualProductAssignmentProductValidFrom = new Date(
						response.data.energyProductAssignment.energyIndividualProductAssignmentProductValidFrom
					);
				}

				dispatch({
					type: EDIT_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT,
					payload: {
						id: id,
						title: title,

						energyIndividualProductAssignmentProductsId: response.data.energyProductAssignment.energyIndividualProductAssignmentProductsId,
						energyIndividualProductAssignmentProductTypesId:
						response.data.energyProductAssignment.energyIndividualProductAssignmentProductTypesId,
						energyIndividualProductAssignmentProductValidFrom: energyIndividualProductAssignmentProductValidFrom,
						energyIndividualProductAssignmentTarifOld: response.data.energyProductAssignment.energyIndividualProductAssignmentTarifOld,
						energyIndividualProductAssignmentLoadProfilesId:
						response.data.energyProductAssignment.energyIndividualProductAssignmentLoadProfilesId,
						energyIndividualProductAssignmentSwitchingTimesId:
						response.data.energyProductAssignment.energyIndividualProductAssignmentSwitchingTimesId,
					},
				});
			} else if (response.status === 401) {
				window.location.href = "#/logout";
			} else {
				console.log("Anderer Fehler");
			}
			})
			.catch(function (error) {
			if (error.response) {
				// The server answered with an error
				if (error.response.status === 401) {
				window.location.href = "#/logout";
				} else {
				console.log(error.response.data);
				console.log(error.response.status);
				console.log(error.response.headers);
				}
			} else if (error.request) {
				//  The request was made but the server didn't respond
				console.log(error.request);
			} else {
				//  Something else went wrong
				console.log("Error", error.message);
			}
		});
	} else {
		dispatch({
			type: EDIT_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT,
			payload: {
			id: id,
			title: title,
			energyIndividualProductAssignmentProductsId: -1,
			energyIndividualProductAssignmentProductTypesId: -1,
			energyIndividualProductAssignmentProductValidFrom: "",
			energyIndividualProductAssignmentTarifOld: "",
			energyIndividualProductAssignmentLoadProfilesId: -1,
			energyIndividualProductAssignmentSwitchingTimesId: -1,
			},
		});
	}
}

const editEnergyIndividualProductAssignmentTimeframe = (id, activeAssignment) => {

	let title = (id > 0 ) ? "Zeitscheibe bearbeiten" : "Neue Zeitscheibe";

	let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
	let url = process.env.REACT_APP_API_URI + "/campaigns/get_energy_individual_product_timeframe.php";

	const params = new URLSearchParams();
	params.append("jwt", sessionStorage.jwt);
	params.append("id", id);
	params.append("assignmentId", activeAssignment);

	axios
		.post(url, params, axiosConfig)
		.then((response) => {
		if (response.status === 200) {
			//   console.log(response.data);

			dispatch({
				type: EDIT_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME,
				payload: {
					activeEnergyIndividualProductAssignmentTimeframeId:			id,
					energyIndividualProductAssignmentTimeframeDialogTitle:		title,
					energyIndividualProductAssignmentTimeframeClimatePlus:		response.data.climatePlus,
					energyIndividualProductAssignmentTimeframeValidFrom:			response.data.validFrom,
					energyIndividualProductAssignmentTimeframeValidTo:			response.data.validTo,
					energyIndividualProductAssignmentTimeframeIsNewYearsOnly:	response.data.isNewYearsOnly,
					energyIndividualProductAssignmentTimeframeGp:				response.data.gp,
					energyIndividualProductAssignmentTimeframeAp:				response.data.ap,
					energyIndividualProductAssignmentTimeframeApNt:				response.data.apNt,
					energyIndividualProductAssignmentTimeframeIsApNt:			response.data.isApNt,
				},
			});

			if (Number.parseInt(response.data.isClimatePlus) === 1)
				document.getElementById("energyIndividualProductAssignmentTimeframeClimatePlus").disabled = false;

		} else if (response.status === 401) {
			window.location.href = "#/logout";
		} else {
			console.log("Anderer Fehler");
		}
		})
		.catch(function (error) {
		if (error.response) {
			// The server answered with an error
			if (error.response.status === 401) {
			window.location.href = "#/logout";
			} else {
			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);
			}
		} else if (error.request) {
			//  The request was made but the server didn't respond
			console.log(error.request);
		} else {
			//  Something else went wrong
			console.log("Error", error.message);
		}
	});
}

const editGasIndividualProductAssignment = (id) => {

	let title = "Neue Produkt-Zuordnung";

    if (id > 0) {

      title = "Produkt-Zuordnung bearbeiten";

      let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
      let url = process.env.REACT_APP_API_URI + "/campaigns/get_gas_individual_product_assignment.php";

      const params = new URLSearchParams();
      params.append("jwt", sessionStorage.jwt);
      params.append("id", id);	

      axios
        .post(url, params, axiosConfig)
        .then((response) => {
          if (response.status === 200) {
            // console.log(response.data);

            let gasIndividualProductAssignmentProductValidFrom = "";
            if (response.data.gasIndividualProductAssignment.gasIndividualProductAssignmentProductValidFrom !== "") {
              gasIndividualProductAssignmentProductValidFrom = new Date(response.data.gasIndividualProductAssignment.gasIndividualProductAssignmentProductValidFrom);
            }

            dispatch({
              type: EDIT_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT,
              payload: {
                id: id,
                title: title,
				customerKindIds: response.data.customerKindIds,

                gasIndividualProductAssignmentLoadProfilesId:	response.data.gasIndividualProductAssignment.gasIndividualProductAssignmentLoadProfilesId,
				gasIndividualProductAssignmentProductsId:		response.data.gasIndividualProductAssignment.gasIndividualProductAssignmentProductsId,
				gasIndividualProductAssignmentProductTypesId:	response.data.gasIndividualProductAssignment.gasIndividualProductAssignmentProductTypesId,
				gasIndividualProductAssignmentProductValidFrom:	gasIndividualProductAssignmentProductValidFrom,
				gasIndividualProductAssignmentTarifOld:			response.data.gasIndividualProductAssignment.gasIndividualProductAssignmentTarifOld,
              },
            });
          } else if (response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log("Anderer Fehler");
          }
        })
        .catch(function (error) {
          if (error.response) {
            // The server answered with an error
            if (error.response.status === 401) {
              window.location.href = "#/logout";
            } else {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            }
          } else if (error.request) {
            //  The request was made but the server didn't respond
            console.log(error.request);
          } else {
            //  Something else went wrong
            console.log("Error", error.message);
          }
        });
    } else {
      dispatch({
        type: EDIT_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT,
        payload: {
          id: 										id,
          title: 									title,
          gasIndividualProductAssignmentLoadProfilesId: 		-1,
          gasIndividualProductAssignmentProductsId: 			-1,
          gasIndividualProductAssignmentProductTypesId:		-1,
          gasIndividualProductAssignmentProductValidFrom:	"",
          gasIndividualProductAssignmentTarifOld: 			"",
        },
      });
    }
}

  const editIndividualProductAssignment = (id, divisionsId) => {

	if(Number.parseInt(divisionsId) === 1){
      	editGasIndividualProductAssignment(id);
    } else {
      	editEnergyIndividualProductAssignment(id)
    }
  };

  //	CHANGE CUSTOMER KIND ID IN INDIVIDUAL PRODUCT GAS PRODUCT ASSIGNMENT DIALOG
  const customerKindIdsIdChange = (e) => {
    if (
      state.individualDetailDialogError !== "" &&
      state.customerKindIdsId === -1
    )
      setIndividualProductError("");

    dispatch({
      type: CUSTOMER_KIND_IDS_ID_CHANGE,
      payload: e.target.value,
    });
  };

  //	CHANGE CUSTOMER KIND IN INDIVIDUAL PRODUCT GAS PRODUCT ASSIGNMENT DIALOG
  const customerKindsIdChange = (e) => {
    if (
      state.customerKindsId === -1 &&
      state.editGasProductDetailError !== ""
    ) {
      setError("");
    }

    if (
      state.individualDetailDialogError !== "" &&
      state.customerKindsId === -1
    )
      setIndividualProductError("");

    const customerKindsId = e.target.value;

    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("customerKindId", e.target.value);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url =
      process.env.REACT_APP_API_URI + "/campaigns/get_customer_kind_ids.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          //   console.log(response.data);

          dispatch({
            type: CUSTOMER_KINDS_ID_CHANGE,
            payload: {
              customerKindsId: customerKindsId,
              customerKindIds: response.data.customerKindIds,
            },
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

const deleteIndividualProduct = () => {

	let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
	let url = process.env.REACT_APP_API_URI + "/campaigns/delete_individual_product.php";

	const params = new URLSearchParams();
	params.append("jwt", sessionStorage.jwt);
	params.append("id", state.activeIndividualProductId);

	axios
		.post(url, params, axiosConfig)
		.then((response) => {
		if (response.status === 200) {
			// console.log(response.data);

			closeDeleteIndividualProductConfirm();
			getIndividualProducts();

		} else if (response.status === 401) {
			window.location.href = "#/logout";
		} else {
			console.log("Anderer Fehler");
		}
		})
		.catch(function (error) {
		if (error.response) {
			// The server answered with an error
			if (error.response.status === 401) {
			window.location.href = "#/logout";
			} else {
			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);
			}
		} else if (error.request) {
			//  The request was made but the server didn't respond
			console.log(error.request);
		} else {
			//  Something else went wrong
			console.log("Error", error.message);
		}
	});
}

const energyIndividualProductAssignmentLoadProfilesIdChange = (e) => {

	if(state.energyIndividualProductAssignmentDialogError !== "" && state.energyIndividualProductAssignmentLoadProfilesId === -1)
		setEnergyIndividualProductAssignmentError("");

	if(state.energyIndividualProductAssignmentDialogError !== "" && Number.parseInt(state.energyIndividualProductAssignmentLoadProfilesId) === 2 && state.energyIndividualProductAssignmentSwitchingTimesId === -1)
		setEnergyIndividualProductAssignmentError("");

	dispatch({
		type: ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_LOAD_PROFILES_ID_CHANGE,
		payload: e.target.value,
	});
}

const energyIndividualProductAssignmentProductsIdChange = (e) => {

	if(state.energyIndividualProductAssignmentDialogError !== "" && state.energyIndividualProductAssignmentProductsId === -1) 
		setEnergyIndividualProductAssignmentError("");

	dispatch({
		type: ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODCUTS_ID_CHANGE,
		payload: e.target.value
	});
}

const energyIndividualProductAssignmentProductTypesIdChange = (e) => {

	if(state.energyIndividualProductAssignmentDialogError !== "" && state.energyIndividualProductAssignmentProductTypesId === -1)
		setEnergyIndividualProductAssignmentError("");

	dispatch({
		type: ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODUCT_TYPES_ID_CHANGE,
		payload: e.target.value
	});
}

const energyIndividualProductAssignmentProductValidFromChange = (date) => {

	if(state.energyIndividualProductAssignmentDialogError !== "" && state.energyIndividualProductAssignmentProductValidFrom === "")
		setEnergyIndividualProductAssignmentError("");

	dispatch({
		type: ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODUCT_VALID_FROM_CHANGE,
		payload: date,
	});
}

const energyIndividualProductAssignmentSwitchingTimesIdChange = (e) => {

	if(state.energyIndividualProductAssignmentDialogError !== "" && Number.parseInt(state.energyIndividualProductAssignmentLoadProfilesId) === 2 && state.energyIndividualProductAssignmentSwitchingTimesId === -1)
		setEnergyIndividualProductAssignmentError("");

	dispatch({
		type: ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_SWITCHING_TIMES_ID_CHANGE,
		payload: e.target.value,
	});
}

const energyIndividualProductAssignmentTarifOldChange = (e) => {

	dispatch({
		type: ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TARIF_OLD_CHANGE,
		payload: e.target.value
	});
}

const energyIndividualProductAssignmentTimeframeApChange = (e) => {

	if(state.energyIndividualProductAssignmentTimeframeDialogError !== "" && state.energyIndividualProductAssignmentTimeframeAp === "")
		setEnergyIndividualProductAssignmentError("");

	dispatch({
		type: ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_AP_CHANGE,
		payload: e.target.value,
	});
}

const energyIndividualProductAssignmentTimeframeApNtChange = (e) => {

	if(state.energyIndividualProductAssignmentTimeframeDialogError !== "" && Number.parseInt(state.isApNt) === 1 && state.energyIndividualProductAssignmentTimeframeApNt === "")
		setEnergyIndividualProductAssignmentError("");

	dispatch({
		type: ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_AP_NT_CHANGE,
		payload: e.target.value,
	});
}

const energyIndividualProductAssignmentTimeframeClimatePlusChange = (e) => {

	dispatch({
		type: ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_CLIMATE_PLUS_CHANGE,
		payload: e.target.value,
	});
}

const energyIndividualProductAssignmentTimeframeGpChange = (e) => {
	
	if(state.energyIndividualProductAssignmentTimeframeDialogError !== "" && state.energyIndividualProductAssignmentTimeframeGp === "")
		setEnergyIndividualProductAssignmentError("");

	dispatch({
		type: ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_GP_CHANGE,
		payload: e.target.value,
	});
}

const energyIndividualProductAssignmentTimeframeValidFromChange = (e) => {
	
	if(state.energyIndividualProductAssignmentTimeframeDialogError !== "" && (state.energyIndividualProductAssignmentTimeframeValidFrom === "" || Number.parseInt(state.energyIndividualProductAssignmentTimeframeValidFrom) < 2020 || Number.parseInt(state.energyIndividualProductAssignmentTimeframeValidFrom) > 2050))
		setEnergyIndividualProductAssignmentTimeframeError("");

	if(state.energyIndividualProductAssignmentTimeframeIsNewYearsOnly === 1) {

		dispatch({
			type: ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_VALID_FROM_CHANGE,
			payload: e.target.value,
		});

		dispatch({
			type: ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_VALID_TO_CHANGE,
			payload: e.target.value,
		});

	} else {

		dispatch({
			type: ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_VALID_FROM_CHANGE,
			payload: e.target.value,
		});
	}
}

const energyIndividualProductAssignmentTimeframeValidToChange = (e) => {

	if(state.energyIndividualProductAssignmentTimeframeDialogError !== "" && (state.energyIndividualProductAssignmentTimeframeValidTo === "" || Number.parseInt(state.energyIndividualProductAssignmentTimeframeValidTo) < 2020 || Number.parseInt(state.energyIndividualProductAssignmentTimeframeValidTo) > 2050))
		setEnergyIndividualProductAssignmentTimeframeError("");

	dispatch({
		type: ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_VALID_TO_CHANGE,
		payload: e.target.value,
	});
}

const gasIndividualProductAssignmentLoadProfilesIdChange = (e) => {

	if(state.gasIndividualProductAssignmentDialogError !== "" && state.gasIndividualProductAssignmentLoadProfilesId === -1)
		setGasIndividualProductAssignmentError("");

	dispatch({
		type: GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_LOAD_PROFILES_ID_CHANGE,
		payload: e.target.value,
	});
};

const gasIndividualProductAssignmentProductsIdChange = (e) => {

	if(state.gasIndividualProductAssignmentDialogError !== "" && state.gasIndividualProductAssignmentProductsId === -1)
		setGasIndividualProductAssignmentError("");
		
	dispatch({
		type: 		GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODUCTS_ID_CHANGE,
		payload:	e.target.value,
	});
};

const gasIndividualProductAssignmentTarifOldChange = (e) => {
	
	dispatch({
		type: GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TARIF_OLD_CHANGE,
		payload: e.target.value,
	});
};

const gasIndividualProductAssignmentProductTypesIdChange = (e) => {

	if(state.gasIndividualProductAssignmentDialogError !== "" && state.gasIndividualProductAssignmentProductTypesId === -1)
		setGasIndividualProductAssignmentError("");

	dispatch({
		type: GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODUCT_TYPES_ID_CHANGE,
		payload: e.target.value,
	});
};

const gasIndividualProductAssignmentProductValidFromChange = (date) => {

	if(state.gasIndividualProductAssignmentDialogError !== "" && state.gasIndividualProductAssignmentProductValidFrom === "")
		setGasIndividualProductAssignmentError("");

	dispatch({
		type: GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_PRODUCT_VALID_FROM_CHANGE,
		payload: date,
	});
};

const gasIndividualProductAssignmentTimeframeApChange = (e) => {

	if(state.gasIndividualProductAssignmentTimeframeDialogError !== "" && state.gasIndividualProductAssignmentTimeframeAp === "")
		setGasIndividualProductAssignmentError("");

	dispatch({
		type: GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_AP_CHANGE,
		payload: e.target.value,
	});
}

const gasIndividualProductAssignmentTimeframeBehgChange = (e) => {

	dispatch({
		type: GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_BEHG_CHANGE,
		payload: e.target.value,
	});
}

const gasIndividualProductAssignmentTimeframeBio5Change = (e) => {

	dispatch({
		type: GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_BIO5_CHANGE,
		payload: e.target.value
	});
}

const gasIndividualProductAssignmentTimeframeBio10Change = (e) => {

	dispatch({
		type: GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_BIO10_CHANGE,
		payload: e.target.value,
	});
}

const gasIndividualProductAssignmentTimeframeClimatePlusChange = (e) => {

	dispatch({
		type: GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_CLIMATE_PLUS_CHANGE,
		payload: e.target.value,
	});
}

const gasIndividualProductAssignmentTimeframeGpChange = (e) => {
	
	if(state.gasIndividualProductAssignmentTimeframeDialogError !== "" && state.gasIndividualProductAssignmentTimeframeGp === "")
		setGasIndividualProductAssignmentError("");

	dispatch({
		type: GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_GP_CHANGE,
		payload: e.target.value,
	});
}

const gasIndividualProductAssignmentTimeframeTehgChange = (e) => {

	dispatch({
		type: GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_TEHG_CHANGE,
		payload: e.target.value,
	});
}

const gasIndividualProductAssignmentTimeframeValidFromChange = (e) => {
	
	if(state.gasIndividualProductAssignmentTimeframeDialogError !== "" && (state.gasIndividualProductAssignmentTimeframeValidFrom === "" || Number.parseInt(state.gasIndividualProductAssignmentTimeframeValidFrom) < 2020 || Number.parseInt(state.gasIndividualProductAssignmentTimeframeValidFrom) > 2050))
		setGasIndividualProductAssignmentTimeframeError("");

	if(state.gasIndividualProductAssignmentTimeframeIsNewYearsOnly === 1){

		dispatch({
			type: GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_VALID_FROM_CHANGE,
			payload: e.target.value,
		});

		dispatch({
			type: GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_VALID_TO_CHANGE,
			payload: e.target.value,
		});
	} else {

		dispatch({
			type: GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_VALID_FROM_CHANGE,
			payload: e.target.value,
		});
	}
}

const gasIndividualProductAssignmentTimeframeValidToChange = (e) => {

	if(state.gasIndividualProductAssignmentTimeframeDialogError !== "" && (state.gasIndividualProductAssignmentTimeframeValidTo === "" || Number.parseInt(state.gasIndividualProductAssignmentTimeframeValidTo) < 2020 || Number.parseInt(state.gasIndividualProductAssignmentTimeframeValidTo) > 2050))
		setGasIndividualProductAssignmentTimeframeError("");

	dispatch({
		type: GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_VALID_TO_CHANGE,
		payload: e.target.value,
	});
}

  const gasProductTypesIdChange = (e) => {
    if (
      state.editGasProductDetailError !== "" &&
      state.gasProductTypesId === -1
    ) {
      setError("");
    }

    dispatch({
      type: GAS_PRODUCT_TYPES_ID_CHANGE,
      payload: e.target.value,
    });

    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", e.target.value);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url =
      process.env.REACT_APP_API_URI +
      "/administration/products/get_product_type.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);

          if (response.data.productType.isClimatePlus === true)
            document.getElementById("climatePlus").disabled = false;
          else document.getElementById("climatePlus").disabled = true;

          if (response.data.productType.isBio5 === true)
            document.getElementById("bio5").disabled = false;
          else document.getElementById("bio5").disabled = true;

          if (response.data.productType.isBio10 === true)
            document.getElementById("bio10").disabled = false;
          else document.getElementById("bio10").disabled = true;
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  const gasProductsIdChange = (e) => {
    if (
      state.editGasProductDetailError !== "" &&
      state.gasProductTypesId === -1
    ) {
      setError("");
    }

    dispatch({
      type: GAS_PRODUCTS_ID_CHANGE,
      payload: e.target.value,
    });
  };

  const getIndividualProductAssignments = () => {

	let axiosConfig = {headers: {"Content-Type": "application/x-www-form-urlencoded"}};
	let url = process.env.REACT_APP_API_URI + "/campaigns/get_individual_product_assignments.php";

    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("activeIndividualProductId", state.activeIndividualProductId);


    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
        //   console.log(response.data);

          dispatch({
            type: GET_INDIVIDUAL_PRODUCT_ASSIGNMENTS,
            payload: {
              gasIndividualProductAssignments: response.data.gasIndividualProductAssignments,
              energyIndividualProductAssignments: response.data.energyIndividualProductAssignments,
            },
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  const getIndividualProducts = () => {
	  
	let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded"}};
	let url = process.env.REACT_APP_API_URI + "/campaigns/get_individual_products.php";
    
	const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
            // console.log(response.data);

          dispatch({
            type: GET_INDIVIDUAL_PRODUCTS,
            payload: response.data.individualProducts,
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  const gpChange = (e) => {
    if (
      state.editGasProductDetailError !== "" &&
      (state.gp === "" || state.gp * 1 === 0)
    ) {
      setError("");
    }

    if (
      state.editProductDetailError !== "" &&
      (state.gp === "" || state.gp * 1 === 0)
    ) {
      setEnergyError("");
    }

    dispatch({
      type: GP_CHANGE,
      payload: e.target.value,
    });
  };

  const loadProfilesIdChange = (e) => {
    if (state.editProductDetailError !== "" && state.loadProfilesId === -1) {
      setEnergyError("");
    }

    dispatch({
      type: LOAD_PROFILES_ID_CHANGE,
      payload: e.target.value,
    });
  };

  const productsIdChange = (e) => {
    if (state.editProductDetailError !== "" && state.productsId === -1) {
      setError("");
    }

    if (state.editProductDetailError !== "" && state.energyProductsId === -1) {
      setEnergyError("");
    }

    dispatch({
      type: PRODUCTS_ID_CHANGE,
      payload: e.target.value,
    });
  };

  const productTypesIdChange = (e) => {
    if (state.editProductDetailError !== "" && state.productTypesId === -1) {
      setEnergyError("");
    }

    dispatch({
      type: PRODUCT_TYPES_ID_CHANGE,
      payload: e.target.value,
    });

    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", e.target.value);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url =
      process.env.REACT_APP_API_URI +
      "/administration/products/get_product_type.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);

          if (response.data.productType.isClimatePlus === true)
            document.getElementById("climatePlus").disabled = false;
          else document.getElementById("climatePlus").disabled = true;
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  //	CHANGE VALIDITY START FOR CAMPAIGN ENERGY PRODUCT ASSIGMENT
  const productValidFromChange = (date) => {
    if (
      state.editGasProductDetailError !== "" &&
      state.productValidFrom === ""
    ) {
      setError("");
    }

    if (state.editProductDetailError !== "" && state.productValidFrom === "") {
      setEnergyError("");
    }

    dispatch({
      type: PRODUCT_VALID_FROM_CHANGE,
      payload: date,
    });
  };

  //	SAVE INDIVIDUAL PRODUCT DIALG
  const saveIndividualProductDetailDialog = () => {

	//  Prüfe Pflichtfelder
    if (state.individualProductName === "") {
      setIndividualProductError("Bitte geben Sie einen Namen für das individuelle Produkt ein");
      return false;
    }

    if (state.individualProductDivisionsId === -1) {
      setIndividualProductError("Bitte wählen Sie eine Sparte für das individuelle Produkt aus");
      return false;
    }

    if (state.customerKindsId === -1) {
      setIndividualProductError("Bitte wählen Sie die Kundenart für das individuelle Produkt aus");
      return false;
    }

    if (state.customerKindIdsId === -1) {
      setIndividualProductError("Bitte wählen Sie einen (Potenzial-)Kunden für das individuelle Produkt aus");
      return false;
    }

    //  Save the individual product
    let axiosConfig = {headers: {"Content-Type": "application/x-www-form-urlencoded"}};
    let url = process.env.REACT_APP_API_URI + "/campaigns/set_individual_product.php";

    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", state.activeIndividualProductId);
    params.append("individual_product_name", state.individualProductName);
    params.append("divisions_id", state.individualProductDivisionsId);
    params.append("customer_kinds_id", state.customerKindsId);
    params.append("customer_kind_ids_id", state.customerKindIdsId);


    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
        //   console.log(response.data);

          closeIndividualProductDetailDialog();
          getIndividualProducts();
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

const saveEnergyIndividualProductAssignment = () => {

	if(state.energyIndividualProductAssignmentProductTypesId < 0)
		return setEnergyIndividualProductAssignmentError("Bitte wählen Sie eine Produktart für die Zuordnung aus.");
		
	if(state.energyIndividualProductAssignmentLoadProfilesId < 0)
		return setEnergyIndividualProductAssignmentError("Bitte wählen Sie ein Lastprofil für die Zuordnung aus.");

	if(state.energyIndividualProductAssignmentProductsId < 0)
		return setEnergyIndividualProductAssignmentError("Bitte wählen Sie ein Produkt für die Zuordnung aus.");

	if(Number.parseInt(state.energyIndividualProductAssignmentLoadProfilesId) === 2 && state.energyIndividualProductAssignmentSwitchingTimesId < 0)
		return setEnergyIndividualProductAssignmentError("Bitte wählen Sie eine Schaltzeit für die Zuordnung aus.");

	if(state.energyIndividualProductAssignmentProductValidFrom === "")
		return setEnergyIndividualProductAssignmentError("Bitte geben Sie an, ab wann das Produkt angelegt werden soll.");


	let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
	let url = process.env.REACT_APP_API_URI + "/campaigns/set_energy_individual_product_assignment.php";

	const params = new URLSearchParams();
	params.append("jwt", sessionStorage.jwt);
	params.append("id", state.activeIndividualProductAssignmentId);
	params.append("individualProductId", state.activeIndividualProductId);
	params.append("loadProfilesId", state.energyIndividualProductAssignmentLoadProfilesId);
	params.append("energyProductsId", state.energyIndividualProductAssignmentProductsId);
	params.append("energyProductTypesId", state.energyIndividualProductAssignmentProductTypesId);
	params.append("switchingTimesId", state.energyIndividualProductAssignmentSwitchingTimesId);
	params.append("tarifOld", state.energyIndividualProductAssignmentTarifOld);

	const productValidFrom = (state.energyIndividualProductAssignmentProductValidFrom !== "") ? new Date(state.energyIndividualProductAssignmentProductValidFrom) : "";
	let newProductValidFrom = "";

	if (productValidFrom !== "") {

		const newDay	= productValidFrom.getDate();
		const newMonth 	= productValidFrom.getMonth() + 1;
		const newYear 	= productValidFrom.getFullYear();

		newProductValidFrom = newYear + "-" + newMonth + "-" + newDay;
	}
	params.append("productValidFrom", newProductValidFrom);


	axios
		.post(url, params, axiosConfig)
		.then((response) => {
		if (response.status === 200) {
			// console.log(response.data);

			dispatch({
			type: CLOSE_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT,
			});

			getIndividualProductAssignments();
		} else if (response.status === 401) {
			window.location.href = "#/logout";
		} else {
			console.log("Anderer Fehler");
		}
		})
		.catch(function (error) {
		if (error.response) {
			// The server answered with an error
			if (error.response.status === 401) {
			window.location.href = "#/logout";
			} else {
			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);
			}
		} else if (error.request) {
			//  The request was made but the server didn't respond
			console.log(error.request);
		} else {
			//  Something else went wrong
			console.log("Error", error.message);
		}
	});
}

const saveEnergyIndividualProductAssignmentTimeframe = () => {

	//	Check mandatory fields
	if(state.energyIndividualProductAssignmentTimeframeValidFrom === "" ||
        Number.parseInt(state.energyIndividualProductAssignmentTimeframeValidFrom) < 2020 ||
        Number.parseInt(state.energyIndividualProductAssignmentTimeframeValidFrom ) > 2050) {
			
			return setEnergyIndividualProductAssignmentTimeframeError("Bitte geben Sie einen validen Beginn für die Laufzeit der Zeitscheibe ein.");
	}

	if(state.energyIndividualProductAssignmentTimeframeValidTo === "" ||
		Number.parseInt(state.energyIndividualProductAssignmentTimeframeValidTo) < 2020 ||
		Number.parseInt(state.energyIndividualProductAssignmentTimeframeValidTo) > 2050)	{

			return setEnergyIndividualProductAssignmentTimeframeError("Bitte geben Sie ein valides Ende für die Laufzeit der Zeitscheibe ein.");
	}

	if(state.energyIndividualProductAssignmentTimeframeGp === "")
		return setEnergyIndividualProductAssignmentTimeframeError("Bitte geben Sie einen Grundpreis für die Zeitscheibe ein.");
		
	if(Number.parseInt(state.energyIndividualProductAssignmentTimeframeIsApNt) === 0 && state.energyIndividualProductAssignmentTimeframeAp === "")
		return setEnergyIndividualProductAssignmentTimeframeError("Bitte geben Sie einen Arbeitspreis für die Zeitscheibe ein.");

	if(Number.parseInt(state.energyIndividualProductAssignmentTimeframeIsApNt) === 1 && state.energyIndividualProductAssignmentTimeframeAp === "")
		return setEnergyIndividualProductAssignmentTimeframeError("Bitte geben Sie einen Arbeitspreis HT für die Zeitscheibe ein.");

	if(Number.parseInt(state.energyIndividualProductAssignmentTimeframeIsApNt) === 1 && state.energyIndividualProductAssignmentTimeframeApNt === "")
		return setEnergyIndividualProductAssignmentTimeframeError("Bitte geben Sie einen Arbeitspreis NT für die Zeitscheibe ein.");

	
	let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
	let url = process.env.REACT_APP_API_URI + "/campaigns/set_energy_individual_product_assignment_timeframe.php";

	let gp = state.energyIndividualProductAssignmentTimeframeGp.replace(".", "").replace(",", ".") * 1;
	let ap = state.energyIndividualProductAssignmentTimeframeAp.replace(".", "").replace(",", ".") * 1;
	let apNt = state.energyIndividualProductAssignmentTimeframeApNt.replace(".", "").replace(",", ".") * 1;
	let climatePlus = state.energyIndividualProductAssignmentTimeframeClimatePlus.replace(".", "").replace(",", ".") * 1;

	const params = new URLSearchParams();
	params.append("jwt", sessionStorage.jwt);
	params.append("id", state.activeEnergyIndividualProductAssignmentTimeframeId);
	params.append("energyIndividualProductAssignmentId", state.activeIndividualProductAssignmentId);
	params.append("validFrom", state.energyIndividualProductAssignmentTimeframeValidFrom);
	params.append("validTo", state.energyIndividualProductAssignmentTimeframeValidTo);
	params.append("isNewYearsOnly", state.energyIndividualProductAssignmentTimeframeIsNewYearsOnly);
	params.append("gp", gp);
	params.append("ap", ap);
	params.append("apNt", apNt);
	params.append("climatePlus", climatePlus);

	axios
		.post(url, params, axiosConfig)
		.then((response) => {
		if (response.status === 200) {
			// console.log(response.data);

			dispatch({
				type: CLOSE_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_DIALOG,
			});

			getEnergyIndividualProductTimeframes();
		} else if (response.status === 401) {
			window.location.href = "#/logout";
		} else {
			console.log("Anderer Fehler");
		}
		})
		.catch(function (error) {
		if (error.response) {
			// The server answered with an error
			if (error.response.status === 401) {
			window.location.href = "#/logout";
			} else {
			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);
			}
		} else if (error.request) {
			//  The request was made but the server didn't respond
			console.log(error.request);
		} else {
			//  Something else went wrong
			console.log("Error", error.message);
		}
	});
}

  const saveGasIndividualProductAssignment = () => {

	if (state.gasIndividualProductAssignmentProductTypesId < 0)
		return setGasIndividualProductAssignmentError("Bitte wählen Sie eine Produktart für die Zuordnung aus.");

	if(state.gasIndividualProductAssignmentLoadProfilesId < 0)
		return setGasIndividualProductAssignmentError("Bitte wählen Sie ein Lastprofil für die Zuordnung aus.");

	if(state.gasIndividualProductAssignmentProductsId < 0)
		return setGasIndividualProductAssignmentError("Bitte wählen Sie ein Produkt für die Zuordnung aus.");

	if(state.gasIndividualProductAssignmentProductValidFrom === "")
		return setGasIndividualProductAssignmentError("Bitte geben Sie an, ab wann das Produkt angelegt werden soll.");


	let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
	let url = process.env.REACT_APP_API_URI + "/campaigns/set_gas_individual_product_assignment.php";

	const params = new URLSearchParams();
	params.append("jwt", sessionStorage.jwt);
	params.append("id", state.activeIndividualProductAssignmentId);
	params.append("individualProductId", state.activeIndividualProductId);
	params.append("loadProfilesId", state.gasIndividualProductAssignmentLoadProfilesId);
	params.append("gasProductsId", state.gasIndividualProductAssignmentProductsId);
	params.append("gasProductTypesId", state.gasIndividualProductAssignmentProductTypesId);
	params.append("tarifOld", state.gasIndividualProductAssignmentTarifOld);

	const productValidFrom = (state.gasIndividualProductAssignmentProductValidFrom !== "") ? new Date(state.gasIndividualProductAssignmentProductValidFrom) : "";
	let newProductValidFrom = "";

	if (productValidFrom !== "") {

		const newDay	= productValidFrom.getDate();
		const newMonth 	= productValidFrom.getMonth() + 1;
		const newYear 	= productValidFrom.getFullYear();

		newProductValidFrom = newYear + "-" + newMonth + "-" + newDay;
	}
	params.append("productValidFrom", newProductValidFrom);


	axios
		.post(url, params, axiosConfig)
		.then((response) => {
		if (response.status === 200) {
			// console.log(response.data);

			dispatch({
			type: CLOSE_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT,
			});

			getIndividualProductAssignments();
		} else if (response.status === 401) {
			window.location.href = "#/logout";
		} else {
			console.log("Anderer Fehler");
		}
		})
		.catch(function (error) {
		if (error.response) {
			// The server answered with an error
			if (error.response.status === 401) {
			window.location.href = "#/logout";
			} else {
			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);
			}
		} else if (error.request) {
			//  The request was made but the server didn't respond
			console.log(error.request);
		} else {
			//  Something else went wrong
			console.log("Error", error.message);
		}
	});
};

const saveGasIndividualProductAssignmentTimeframe = () => {

	//	Check mandatory fields
	if(state.gasIndividualProductAssignmentTimeframeValidFrom === "" ||
        Number.parseInt(state.gasIndividualProductAssignmentTimeframeValidFrom) < 2020 ||
        Number.parseInt(state.gasIndividualProductAssignmentTimeframeValidFrom ) > 2050) {
			
			return setGasIndividualProductAssignmentTimeframeError("Bitte geben Sie einen validen Beginn für die Laufzeit der Zeitscheibe ein.");
	}

	if(state.gasIndividualProductAssignmentTimeframeValidTo === "" ||
		Number.parseInt(state.gasIndividualProductAssignmentTimeframeValidTo) < 2020 ||
		Number.parseInt(state.gasIndividualProductAssignmentTimeframeValidTo) > 2050)	{

			return setGasIndividualProductAssignmentTimeframeError("Bitte geben Sie ein valides Ende für die Laufzeit der Zeitscheibe ein.");
	}

	if(state.gasIndividualProductAssignmentTimeframeGp === "")
		return setGasIndividualProductAssignmentTimeframeError("Bitte geben Sie einen Grundpreis für die Zeitscheibe ein.");
		
	if(state.gasIndividualProductAssignmentTimeframeAp === "")
		return setGasIndividualProductAssignmentTimeframeError("Bitte geben Sie einen Arbeitspreis für die Zeitscheibe ein.");

	
	let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
	let url = process.env.REACT_APP_API_URI + "/campaigns/set_gas_individual_product_assignment_timeframe.php";

	let gp = state.gasIndividualProductAssignmentTimeframeGp.replace(".", "").replace(",", ".") * 1;
	let ap = state.gasIndividualProductAssignmentTimeframeAp.replace(".", "").replace(",", ".") * 1;
	let climatePlus = state.gasIndividualProductAssignmentTimeframeClimatePlus.replace(".", "").replace(",", ".") * 1;
	let bio5 = state.gasIndividualProductAssignmentTimeframeBio5.replace(".", "").replace(",", ".") * 1;
	let bio10 = state.gasIndividualProductAssignmentTimeframeBio10.replace(".", "").replace(",", ".") * 1;
	let behg = state.gasIndividualProductAssignmentTimeframeBehg.replace(".", "").replace(",", ".") * 1;
	let tehg = state.gasIndividualProductAssignmentTimeframeTehg.replace(".", "").replace(",", ".") * 1;

	const params = new URLSearchParams();
	params.append("jwt", sessionStorage.jwt);
	params.append("id", state.activeGasIndividualProductAssignmentTimeframeId);
	params.append("gasIndividualProductAssignmentId", state.activeIndividualProductAssignmentId);
	params.append("validFrom", state.gasIndividualProductAssignmentTimeframeValidFrom);
	params.append("validTo", state.gasIndividualProductAssignmentTimeframeValidTo);
	params.append("isNewYearsOnly", state.gasIndividualProductAssignmentTimeframeIsNewYearsOnly);
	params.append("gp", gp);
	params.append("ap", ap);
	params.append("climatePlus", climatePlus);
	params.append("bio5", bio5);
	params.append("bio10", bio10);
	params.append("behg", behg);
	params.append("tehg", tehg);

	axios
		.post(url, params, axiosConfig)
		.then((response) => {
		if (response.status === 200) {
			// console.log(response.data);

			dispatch({
			type: CLOSE_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_DIALOG,
			});

			getGasIndividualProductTimeframes();
		} else if (response.status === 401) {
			window.location.href = "#/logout";
		} else {
			console.log("Anderer Fehler");
		}
		})
		.catch(function (error) {
		if (error.response) {
			// The server answered with an error
			if (error.response.status === 401) {
			window.location.href = "#/logout";
			} else {
			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);
			}
		} else if (error.request) {
			//  The request was made but the server didn't respond
			console.log(error.request);
		} else {
			//  Something else went wrong
			console.log("Error", error.message);
		}
	});
}

  //	SAVE GAS PRODUCT ASSIGNMENT DIALOG
  const saveGasProductDetail = () => {
	  
    //	Check mandatory fields
    if (state.customerKindsId < 0) {
      return setError("Bitte wählen Sie die Kundenart für die Zuordnung aus.");
    }

    if (state.customerKindIdsId < 0) {
      return setError("Bitte wählen Sie einen Kunden für die Zuordnung aus.");
    }

    if (state.gasProductTypesId < 0) {
      return setError(
        "Bitte wählen Sie eine Produktart für die Zuordnung aus."
      );
    }

    // if (state.administrationsId < 0) {
    //   return setError(
    //     "Bitte wählen Sie eine Verwaltung für die Zuordnung aus."
    //   );
    // }

    if (
      state.validFrom === "" ||
      state.validFrom * 1 < 2010 ||
      state.validFrom * 1 > 2050
    ) {
      return setError(
        "Bitte geben Sie einen validen Beginn für die Laufzeit ein."
      );
    }

    if (
      state.validTo === "" ||
      state.validTo * 1 < 2010 ||
      state.validTo * 1 > 2050
    ) {
      return setError("Bitte geben Sie ein valides Ende für die Laufzeit ein.");
    }

    if (state.gasProductsId < 0) {
      return setError("Bitte wählen Sie ein Produkt für die Zuordnung aus.");
    }

    if (state.gp === "" && state.gp * 1 === 0) {
      return setError("Bitte geben Sie einen validen Grundpreis €/Jahr ein.");
    }

    if (state.ap === "" && state.ap * 1 === 0) {
      return setError("Bitte geben Sie einen validen Arbeitspreis ct/kWh ein.");
    }

    if (state.productValidFrom === "") {
      return setError(
        "Bitte geben Sie an, ab wann das Produkt angelegt werden soll."
      );
    }

    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", state.activeProduct);
    params.append("table", "gas_individual_product_assignments");
    params.append("individual_products_id", state.activeIndividualId);
    params.append("customer_kinds_id", state.customerKindsId);
    params.append("customer_kind_ids_id", state.customerKindIdsId);
    params.append("product_types_id", state.gasProductTypesId);
    params.append("administrations_id", state.administrationsId);
    params.append("load_profiles_id", state.loadProfilesId);
    params.append("valid_from", state.validFrom);
    params.append("valid_to", state.validTo);
    params.append("products_id", state.gasProductsId);
    params.append("tarif_old", state.tarifOld);
    params.append("ap", state.ap);
    params.append("ap_nt", state.apNt);
    params.append("climate_plus", state.climatePlus);
    params.append("bio5", state.bio5);
    params.append("bio10", state.bio10);
    params.append("co2", state.co2);
    params.append("gp", state.gp);

    let productValidFrom =
      state.productValidFrom !== "" ? new Date(state.productValidFrom) : "";
    if (productValidFrom !== "") {
      const newDay = productValidFrom.getDate();
      const newMonth = productValidFrom.getMonth() + 1;
      const newYear = productValidFrom.getFullYear();
      productValidFrom = newYear + "-" + newMonth + "-" + newDay;
    }
    params.append("product_valid_from", productValidFrom);

    let url =
      process.env.REACT_APP_API_URI +
      "/campaigns/set_gas_individual_assignment.php";
    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);

          dispatch({
            type: CLOSE_GAS_PRODUCT_DETAIL,
          });

          getIndividualProductAssignments();
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

  //	SAVE ENERGY PRODUCT ASSIGNMENT DIALOG
  const saveProductDetail = () => {
    //	Check mandatory fields
    if (Number.parseInt(state.customerKindsId, 10) < 0) {
      return setError("Bitte wählen Sie die Kundenart für die Zuordnung aus.");
    }

    if (Number.parseInt(state.customerKindIdsId, 10) < 0) {
      return setError("Bitte wählen Sie einen Kunden für die Zuordnung aus.");
    }

    if (Number.parseInt(state.productTypesId, 10) < 0) {
      return setEnergyError(
        "Bitte wählen Sie eine Produktart für die Zuordnung aus."
      );
    }

    // if (Number.parseInt(state.administrationsId, 10) < 0) {
    //   return setEnergyError(
    //     "Bitte wählen Sie eine Verwaltung für die Zuordnung aus."
    //   );
    // }

    if (Number.parseInt(state.loadProfilesId, 10) === -1) {
      return setEnergyError(
        "Bitte wählen Sie ein Lastprofil für die Zuordnung aus."
      );
    }

    if (
      Number.parseInt(state.loadProfilesId, 10) === 2 &&
      Number.parseInt(state.switchingTimesId, 10) === -1
    ) {
      return setEnergyError(
        "Bitte wählen Sie die Schaltzeit für die Zuordnung aus."
      );
    }

    if (
      state.validFrom === "" ||
      state.validFrom * 1 < 2010 ||
      state.validFrom * 1 > 2050
    ) {
      return setEnergyError(
        "Bitte geben Sie einen validen Beginn für die Laufzeit ein."
      );
    }

    if (
      state.validTo === "" ||
      state.validTo * 1 < 2010 ||
      state.validTo * 1 > 2050
    ) {
      return setEnergyError(
        "Bitte geben Sie ein valides Ende für die Laufzeit ein."
      );
    }

    if (state.energyProductsId < 0) {
      return setEnergyError(
        "Bitte wählen Sie ein Produkt für die Zuordnung aus."
      );
    }

    if (state.gp === "" && state.gp * 1 === 0) {
      return setEnergyError(
        "Bitte geben Sie einen validen Grundpreis €/Jahr ein."
      );
    }

    if (state.ap === "" && state.ap * 1 === 0) {
      return setEnergyError(
        "Bitte geben Sie einen validen Arbeitspreis ct/kWh ein."
      );
    }

    if (
      Number.parseInt(state.loadProfilesId, 10) === 2 &&
      Number.parseInt(state.switchingTimesId, 10) > 1 &&
      state.apNt === "" &&
      state.apNt * 1 === 0
    ) {
      return setEnergyError(
        "Bitte geben Sie einen validen Arbeitspreis (NT) ct/kWh ein."
      );
    }

    if (state.productValidFrom === "") {
      return setEnergyError(
        "Bitte geben Sie an, ab wann das Produkt angelegt werden soll."
      );
    }

    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("id", state.activeProduct);
    params.append("table", "energy_individual_product_assignments");
    params.append("individual_products_id", state.activeIndividualId);
    params.append("customer_kinds_id", state.customerKindsId);
    params.append("customer_kind_ids_id", state.customerKindIdsId);
    params.append("product_types_id", state.productTypesId);
    params.append("administrations_id", state.administrationsId);
    params.append("load_profiles_id", state.loadProfilesId);
    params.append("switching_times_id", state.switchingTimesId);
    params.append("valid_from", state.validFrom);
    params.append("valid_to", state.validTo);
    params.append("products_id", state.energyProductsId);
    params.append("tarif_old", state.tarifOld);
    params.append("gp", state.gp);
    params.append("ap", state.ap);
    params.append("ap_nt", state.apNt);
    params.append("climate_plus", state.climatePlus);

    let productValidFrom =
      state.productValidFrom !== "" ? new Date(state.productValidFrom) : "";
    if (productValidFrom !== "") {
      const newDay = productValidFrom.getDate();
      const newMonth = productValidFrom.getMonth() + 1;
      const newYear = productValidFrom.getFullYear();
      productValidFrom = newYear + "-" + newMonth + "-" + newDay;
    }
    params.append("product_valid_from", productValidFrom);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let url =
      process.env.REACT_APP_API_URI +
      "/campaigns/set_individual_assignment.php";

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);

          dispatch({
            type: CLOSE_PRODUCT_DETAIL,
          });

          getIndividualProductAssignments();
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };


const setEnergyIndividualProductAssignmentError = (errMsg) => {

	dispatch({
		type: SET_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_ERROR,
		payload: errMsg,
	});
}

const setGasIndividualProductAssignmentError = (errMsg) => {

	dispatch({
		type: 		SET_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_ERROR,
		payload:	errMsg,
	});
}

const setGasIndividualProductAssignmentTimeframeError = (errMsg) => {

	dispatch({
		type: SET_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_ERROR,
		payload: errMsg,
	});
}

  const setIndividualProductError = (errMsg) => {
    dispatch({
      type: SET_INDIVIDUAL__PRODUCT_ERROR,
      payload: errMsg,
    });
  };

const setEnergyIndividualProductAssignmentTimeframeError = (errMsg) => {

	dispatch({
		type: SET_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME_ERROR,
		payload: errMsg,
	});
}

  const setError = (errMsg) => {
    dispatch({
      type: SET_ERROR,
      payload: errMsg,
    });

    return false;
  };

  const setEnergyError = (errMsg) => {
    dispatch({
      type: SET_ENERGY_ERROR,
      payload: errMsg,
    });

    return false;
  };

const setIndividualProductForeigns = (energyProductTypes, gasProductTypes,  energyProducts, gasProducts, loadProfiles, switchingTimes, customerKinds) => {

    dispatch({
		type: SET_INDIVIDUAL_PRODUCT_FOREIGNS,
	    payload: {
			energyProductTypes: energyProductTypes,
			gasProductTypes: gasProductTypes,
			energyProducts: energyProducts,
			gasProducts: gasProducts,
			loadProfiles: loadProfiles,
			switchingTimes: switchingTimes,
			customerKinds: customerKinds,
      	},
    });
};

  const switchingTimesIdChange = (e) => {
    if (state.editProductDetailError !== "" && state.switchingTimesId === -1) {
      setEnergyError("");
    }

    let apNt = state.apNt;
    if (e.target.value < 2) apNt = "";

    dispatch({
      type: SWITCHING_TIMES_ID_CHANGE,
      payload: {
        switchingTimesId: e.target.value,
        apNt: apNt,
      },
    });
  };

  const tarifOldChange = (e) => {
    dispatch({
      type: TARIF_OLD_CHANGE,
      payload: e.target.value,
    });
  };

  const individualProductNameChange = (e) => {

    if ( state.individualProductDetailDialogError !== "" && state.individualProductName === "")
		setIndividualProductError("");

    dispatch({
      type: INDIVIDUAL_PRODUCT_NAME_CHANGE,
      payload: e.target.value,
    });
  };

  const validFromChange = (e) => {
    if (
      state.editGasProductDetailError !== "" &&
      (state.validFrom === "" ||
        state.validFrom * 1 < 2010 ||
        state.validFrom * 1 > 2050)
    ) {
      setError("");
    }

    if (
      state.editProductDetailError !== "" &&
      (state.validFrom === "" ||
        state.validFrom * 1 < 2020 ||
        state.validFrom * 1 > 2050)
    ) {
      setEnergyError("");
    }

    dispatch({
      type: VALID_FROM_CHANGE,
      payload: e.target.value,
    });
  };

  const validToChange = (e) => {
    if (
      state.editGasProductDetailError !== "" &&
      (state.validTo === "" ||
        state.validTo * 1 < 2020 ||
        state.validTo * 1 > 2050)
    ) {
      setError("");
    }

    if (
      state.editProductDetailError !== "" &&
      (state.validTo === "" ||
        state.validTo * 1 < 2020 ||
        state.validTo * 1 > 2050)
    ) {
      setEnergyError("");
    }

    dispatch({
      type: VALID_TO_CHANGE,
      payload: e.target.value,
    });
  };

const editGasIndividualProductAssignmentTimeframe = (id, activeAssignment) => {
	
	let title = (id > 0 ) ? "Zeitscheibe bearbeiten" : "Neue Zeitscheibe";

	let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
	let url = process.env.REACT_APP_API_URI + "/campaigns/get_gas_individual_product_timeframe.php";

	const params = new URLSearchParams();
	params.append("jwt", sessionStorage.jwt);
	params.append("id", id);
	params.append("assignmentId", activeAssignment);


	axios
		.post(url, params, axiosConfig)
		.then((response) => {
		if (response.status === 200) {
			//   console.log(response.data);

			dispatch({
				type: EDIT_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAME,
				payload: {
					activeGasIndividualProductAssignmentTimeframeId:		id,
					gasIndividualProductAssignmentTimeframeDialogTitle:	title,
					gasIndividualProductAssignmentTimeframeClimatePlus:	response.data.climatePlus,
					gasIndividualProductAssignmentTimeframeBio5:			response.data.bio5,
					gasIndividualProductAssignmentTimeframeBio10:		response.data.bio10,
					gasIndividualProductAssignmentTimeframeValidFrom:	response.data.validFrom,
					gasIndividualProductAssignmentTimeframeValidTo:		response.data.validTo,
					gasIndividualProductAssignmentTimeframeIsNewYearsOnly:	response.data.isNewYearsOnly,
					gasIndividualProductAssignmentTimeframeGp:			response.data.gp,
					gasIndividualProductAssignmentTimeframeAp:			response.data.ap,
					gasIndividualProductAssignmentTimeframeBehg:		response.data.behg,
					gasIndividualProductAssignmentTimeframeTehg:		response.data.tehg,
				},
			});

			if (Number.parseInt(response.data.isClimatePlus) === 1)
				document.getElementById("gasIndividualProductAssignmentTimeframeClimatePlus").disabled = false;

			if (Number.parseInt(response.data.isBio5) === 1)
				document.getElementById("gasIndividualProductAssignmentTimeframeBio5").disabled = false;

			if (Number.parseInt(response.data.isBio10) === 1)
				document.getElementById("gasIndividualProductAssignmentTimeframeBio10").disabled = false;

		} else if (response.status === 401) {
			window.location.href = "#/logout";
		} else {
			console.log("Anderer Fehler");
		}
		})
		.catch(function (error) {
		if (error.response) {
			// The server answered with an error
			if (error.response.status === 401) {
			window.location.href = "#/logout";
			} else {
			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);
			}
		} else if (error.request) {
			//  The request was made but the server didn't respond
			console.log(error.request);
		} else {
			//  Something else went wrong
			console.log("Error", error.message);
		}
	});
};

const getEnergyIndividualProductTimeframes = () => {

    let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
    let url = process.env.REACT_APP_API_URI + "/campaigns/get_energy_individual_product_timeframes.php";

    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("activeIndividualProductAssignmentId", state.activeIndividualProductAssignmentId);

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {

			// console.log(response.data);	
			dispatch({
				type: GET_ENERGY_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAMES,
				payload: {
					energyIndividualProductAssignmentTimeframes: response.data.assignments,
				},
	        });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
};

const getGasIndividualProductTimeframes  = () => {

    let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
    let url = process.env.REACT_APP_API_URI + "/campaigns/get_gas_individual_product_timeframes.php";

    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("activeIndividualProductAssignmentId", state.activeIndividualProductAssignmentId);

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {

            // console.log(response.data);
          dispatch({
            type: GET_GAS_INDIVIDUAL_PRODUCT_ASSIGNMENT_TIMEFRAMES,
            payload: {
              gasIndividualProductAssignmentTimeframes: response.data.assignments,
            },
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
};

  return (
    <IndividualsContext.Provider
      value={{
        administrations: state.administrations,
        administrationsId: state.administrationsId,
        activeIndividualProductId: state.activeIndividualProductId,
		activeIndividualProductAssignmentId: state.activeIndividualProductAssignmentId,
        activeProduct: state.activeProduct,
        bio5: state.bio5,
        bio10: state.bio10,
        ap: state.ap,
        apNt: state.apNt,
        individualProductDetailDialogError: state.individualProductDetailDialogError,
        individualProductDetailDialogState: state.individualProductDetailDialogState,
        individualProductDetailDialogTitle: state.individualProductDetailDialogTitle,
        individualProducts: state.individualProducts,
		energyIndividualProductAssignments:	state.energyIndividualProductAssignments,
        individualProductName: state.individualProductName,
        climatePlus: state.climatePlus,
        co2: state.co2,
        customerKindIds: state.customerKindIds,
        customerKindIdsId: state.customerKindIdsId,
        customerKinds: state.customerKinds,
        customerKindsId: state.customerKindsId,
		deleteIndividualProductAssignmentConfirmName:	state.deleteIndividualProductAssignmentConfirmName,
		deleteIndividualProductAssignmentConfirmState:	state.deleteIndividualProductAssignmentConfirmState,
        deleteIndividualProductConfirmName: state.deleteIndividualProductConfirmName,
        deleteIndividualProductConfirmState: state.deleteIndividualProductConfirmState,
        deleteGasProductConfirmName: state.deleteGasProductConfirmName,
        deleteGasProductConfirmState: state.deleteGasProductConfirmState,
        deleteProductConfirmName: state.deleteProductConfirmName,
        deleteProductConfirmState: state.deleteProductConfirmState,
        individualProductDivisions: state.individualProductDivisions,
        individualProductDivisionsId: state.individualProductDivisionsId,
        editGasProductDetailError: state.editGasProductDetailError,
        editGasProductDetailState: state.editGasProductDetailState,
        editGasProductDetailTitle: state.editGasProductDetailTitle,
        editProductDetailState: state.editProductDetailState,
        editProductDetailError: state.editProductDetailError,
        editProductState: state.editProductState,
        editProductDetailTitle: state.editProductDetailTitle,
        energyProductAssignments: state.energyProductAssignments,
        energyProducts: state.energyProducts,
        energyProductsId: state.energyProductsId,
        gasProductAssignments: state.gasProductAssignments,
		gasIndividualProductAssignmentProducts: state.gasIndividualProductAssignmentProducts,
		gasIndividualProductAssignmentProductsId:	state.gasIndividualProductAssignmentProductsId,
		gasIndividualProductAssignmentProductTypes:	state.gasIndividualProductAssignmentProductTypes,
		gasIndividualProductAssignmentProductTypesId:	state.gasIndividualProductAssignmentProductTypesId,
		gasIndividualProductAssignmentProductValidFrom:	state.gasIndividualProductAssignmentProductValidFrom,
        gp: state.gp,
        isEnergyIndividual: state.isEnergyIndividual,
        isGasIndividual: state.isGasIndividual,
		gasIndividualProductAssignmentLoadProfiles:	state.gasIndividualProductAssignmentLoadProfiles,
		gasIndividualProductAssignmentLoadProfilesId:	state.gasIndividualProductAssignmentLoadProfilesId,
		gasIndividualProductAssignmentTarifOld:	state.gasIndividualProductAssignmentTarifOld,
		energyIndividualProductAssignmentDialogError:	state.energyIndividualProductAssignmentDialogError,
		energyIndividualProductAssignmentDialogState:	state.energyIndividualProductAssignmentDialogState,
		energyIndividualProductAssignmentDialogTitle:	state.energyIndividualProductAssignmentDialogTitle,
		energyIndividualProductAssignmentLoadProfiles:	state.energyIndividualProductAssignmentLoadProfiles,
		energyIndividualProductAssignmentLoadProfilesId:	state.energyIndividualProductAssignmentLoadProfilesId,
		energyIndividualProductAssignmentProducts:	state.energyIndividualProductAssignmentProducts,
		energyIndividualProductAssignmentProductsId: state.energyIndividualProductAssignmentProductsId,
		energyIndividualProductAssignmentProductTypes:	state.energyIndividualProductAssignmentProductTypes,
		energyIndividualProductAssignmentProductTypesId:	state.energyIndividualProductAssignmentProductTypesId,
		energyIndividualProductAssignmentProductValidFrom: 	state.energyIndividualProductAssignmentProductValidFrom,
		energyIndividualProductAssignmentTimeframeDeleteDialogName:	state.energyIndividualProductAssignmentTimeframeDeleteDialogName,
		energyIndividualProductAssignmentTimeframeDeleteDialogState:	state.energyIndividualProductAssignmentTimeframeDeleteDialogState,
		energyIndividualProductAssignmentTimeframeDialogError:	state.energyIndividualProductAssignmentTimeframeDialogError,
		energyIndividualProductAssignmentTimeframeDialogState:	state.energyIndividualProductAssignmentTimeframeDialogState,
		energyIndividualProductAssignmentTimeframeDialogTitle:	state.energyIndividualProductAssignmentTimeframeDialogTitle,
		energyIndividualProductAssignmentTimeframeIsApNt:	state.energyIndividualProductAssignmentTimeframeIsApNt,
		energyIndividualProductAssignmentTimeframeIsNewYearsOnly: state.energyIndividualProductAssignmentTimeframeIsNewYearsOnly,
		energyIndividualProductAssignmentTimeframes:	state.energyIndividualProductAssignmentTimeframes,
        productValidFrom: state.productValidFrom,
		energyIndividualProductAssignmentSwitchingTimes:	state.energyIndividualProductAssignmentSwitchingTimes,
		energyIndividualProductAssignmentSwitchingTimesId:	state.energyIndividualProductAssignmentSwitchingTimesId,
        energyIndividualProductAssignmentTarifOld: state.energyIndividualProductAssignmentTarifOld,
        validFrom: state.validFrom,
        validTo: state.validTo,
		energyIndividualProductAssignmentTimeframeAp:	state.energyIndividualProductAssignmentTimeframeAp,
		energyIndividualProductAssignmentTimeframeApNt:	state.energyIndividualProductAssignmentTimeframeApNt,
		energyIndividualProductAssignmentTimeframeClimatePlus:	state.energyIndividualProductAssignmentTimeframeClimatePlus,
		energyIndividualProductAssignmentTimeframeGp:	state.energyIndividualProductAssignmentTimeframeGp,
		energyIndividualProductAssignmentTimeframeValidFrom:	state.energyIndividualProductAssignmentTimeframeValidFrom,
		energyIndividualProductAssignmentTimeframeValidTo:	state.energyIndividualProductAssignmentTimeframeValidTo,
		gasIndividualProductAssignments: state.gasIndividualProductAssignments,
		gasIndividualProductAssignmentDialogError:	state.gasIndividualProductAssignmentDialogError,
		gasIndividualProductAssignmentDialogState:	state.gasIndividualProductAssignmentDialogState,
		gasIndividualProductAssignmentDialogTitle:	state.gasIndividualProductAssignmentDialogTitle,
		gasIndividualProductAssignmentTimeframes: state.gasIndividualProductAssignmentTimeframes,
		gasIndividualProductAssignmentTimeframeAp:	state.gasIndividualProductAssignmentTimeframeAp,
		gasIndividualProductAssignmentTimeframeBehg: state.gasIndividualProductAssignmentTimeframeBehg,
		gasIndividualProductAssignmentTimeframeBio5:	state.gasIndividualProductAssignmentTimeframeBio5,
		gasIndividualProductAssignmentTimeframeBio10:	state.gasIndividualProductAssignmentTimeframeBio10,
		gasIndividualProductAssignmentTimeframeClimatePlus:	state.gasIndividualProductAssignmentTimeframeClimatePlus,
		gasIndividualProductAssignmentTimeframeDeleteDialogName:	state.gasIndividualProductAssignmentTimeframeDeleteDialogName,
		gasIndividualProductAssignmentTimeframeDeleteDialogState:	state.gasIndividualProductAssignmentTimeframeDeleteDialogState,
		gasIndividualProductAssignmentTimeframeDialogError:	state.gasIndividualProductAssignmentTimeframeDialogError,
		gasIndividualProductAssignmentTimeframeDialogState:	state.gasIndividualProductAssignmentTimeframeDialogState,
		gasIndividualProductAssignmentTimeframeDialogTitle: state.gasIndividualProductAssignmentTimeframeDialogTitle,
		gasIndividualProductAssignmentTimeframeGp:	state.gasIndividualProductAssignmentTimeframeGp,
		gasIndividualProductAssignmentTimeframeIsNewYearsOnly: state.gasIndividualProductAssignmentTimeframeIsNewYearsOnly,
		gasIndividualProductAssignmentTimeframeTehg: state.gasIndividualProductAssignmentTimeframeTehg,
		gasIndividualProductAssignmentTimeframeValidFrom:	state.gasIndividualProductAssignmentTimeframeValidFrom,
		gasIndividualProductAssignmentTimeframeValidTo: state.gasIndividualProductAssignmentTimeframeValidTo,

        administrationsIdChange,
        apChange,
        apNtChange,
        bio5Change,
        bio10Change,
        changeDivision,
        climatePlusChange,
		closeDeleteIndividualProductConfirm,
		closeEnergyIndividualProductAssignmentDialog,
		closeEnergyIndividualProductAssignmentTimeframeDeleteDialog,
		closeEnergyIndividualProductAssignmentTimeframeDialog,
		closeGasIndividualProductAssignmentDialog,
		closeGasIndividualProductAssignmentTimeframeDeleteDialog,
		closeGasIndividualProductAssignmentTimeframeDialog,
		closeIndividualProductAssignmentConfirm,
        closeIndividualProductConfirm,
        closeIndividualProductDetailDialog,
        closeGasProductConfirm,
        closeGasProductDetail,
        closeProductConfirm,
        closeProductDetail,
        co2Change,
        customerKindsIdChange,
        customerKindIdsIdChange,
		deleteEnergyIndividualProductAssignmentTimeframe,
		deleteEnergyIndividualProductAssignmentTimeframeConfirm,
		deleteGasIndividualProductAssignmentTimeframe,
        deleteIndividual,
		deleteIndividualProduct,
        deleteIndividualProductConfirm,
		deleteIndividualProductAssignment,
		deleteIndividualProductAssignmentConfirm,
		deleteIndividualProductAssignmentTimeframeConfirm,
        deleteGasProduct,
        deleteGasProductConfirm,
        deleteProduct,
        deleteProductConfirm,
        individualProductDivisionsIdChange,
        editIndividualProduct,
        editGasProduct,
		editEnergyIndividualProductAssignment,
		editEnergyIndividualProductAssignmentTimeframe,
		editGasIndividualProductAssignment,
		editGasIndividualProductAssignmentTimeframe,
        editIndividualProductAssignment,
		energyIndividualProductAssignmentLoadProfilesIdChange,
		energyIndividualProductAssignmentProductsIdChange,
		energyIndividualProductAssignmentProductTypesIdChange,
		energyIndividualProductAssignmentProductValidFromChange,
		energyIndividualProductAssignmentSwitchingTimesIdChange,
		energyIndividualProductAssignmentTarifOldChange,
		energyIndividualProductAssignmentTimeframeApChange,
		energyIndividualProductAssignmentTimeframeApNtChange,
		energyIndividualProductAssignmentTimeframeClimatePlusChange,
		energyIndividualProductAssignmentTimeframeGpChange,
		energyIndividualProductAssignmentTimeframeValidFromChange,
		energyIndividualProductAssignmentTimeframeValidToChange,
		gasIndividualProductAssignmentLoadProfilesIdChange,
		gasIndividualProductAssignmentProductsIdChange,
		gasIndividualProductAssignmentTarifOldChange,
		gasIndividualProductAssignmentProductTypesIdChange,
		gasIndividualProductAssignmentProductValidFromChange,
		gasIndividualProductAssignmentTimeframeApChange,
		gasIndividualProductAssignmentTimeframeBehgChange,
		gasIndividualProductAssignmentTimeframeBio5Change,
		gasIndividualProductAssignmentTimeframeBio10Change,
		gasIndividualProductAssignmentTimeframeClimatePlusChange,
		gasIndividualProductAssignmentTimeframeGpChange,
		gasIndividualProductAssignmentTimeframeTehgChange,
		gasIndividualProductAssignmentTimeframeValidFromChange,
		gasIndividualProductAssignmentTimeframeValidToChange,
        gasProductTypesIdChange,
        gasProductsIdChange,
		getEnergyIndividualProductTimeframes,
		getGasIndividualProductTimeframes,
        getIndividualProductAssignments,
        getIndividualProducts,
        gpChange,
        loadProfilesIdChange,
        productsIdChange,
        productTypesIdChange,
        productValidFromChange,
        saveIndividualProductDetailDialog,
		saveEnergyIndividualProductAssignment,
		saveEnergyIndividualProductAssignmentTimeframe,
		saveGasIndividualProductAssignment,
		saveGasIndividualProductAssignmentTimeframe,
        saveGasProductDetail,
        saveProductDetail,
		setEnergyIndividualProductAssignmentError,
		setGasIndividualProductAssignmentError,
		setGasIndividualProductAssignmentTimeframeError,
        setIndividualProductError,
        setEnergyError,
		setEnergyIndividualProductAssignmentTimeframeError,
        setError,
        setIndividualProductForeigns,
        switchingTimesIdChange,
        tarifOldChange,
        individualProductNameChange,
        validFromChange,
        validToChange,
      }}
    >
      {props.children}
    </IndividualsContext.Provider>
  );
};

export default IndividualsState;
