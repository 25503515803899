/**
 * 		File:		./src/context/campaigns/CampaignsState.js
 *
 * 		Subject:	Campaign's state
 */

import React, { useReducer } from "react";
import axios from "axios";
import CampaignsContext from "./campaignsContext";
import campaignsReducer from "./campaignsReducer";

import {

	/* CAMPAIGNS */
	CLOSE_DELETE_CAMPAIGN_CONFIRM,
	DELETE_CAMPAIGN_CONFIRM,
	EDIT_CAMPAIGN,
	GET_CAMPAIGNS,
	SET_CAMPAIGN_FOREIGNS,

	/* CAMPAIGN DETAILS */
	CAMPAIGN_DIVISIONS_ID_CHANGE,
	CAMPAIGN_NAME_CHANGE,
	CLOSE_CAMPAIGN_DETAIL,
	DELETE_CAMPAIGN_ASSIGNMENT_CONFIRM,
	EDIT_ENERGY_CAMPAIGN_ASSIGNMENT,
	EDIT_GAS_CAMPAIGN_ASSIGNMENT,
	GET_CAMPAIGN_ASSIGNMENTS,
	SET_CAMPAIGN_DETAIL_ERROR,

	/* GAS CAMPAIGN ASSIGNMENTS */
	CLOSE_CAMPAIGN_ASSIGNMENT_DELETE_DIALOG,
	CLOSE_GAS_CAMPAIGN_ASSIGNMENT,
	EDIT_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME,
	GAS_CAMPAIGN_ASSIGNMENT_LOAD_PROFILES_ID_CHANGE,
	GAS_CAMPAIGN_ASSIGNMENT_PRODUCTS_ID_CHANGE,
	GAS_CAMPAIGN_ASSIGNMENT_PRODUCT_TYPES_ID_CHANGE,
	GAS_CAMPAIGN_ASSIGNMENT_PRODUCT_VALID_FROM_CHANGE,
	GAS_CAMPAIGN_ASSIGNMENT_TARIF_OLD_CHANGE,
	SET_GAS_CAMPAIGN_ASSIGNMENT_ERROR,

	/* ENERGY CAMPAIGN ASSIGNMENTS */
	CLOSE_ENERGY_CAMPAIGN_ASSIGNMENT,
	EDIT_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME,
	SET_ENERGY_CAMPAIGN_ASSIGNMENT_ERROR,
	ENERGY_CAMPAIGN_ASSIGNMENT_LOAD_PROFILES_ID_CHANGE,
	ENERGY_CAMPAIGN_ASSIGNMENT_PRODCUTS_ID_CHANGE,
	ENERGY_CAMPAIGN_ASSIGNMENT_PRODUCT_TYPES_ID_CHANGE,
	ENERGY_CAMPAIGN_ASSIGNMENT_PRODUCT_VALID_FROM_CHANGE,
	ENERGY_CAMPAIGN_ASSIGNMENT_SWITCHING_TIMES_ID_CHANGE,
	ENERGY_CAMPAIGN_ASSIGNMENT_TARIF_OLD_CHANGE,

	/*	GAS CAMPAIGN ASSIGNMENT TIMEFRAMES */
	CLOSE_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_DIALOG,
	CLOSE_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_DELETE_DIALOG,
	DELETE_GAS_ASSIGNMENT_TIMEFRAME_CONFIRM,
	GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_AP_CHANGE,
	GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_BEHG_CHANGE,
	GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_BIO5_CHANGE,
	GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_BIO10_CHANGE,
	GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_CLIMATE_PLUS_CHANGE,
	GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_GP_CHANGE,
	GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_TEHG_CHANGE,
	GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_VALID_FROM_CHANGE,
	GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_VALID_TO_CHANGE,
	GET_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAMES,
	SET_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_ERROR,

	/*	ENERGY CAMPAIGN ASSIGNMENT TIMEFRAMES */
	CLOSE_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_DIALOG,
	CLOSE_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_DELETE_DIALOG,
	DELETE_ENERGY_ASSIGNMENT_TIMEFRAME_CONFIRM,
	ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_AP_CHANGE,
	ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_AP_NT_CHANGE,
	ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_CLIMATE_PLUS_CHANGE,
	ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_GP_CHANGE,
	ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_VALID_FROM_CHANGE,
	ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_VALID_TO_CHANGE,
	GET_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAMES,
	SET_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_ERROR,

} from "../types";


const CampaignsState = (props) => {
  const initialState = {

	//	CAMPAIGNS
	
	activeCampaignId:			0,
	campaignDetailDialogError:	"",
	campaignDetailDialogState:	false,
	campaignDetailDialogTitle:	"",
	campaignDivisionsId:		-1,
	campaignName:				"",
	campaigns: 					[],
	deleteCampaignConfirmName:	"",
	deleteCampaignConfirmState:	false,
	energyProducts: 			[],
	energyProductTypes: 		[],
	gasProducts: 				[],
	gasProductTypes: 			[],
	loadProfiles: 				[],
	switchingTimes: 			[],

	//	CAMPAIGN DETAILS
	campaignDivisions:						[
		{ id: -1, value: "Bitte wählen..." },
		{ id: 1, value: "Erdgas" },
		{ id: 2, value: "Strom"}
	],
	deleteCampaignAssignmentConfirmName: 	"",
	deleteCampaignAssignmentConfirmState:	false,
	energyCampaignAssignments:				[],
	gasCampaignAssignments:					[],
	
	//	GAS CAMPAIGN ASSIGNMENTS
	activeCampaignAssignmentId:				0,
	gasCampaignAssignmentDialogError:		"",
	gasCampaignAssignmentDialogState:		false,
	gasCampaignAssignmentDialogTitle:		"",
	gasCampaignAssignmentLoadProfiles:		[],
	gasCampaignAssignmentLoadProfilesId:	-1,
	gasCampaignAssignmentProducts:			[],
	gasCampaignAssignmentProductsId:		-1,
	gasCampaignAssignmentProductTypes:		[],
	gasCampaignAssignmentProductTypesId:	-1,
	gasCampaignAssignmentProductValidFrom:	"",
	gasCampaignAssignmentTarifOld:			"",
	gasCampaignAssignmentTimeframes:		[],

	//	ENERGY CAMPAIGN ASSIGNMENTS
	energyCampaignAssignmentDialogError:		"",
	energyCampaignAssignmentDialogState:		false,
	energyCampaignAssignmentDialogTitle:		"",
	energyCampaignAssignmentLoadProfiles:		[],
	energyCampaignAssignmentLoadProfilesId:		-1,
	energyCampaignAssignmentProducts:			[],
	energyCampaignAssignmentProductsId:			-1,
	energyCampaignAssignmentProductTypes:		[],
	energyCampaignAssignmentProductTypesId:		-1,
	energyCampaignAssignmentProductValidFrom:	"",
	energyCampaignAssignmentSwitchingTimes:		[],
	energyCampaignAssignmentSwitchingTimesId:	-1,
	energyCampaignAssignmentTarifOld:			"",
	energyCampaignAssignmentTimeframes:			[],

	//	GAS CAMPAIGN TIMEFRAMES
	activeGasCampaignAssignmentTimeframeId:			0,
	gasCampaignAssignmentTimeframeDialogError:		"",
	gasCampaignAssignmentTimeframeDialogState:		false,
	gasCampaignAssignmentTimeframeDialogTitle:		"",
	gasCampaignAssignmentTimeframeDeleteDialogName:	"",
	gasCampaignAssignmentTimeframeDeleteDialogState:false,
	gasCampaignAssignmentTimeframeAp:				"",
	gasCampaignAssignmentTimeframeBehg:				"",
	gasCampaignAssignmentTimeframeBio5:				"",
	gasCampaignAssignmentTimeframeBio10:			"",
	gasCampaignAssignmentTimeframeClimatePlus:		"",
	gasCampaignAssignmentTimeframeGp:				"",
	gasCampaignAssignmentTimeframeIsNewYearsOnly:	1,
	gasCampaignAssignmentTimeframeTehg:				"",
	gasCampaignAssignmentTimeframeValidFrom:		"",
	gasCampaignAssignmentTimeframeValidTo:			"",
	
	//	ENERGY CAMPAIGN TIMEFRAMES
	activeEnergyCampaignAssignmentTimeframeId:			0,
	energyCampaignAssignmentTimeframeDialogError:		"",
	energyCampaignAssignmentTimeframeDialogState:		false,
	energyCampaignAssignmentTimeframeDialogTitle:		"",
	energyCampaignAssignmentTimeframeDeleteDialogName:	"",
	energyCampaignAssignmentTimeframeDeleteDialogState:	false,
	energyCampaignAssignmentTimeframeAp:				"",
	energyCampaignAssignmentTimeframeApNt:				"",
	energyCampaignAssignmentTimeframeClimatePlus:		"",
	energyCampaignAssignmentTimeframeGp:				"",
	energyCampaignAssignmentTimeframeIsApNt:			0,
	energyCampaignAssignmentTimeframeIsNewYearsOnly:	1,
	energyCampaignAssignmentTimeframeValidFrom:			"",
	energyCampaignAssignmentTimeframeValidTo:			"",
  };
  const [state, dispatch] = useReducer(campaignsReducer, initialState);

/*----------------------------------
	CAMPAIGNS
----------------------------------*/

const closeDeleteCampaignConfirm = () => {
	dispatch({
		type: CLOSE_DELETE_CAMPAIGN_CONFIRM,
	});
};

const deleteCampaign = () => {

	let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
	let url = process.env.REACT_APP_API_URI + "/campaigns/delete_campaign.php";

	const params = new URLSearchParams();
	params.append("jwt", sessionStorage.jwt);
	params.append("id", state.activeCampaignId);

	axios
		.post(url, params, axiosConfig)
		.then((response) => {
		if (response.status === 200) {
			// console.log(response.data);

			closeDeleteCampaignConfirm();
			getCampaigns();

		} else if (response.status === 401) {
			window.location.href = "#/logout";
		} else {
			console.log("Anderer Fehler");
		}
		})
		.catch(function (error) {
		if (error.response) {
			// The server answered with an error
			if (error.response.status === 401) {
			window.location.href = "#/logout";
			} else {
			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);
			}
		} else if (error.request) {
			//  The request was made but the server didn't respond
			console.log(error.request);
		} else {
			//  Something else went wrong
			console.log("Error", error.message);
		}
	});
};
  
const deleteCampaignConfirm = (id) => {
	
	let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
	let url = process.env.REACT_APP_API_URI + "/campaigns/get_campaign.php";

	const params = new URLSearchParams();
	params.append("jwt", sessionStorage.jwt);
	params.append("id", id);


	axios
		.post(url, params, axiosConfig)
		.then((response) => {
		if (response.status === 200) {
			// console.log(response.data);

			dispatch({
			type: DELETE_CAMPAIGN_CONFIRM,
			payload: {
				id: id,
				deleteCampaignConfirmName: response.data.campaign.campaign_name,
			},
			});
		} else if (response.status === 401) {
			window.location.href = "#/logout";
		} else {
			console.log("Anderer Fehler");
		}
		})
		.catch(function (error) {
		if (error.response) {
			// The server answered with an error
			if (error.response.status === 401) {
			window.location.href = "#/logout";
			} else {
			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);
			}
		} else if (error.request) {
			//  The request was made but the server didn't respond
			console.log(error.request);
		} else {
			//  Something else went wrong
			console.log("Error", error.message);
		}
	});
};
  
const editCampaign = async (id) => {
	  
	let title = id > 0 ? "Kampagne bearbeiten" : "Neue Kampagne anlegen";

	if(id > 0){

		let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
		let url = process.env.REACT_APP_API_URI + "/campaigns/get_campaign.php";

		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("id", id);

		axios
			.post(url, params, axiosConfig)
			.then((response) => {
			if (response.status === 200) {
				// console.log(response.data);

				dispatch({
					type: EDIT_CAMPAIGN,
					payload: {
						id: 					id,
						campaignName: 			response.data.campaign.campaign_name,
						campaignDivisionsId: 	response.data.campaign.divisions_id,
						title: title,
					},
				});

				state.activeCampaignId = id;
				document.getElementById("campaignDivisionsId").disabled = true;
				getCampaignAssignments();

			} else if (response.status === 401) {
				window.location.href = "#/logout";
			} else {
				console.log("Anderer Fehler");
			}
		})
		.catch(function (error) {
			if (error.response) {
				// The server answered with an error
				if (error.response.status === 401) {
					window.location.href = "#/logout";
				} else {
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				}
			} else if (error.request) {
				//  The request was made but the server didn't respond
				console.log(error.request);
			} else {
				//  Something else went wrong
				console.log("Error", error.message);
			}
		});
	} else {
		await dispatch({
			type: EDIT_CAMPAIGN,
			payload: {
				id: id,
				campaignName: "",
				campaignDivisionsId: -1,
				title: title,
			},
	  });

	//   getCampaignAssignments();
	}
};

const getCampaigns = () => {


    let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded"} };
    let url = process.env.REACT_APP_API_URI + "/campaigns/get_campaigns.php";

    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
        //   console.log(response.data);

          dispatch({
            type: GET_CAMPAIGNS,
            payload: response.data.campaigns,
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
  };

const setCampaignForeigns = (energyProductTypes,gasProductTypes,energyProducts,gasProducts,loadProfiles,switchingTimes) => {

	dispatch({
		type: SET_CAMPAIGN_FOREIGNS,
		payload: {
		energyProductTypes:	energyProductTypes,
		gasProductTypes: 	gasProductTypes,
		energyProducts: 	energyProducts,
		gasProducts: 		gasProducts,
		loadProfiles: 		loadProfiles,
		switchingTimes: 	switchingTimes,
		},
	});
};


/*----------------------------------
	CAMPAIGN DETAILS
----------------------------------*/
const campaignDivisionsIdChange = (e) => {

	if (state.campaignDetailDialogError !== "" && state.campaignDivisionsId === -1)
		setCampaignDetailError("");

	dispatch({
		type: CAMPAIGN_DIVISIONS_ID_CHANGE,
		payload: e.target.value,
	});
};

const campaignNameChange = (e) => {

	if (state.campaignDetailDialogError !== "" && state.campaignName === "") {
		setCampaignDetailError("");
	}

	dispatch({
		type: CAMPAIGN_NAME_CHANGE,
		payload: e.target.value,
	});
};

const closeCampaignAssignmentDeleteDialog = () => {
	dispatch({
		type: CLOSE_CAMPAIGN_ASSIGNMENT_DELETE_DIALOG
	});
}

const closeCampaignDetailDialog = () => {

	dispatch({
		type: CLOSE_CAMPAIGN_DETAIL
	});
};

const deleteCampaignAssignment = () => {
	
	let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
	let url = process.env.REACT_APP_API_URI + "/campaigns/delete_campaign_assignment.php";

	const params = new URLSearchParams();
	params.append("jwt", sessionStorage.jwt);
	params.append("id", state.activeCampaignAssignmentId);
	params.append("activeCampaignId", state.activeCampaignId);

	axios
		.post(url, params, axiosConfig)
		.then((response) => {
		if (response.status === 200) {
			// console.log(response.data);

			closeCampaignAssignmentDeleteDialog();
			getCampaignAssignments();
		} else if (response.status === 401) {
			window.location.href = "#/logout";
		} else {
			console.log("Anderer Fehler");
		}
		})
		.catch(function (error) {
		if (error.response) {
			// The server answered with an error
			if (error.response.status === 401) {
			window.location.href = "#/logout";
			} else {
			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);
			}
		} else if (error.request) {
			//  The request was made but the server didn't respond
			console.log(error.request);
		} else {
			//  Something else went wrong
			console.log("Error", error.message);
		}
	});
};

const deleteCampaignAssignmentConfirm = (id) => {

	dispatch({
		type: DELETE_CAMPAIGN_ASSIGNMENT_CONFIRM,
		payload: id
	});
};

const editCampaignAssignment = (id, divisionId) => {

	if(Number.parseInt(divisionId) === 1) {
		editGasCampaignAssignment(id);
	} else {
		editEnergyCampaignAssignment(id);
	}	
};

const editEnergyCampaignAssignment = (id) => {

	let title = "Neue Produkt-Zuordnung";

	if (id > 0) {

		title = "Produkt-Zuordnung bearbeiten";

		let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
		let url = process.env.REACT_APP_API_URI + "/campaigns/get_energy_campaign_assignment.php";

		const params = new URLSearchParams();
		params.append("jwt", sessionStorage.jwt);
		params.append("id", id);


		axios
			.post(url, params, axiosConfig)
			.then((response) => {
			if (response.status === 200) {
				// console.log(response.data);

				let energyCampaignAssignmentProductValidFrom = "";
				if (response.data.energyProductAssignment.energyCampaignAssignmentProductValidFrom !== "") {
					energyCampaignAssignmentProductValidFrom = new Date(
						response.data.energyProductAssignment.energyCampaignAssignmentProductValidFrom
					);
				}

				dispatch({
					type: EDIT_ENERGY_CAMPAIGN_ASSIGNMENT,
					payload: {
						id: id,
						title: title,

						energyCampaignAssignmentProductsId: response.data.energyProductAssignment.energyCampaignAssignmentProductsId,
						energyCampaignAssignmentProductTypesId:
						response.data.energyProductAssignment.energyCampaignAssignmentProductTypesId,
						energyCampaignAssignmentProductValidFrom: energyCampaignAssignmentProductValidFrom,
						energyCampaignAssignmentTarifOld: response.data.energyProductAssignment.energyCampaignAssignmentTarifOld,
						energyCampaignAssignmentLoadProfilesId:
						response.data.energyProductAssignment.energyCampaignAssignmentLoadProfilesId,
						energyCampaignAssignmentSwitchingTimesId:
						response.data.energyProductAssignment.energyCampaignAssignmentSwitchingTimesId,
					},
				});
			} else if (response.status === 401) {
				window.location.href = "#/logout";
			} else {
				console.log("Anderer Fehler");
			}
			})
			.catch(function (error) {
			if (error.response) {
				// The server answered with an error
				if (error.response.status === 401) {
				window.location.href = "#/logout";
				} else {
				console.log(error.response.data);
				console.log(error.response.status);
				console.log(error.response.headers);
				}
			} else if (error.request) {
				//  The request was made but the server didn't respond
				console.log(error.request);
			} else {
				//  Something else went wrong
				console.log("Error", error.message);
			}
		});
	} else {
		dispatch({
			type: EDIT_ENERGY_CAMPAIGN_ASSIGNMENT,
			payload: {
			id: id,
			title: title,
			energyCampaignAssignmentProductsId: -1,
			energyCampaignAssignmentProductTypesId: -1,
			energyCampaignAssignmentProductValidFrom: "",
			energyCampaignAssignmentTarifOld: "",
			energyCampaignAssignmentLoadProfilesId: -1,
			energyCampaignAssignmentSwitchingTimesId: -1,
			},
		});
	}
}

const editGasCampaignAssignment = (id) => {

    let title = "Neue Produkt-Zuordnung";

    if (id > 0) {

      title = "Produkt-Zuordnung bearbeiten";

      let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
      let url = process.env.REACT_APP_API_URI + "/campaigns/get_gas_campaign_assignment.php";

      const params = new URLSearchParams();
      params.append("jwt", sessionStorage.jwt);
      params.append("id", id);	

      axios
        .post(url, params, axiosConfig)
        .then((response) => {
          if (response.status === 200) {
            // console.log(response.data);

            let gasCampaignAssignmentProductValidFrom = "";
            if (response.data.gasCampaignAssignment.gasCampaignAssignmentProductValidFrom !== "") {
              gasCampaignAssignmentProductValidFrom = new Date(response.data.gasCampaignAssignment.gasCampaignAssignmentProductValidFrom);
            }

            dispatch({
              type: EDIT_GAS_CAMPAIGN_ASSIGNMENT,
              payload: {
                id: id,
                title: title,

                gasCampaignAssignmentLoadProfilesId:	response.data.gasCampaignAssignment.gasCampaignAssignmentLoadProfilesId,
				gasCampaignAssignmentProductsId:		response.data.gasCampaignAssignment.gasCampaignAssignmentProductsId,
				gasCampaignAssignmentProductTypesId:	response.data.gasCampaignAssignment.gasCampaignAssignmentProductTypesId,
				gasCampaignAssignmentProductValidFrom:	gasCampaignAssignmentProductValidFrom,
				gasCampaignAssignmentTarifOld:			response.data.gasCampaignAssignment.gasCampaignAssignmentTarifOld,
              },
            });
          } else if (response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log("Anderer Fehler");
          }
        })
        .catch(function (error) {
          if (error.response) {
            // The server answered with an error
            if (error.response.status === 401) {
              window.location.href = "#/logout";
            } else {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            }
          } else if (error.request) {
            //  The request was made but the server didn't respond
            console.log(error.request);
          } else {
            //  Something else went wrong
            console.log("Error", error.message);
          }
        });
    } else {
      dispatch({
        type: EDIT_GAS_CAMPAIGN_ASSIGNMENT,
        payload: {
          id: 										id,
          title: 									title,
          gasCampaignAssignmentLoadProfilesId: 		-1,
          gasCampaignAssignmentProductsId: 			-1,
          gasCampaignAssignmentProductTypesId:		-1,
          gasCampaignAssignmentProductValidFrom:	"",
          gasCampaignAssignmentTarifOld: 			"",
        },
      });
    }
}

const energyCampaignAssignmentLoadProfilesIdChange = (e) => {

	if(state.energyCampaignAssignmentDialogError !== "" && state.energyCampaignAssignmentLoadProfilesId === -1)
		setEnergyCampaignAssignmentError("");

	if(state.energyCampaignAssignmentDialogError !== "" && Number.parseInt(state.energyCampaignAssignmentLoadProfilesId) === 2 && state.energyCampaignAssignmentSwitchingTimesId === -1)
		setEnergyCampaignAssignmentError("");

	dispatch({
		type: ENERGY_CAMPAIGN_ASSIGNMENT_LOAD_PROFILES_ID_CHANGE,
		payload: e.target.value,
	});
}

const energyCampaignAssignmentProductsIdChange = (e) => {

	if(state.energyCampaignAssignmentDialogError !== "" && state.energyCampaignAssignmentProductsId === -1) 
		setEnergyCampaignAssignmentError("");

	dispatch({
		type: ENERGY_CAMPAIGN_ASSIGNMENT_PRODCUTS_ID_CHANGE,
		payload: e.target.value
	});
}

const energyCampaignAssignmentProductTypesIdChange = (e) => {

	if(state.energyCampaignAssignmentDialogError !== "" && state.energyCampaignAssignmentProductTypesId === -1)
		setEnergyCampaignAssignmentError("");

	dispatch({
		type: ENERGY_CAMPAIGN_ASSIGNMENT_PRODUCT_TYPES_ID_CHANGE,
		payload: e.target.value
	});
}

const energyCampaignAssignmentProductValidFromChange = (date) => {

	if(state.energyCampaignAssignmentDialogError !== "" && state.energyCampaignAssignmentProductValidFrom === "")
		setEnergyCampaignAssignmentError("");

	dispatch({
		type: ENERGY_CAMPAIGN_ASSIGNMENT_PRODUCT_VALID_FROM_CHANGE,
		payload: date,
	});
}

const energyCampaignAssignmentTarifOldChange = (e) => {

	dispatch({
		type: ENERGY_CAMPAIGN_ASSIGNMENT_TARIF_OLD_CHANGE,
		payload: e.target.value
	});
}

const energyCampaignAssignmentSwitchingTimesIdChange = (e) => {

	if(state.energyCampaignAssignmentDialogError !== "" && Number.parseInt(state.energyCampaignAssignmentLoadProfilesId) === 2 && state.energyCampaignAssignmentSwitchingTimesId === -1)
		setEnergyCampaignAssignmentError("");

	dispatch({
		type: ENERGY_CAMPAIGN_ASSIGNMENT_SWITCHING_TIMES_ID_CHANGE,
		payload: e.target.value,
	});
}

const getCampaignAssignments = () => {
	
	let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
	let url = process.env.REACT_APP_API_URI + "/campaigns/get_campaign_assignments.php";

	const params = new URLSearchParams();
	params.append("jwt", sessionStorage.jwt);
	params.append("activeCampaignId", state.activeCampaignId);

	axios
		.post(url, params, axiosConfig)
		.then((response) => {
		if (response.status === 200) {
			// console.log(response.data);

			dispatch({
				type: GET_CAMPAIGN_ASSIGNMENTS,
				payload: {
					gasCampaignAssignments: 	response.data.gasCampaignAssignments,
					energyCampaignAssignments: 	response.data.energyCampaignAssignments,
				},
			});
		} else if (response.status === 401) {
			window.location.href = "#/logout";
		} else {
			console.log("Anderer Fehler");
		}
		})
		.catch(function (error) {
		if (error.response) {
			// The server answered with an error
			if (error.response.status === 401) {
			window.location.href = "#/logout";
			} else {
			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);
			}
		} else if (error.request) {
			//  The request was made but the server didn't respond
			console.log(error.request);
		} else {
			//  Something else went wrong
			console.log("Error", error.message);
		}
	});
};

const saveCampaignDetailDialog = () => {

	//  Prüfe Pflichtfelder
	if (state.campaignName === "") {
		setCampaignDetailError("Bitte geben Sie einen Titel für die Kampagne ein");
		return false;
	}

	if (state.campaignDivisionsId === -1) {
		setCampaignDetailError("Bitte wählen Sie eine Sparte für die Kampagne aus");
		return false;
	}

	//  Speichere die Kampagne
	let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
	let url = process.env.REACT_APP_API_URI + "/campaigns/set_campaign.php";

	const params = new URLSearchParams();
	params.append("jwt", sessionStorage.jwt);
	params.append("id", state.activeCampaignId);
	params.append("campaignName", state.campaignName);
	params.append("campaignDivisionsId", state.campaignDivisionsId);

	axios
		.post(url, params, axiosConfig)
		.then((response) => {
		if (response.status === 200) {
			// console.log(response.data);

			closeCampaignDetailDialog();
			getCampaigns();

		} else if (response.status === 401) {
			window.location.href = "#/logout";
		} else {
			console.log("Anderer Fehler");
		}
		})
		.catch(function (error) {
		if (error.response) {
			// The server answered with an error
			if (error.response.status === 401) {
			window.location.href = "#/logout";
			} else {
			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);
			}
		} else if (error.request) {
			//  The request was made but the server didn't respond
			console.log(error.request);
		} else {
			//  Something else went wrong
			console.log("Error", error.message);
		}
	});
};

const setCampaignDetailError = (errMsg) => {
	dispatch({
		type: SET_CAMPAIGN_DETAIL_ERROR,
		payload: errMsg,
	});
};


/*----------------------------------
	GAS CAMPAIGN ASSIGNMENTS
----------------------------------*/


const closeGasCampaignAssignmentDialog = () => {
	dispatch({
		type: CLOSE_GAS_CAMPAIGN_ASSIGNMENT,
	});
};

const editGasCampaignAssignmentTimeframe = (id, activeAssignment) => {
	
	let title = (id > 0 ) ? "Zeitscheibe bearbeiten" : "Neue Zeitscheibe";

	let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
	let url = process.env.REACT_APP_API_URI + "/campaigns/get_gas_campaign_timeframe.php";

	const params = new URLSearchParams();
	params.append("jwt", sessionStorage.jwt);
	params.append("id", id);
	params.append("assignmentId", activeAssignment);


	axios
		.post(url, params, axiosConfig)
		.then((response) => {
		if (response.status === 200) {
			//   console.log(response.data);

			dispatch({
				type: EDIT_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME,
				payload: {
					activeGasCampaignAssignmentTimeframeId:		parseInt(id),
					gasCampaignAssignmentTimeframeDialogTitle:	title,
					gasCampaignAssignmentTimeframeClimatePlus:	response.data.climatePlus,
					gasCampaignAssignmentTimeframeBio5:			response.data.bio5,
					gasCampaignAssignmentTimeframeBio10:		response.data.bio10,
					gasCampaignAssignmentTimeframeValidFrom:	response.data.validFrom,
					gasCampaignAssignmentTimeframeValidTo:		response.data.validTo,
					gasCampaignAssignmentTimeframeIsNewYearsOnly:	parseInt(response.data.isNewYearsOnly),
					gasCampaignAssignmentTimeframeGp:			response.data.gp,
					gasCampaignAssignmentTimeframeAp:			response.data.ap,
					gasCampaignAssignmentTimeframeBehg:			response.data.behg,
					gasCampaignAssignmentTimeframeTehg:			response.data.tehg,
				},
			});

			if (Number.parseInt(response.data.isClimatePlus) === 1)
				document.getElementById("gasCampaignAssignmentTimeframeClimatePlus").disabled = false;

			if (Number.parseInt(response.data.isBio5) === 1)
				document.getElementById("gasCampaignAssignmentTimeframeBio5").disabled = false;

			if (Number.parseInt(response.data.isBio10) === 1)
				document.getElementById("gasCampaignAssignmentTimeframeBio10").disabled = false;

		} else if (response.status === 401) {
			window.location.href = "#/logout";
		} else {
			console.log("Anderer Fehler");
		}
		})
		.catch(function (error) {
		if (error.response) {
			// The server answered with an error
			if (error.response.status === 401) {
			window.location.href = "#/logout";
			} else {
			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);
			}
		} else if (error.request) {
			//  The request was made but the server didn't respond
			console.log(error.request);
		} else {
			//  Something else went wrong
			console.log("Error", error.message);
		}
	});
};

const gasCampaignAssignmentLoadProfilesIdChange = (e) => {

	if(state.gasCampaignAssignmentDialogError !== "" && state.gasCampaignAssignmentLoadProfilesId === -1)
		setGasCampaignAssignmentError("");

	dispatch({
		type: GAS_CAMPAIGN_ASSIGNMENT_LOAD_PROFILES_ID_CHANGE,
		payload: e.target.value,
	});
};

const gasCampaignAssignmentProductsIdChange = (e) => {

	if(state.gasCampaignAssignmentDialogError !== "" && state.gasCampaignAssignmentProductsId === -1)
		setGasCampaignAssignmentError("");
		
	dispatch({
		type: 		GAS_CAMPAIGN_ASSIGNMENT_PRODUCTS_ID_CHANGE,
		payload:	e.target.value,
	});
};

const gasCampaignAssignmentProductTypesIdChange = (e) => {

	if(state.gasCampaignAssignmentDialogError !== "" && state.gasCampaignAssignmentProductTypesId === -1)
		setGasCampaignAssignmentError("");

	dispatch({
		type: GAS_CAMPAIGN_ASSIGNMENT_PRODUCT_TYPES_ID_CHANGE,
		payload: e.target.value,
	});
};

const gasCampaignAssignmentProductValidFromChange = (date) => {

	if(state.gasCampaignAssignmentDialogError !== "" && state.gasCampaignAssignmentProductValidFrom === "")
		setGasCampaignAssignmentError("");

	dispatch({
		type: GAS_CAMPAIGN_ASSIGNMENT_PRODUCT_VALID_FROM_CHANGE,
		payload: date,
	});
};

const gasCampaignAssignmentTarifOldChange = (e) => {
	
	dispatch({
		type: GAS_CAMPAIGN_ASSIGNMENT_TARIF_OLD_CHANGE,
		payload: e.target.value,
	});
};

const getGasCampaignTimeframes = () => {

    let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
    let url = process.env.REACT_APP_API_URI + "/campaigns/get_gas_campaign_timeframes.php";

    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("activeCampaignAssignmentId", state.activeCampaignAssignmentId);

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {

            // console.log(response.data);
          dispatch({
            type: GET_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAMES,
            payload: {
              gasCampaignAssignmentTimeframes: response.data.assignments,
            },
          });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
};

const saveGasCampaignAssignment = () => {

	if (state.gasCampaignAssignmentProductTypesId < 0)
		return setGasCampaignAssignmentError("Bitte wählen Sie eine Produktart für die Zuordnung aus.");

	if(state.gasCampaignAssignmentLoadProfilesId < 0)
		return setGasCampaignAssignmentError("Bitte wählen Sie ein Lastprofil für die Zuordnung aus.");

	if(state.gasCampaignAssignmentProductsId < 0)
		return setGasCampaignAssignmentError("Bitte wählen Sie ein Produkt für die Zuordnung aus.");

	if(state.gasCampaignAssignmentProductValidFrom === "")
		return setGasCampaignAssignmentError("Bitte geben Sie an, ab wann das Produkt angelegt werden soll.");


	let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
	let url = process.env.REACT_APP_API_URI + "/campaigns/set_gas_campaign_assignment.php";

	const params = new URLSearchParams();
	params.append("jwt", sessionStorage.jwt);
	params.append("id", state.activeCampaignAssignmentId);
	params.append("campaignId", state.activeCampaignId);
	params.append("loadProfilesId", state.gasCampaignAssignmentLoadProfilesId);
	params.append("gasProductsId", state.gasCampaignAssignmentProductsId);
	params.append("gasProductTypesId", state.gasCampaignAssignmentProductTypesId);
	params.append("tarifOld", state.gasCampaignAssignmentTarifOld);

	const productValidFrom = (state.gasCampaignAssignmentProductValidFrom !== "") ? new Date(state.gasCampaignAssignmentProductValidFrom) : "";
	let newProductValidFrom = "";

	if (productValidFrom !== "") {

		const newDay	= productValidFrom.getDate();
		const newMonth 	= productValidFrom.getMonth() + 1;
		const newYear 	= productValidFrom.getFullYear();

		newProductValidFrom = newYear + "-" + newMonth + "-" + newDay;
	}
	params.append("productValidFrom", newProductValidFrom);


	axios
		.post(url, params, axiosConfig)
		.then((response) => {
		if (response.status === 200) {
			// console.log(response.data);

			dispatch({
			type: CLOSE_GAS_CAMPAIGN_ASSIGNMENT,
			});

			getCampaignAssignments();
		} else if (response.status === 401) {
			window.location.href = "#/logout";
		} else {
			console.log("Anderer Fehler");
		}
		})
		.catch(function (error) {
		if (error.response) {
			// The server answered with an error
			if (error.response.status === 401) {
			window.location.href = "#/logout";
			} else {
			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);
			}
		} else if (error.request) {
			//  The request was made but the server didn't respond
			console.log(error.request);
		} else {
			//  Something else went wrong
			console.log("Error", error.message);
		}
	});
};

const setGasCampaignAssignmentError = (errMsg) => {

	dispatch({
		type: 		SET_GAS_CAMPAIGN_ASSIGNMENT_ERROR,
		payload:	errMsg,
	});
}


/*------------------------------------------
	ENERGY CAMPAIGN ASSIGNMENT TIMEFRAMES
------------------------------------------*/

const closeEnergyCampaignAssignmentDialog = () => {
	dispatch({
		type: CLOSE_ENERGY_CAMPAIGN_ASSIGNMENT,
	});
};

const editEnergyCampaignAssignmentTimeframe = (id, activeAssignment) => {

	let title = (id > 0 ) ? "Zeitscheibe bearbeiten" : "Neue Zeitscheibe";

	let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
	let url = process.env.REACT_APP_API_URI + "/campaigns/get_energy_campaign_timeframe.php";

	const params = new URLSearchParams();
	params.append("jwt", sessionStorage.jwt);
	params.append("id", id);
	params.append("assignmentId", activeAssignment);

	axios
		.post(url, params, axiosConfig)
		.then((response) => {
		if (response.status === 200) {
			//   console.log(response.data);

			dispatch({
				type: EDIT_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME,
				payload: {
					activeEnergyCampaignAssignmentTimeframeId:			id,
					energyCampaignAssignmentTimeframeDialogTitle:		title,
					energyCampaignAssignmentTimeframeClimatePlus:		response.data.climatePlus,
					energyCampaignAssignmentTimeframeValidFrom:			response.data.validFrom,
					energyCampaignAssignmentTimeframeValidTo:			response.data.validTo,
					energyCampaignAssignmentTimeframeIsNewYearsOnly:	response.data.isNewYearsOnly,
					energyCampaignAssignmentTimeframeGp:				response.data.gp,
					energyCampaignAssignmentTimeframeAp:				response.data.ap,
					energyCampaignAssignmentTimeframeApNt:				response.data.apNt,
					energyCampaignAssignmentTimeframeIsApNt:			response.data.isApNt,
				},
			});

			if (Number.parseInt(response.data.isClimatePlus) === 1)
				document.getElementById("energyCampaignAssignmentTimeframeClimatePlus").disabled = false;

		} else if (response.status === 401) {
			window.location.href = "#/logout";
		} else {
			console.log("Anderer Fehler");
		}
		})
		.catch(function (error) {
		if (error.response) {
			// The server answered with an error
			if (error.response.status === 401) {
			window.location.href = "#/logout";
			} else {
			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);
			}
		} else if (error.request) {
			//  The request was made but the server didn't respond
			console.log(error.request);
		} else {
			//  Something else went wrong
			console.log("Error", error.message);
		}
	});
}

const getEnergyCampaignTimeframes = () => {

    let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
    let url = process.env.REACT_APP_API_URI + "/campaigns/get_energy_campaign_timeframes.php";

    const params = new URLSearchParams();
    params.append("jwt", sessionStorage.jwt);
    params.append("activeCampaignAssignmentId", state.activeCampaignAssignmentId);

    axios
      .post(url, params, axiosConfig)
      .then((response) => {
        if (response.status === 200) {

			// console.log(response.data);	
			dispatch({
				type: GET_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAMES,
				payload: {
					energyCampaignAssignmentTimeframes: response.data.assignments,
				},
	        });
        } else if (response.status === 401) {
          window.location.href = "#/logout";
        } else {
          console.log("Anderer Fehler");
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The server answered with an error
          if (error.response.status === 401) {
            window.location.href = "#/logout";
          } else {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        } else if (error.request) {
          //  The request was made but the server didn't respond
          console.log(error.request);
        } else {
          //  Something else went wrong
          console.log("Error", error.message);
        }
      });
};

const saveEnergyCampaignAssignment = () => {

	if(state.energyCampaignAssignmentProductTypesId < 0)
		return setEnergyCampaignAssignmentError("Bitte wählen Sie eine Produktart für die Zuordnung aus.");
		
	if(state.energyCampaignAssignmentLoadProfilesId < 0)
		return setEnergyCampaignAssignmentError("Bitte wählen Sie ein Lastprofil für die Zuordnung aus.");

	if(state.energyCampaignAssignmentProductsId < 0)
		return setEnergyCampaignAssignmentError("Bitte wählen Sie ein Produkt für die Zuordnung aus.");

	if(Number.parseInt(state.energyCampaignAssignmentLoadProfilesId) === 2 && state.energyCampaignAssignmentSwitchingTimesId < 0)
		return setEnergyCampaignAssignmentError("Bitte wählen Sie eine Schaltzeit für die Zuordnung aus.");

	if(state.energyCampaignAssignmentProductValidFrom === "")
		return setEnergyCampaignAssignmentError("Bitte geben Sie an, ab wann das Produkt angelegt werden soll.");


	let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
	let url = process.env.REACT_APP_API_URI + "/campaigns/set_energy_campaign_assignment.php";

	const params = new URLSearchParams();
	params.append("jwt", sessionStorage.jwt);
	params.append("id", state.activeCampaignAssignmentId);
	params.append("campaignId", state.activeCampaignId);
	params.append("loadProfilesId", state.energyCampaignAssignmentLoadProfilesId);
	params.append("energyProductsId", state.energyCampaignAssignmentProductsId);
	params.append("energyProductTypesId", state.energyCampaignAssignmentProductTypesId);
	params.append("switchingTimesId", state.energyCampaignAssignmentSwitchingTimesId);
	params.append("tarifOld", state.energyCampaignAssignmentTarifOld);

	const productValidFrom = (state.energyCampaignAssignmentProductValidFrom !== "") ? new Date(state.energyCampaignAssignmentProductValidFrom) : "";
	let newProductValidFrom = "";

	if (productValidFrom !== "") {

		const newDay	= productValidFrom.getDate();
		const newMonth 	= productValidFrom.getMonth() + 1;
		const newYear 	= productValidFrom.getFullYear();

		newProductValidFrom = newYear + "-" + newMonth + "-" + newDay;
	}
	params.append("productValidFrom", newProductValidFrom);


	axios
		.post(url, params, axiosConfig)
		.then((response) => {
		if (response.status === 200) {
			// console.log(response.data);

			dispatch({
			type: CLOSE_ENERGY_CAMPAIGN_ASSIGNMENT,
			});

			getCampaignAssignments();
		} else if (response.status === 401) {
			window.location.href = "#/logout";
		} else {
			console.log("Anderer Fehler");
		}
		})
		.catch(function (error) {
		if (error.response) {
			// The server answered with an error
			if (error.response.status === 401) {
			window.location.href = "#/logout";
			} else {
			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);
			}
		} else if (error.request) {
			//  The request was made but the server didn't respond
			console.log(error.request);
		} else {
			//  Something else went wrong
			console.log("Error", error.message);
		}
	});
}

const setEnergyCampaignAssignmentError = (errMsg) => {

	dispatch({
		type: SET_ENERGY_CAMPAIGN_ASSIGNMENT_ERROR,
		payload: errMsg,
	});
}

/*---------------------------------------
	GAS CAMPAIGN ASSIGNMENT TIMEFRAMES
---------------------------------------*/

const closeGasCampaignAssignmentTimeframeDialog = () => {

	dispatch({
		type: CLOSE_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_DIALOG
	});
}

const closeGasCampaignAssignmentTimeframeDeleteDialog = () => {
	dispatch({
		type: CLOSE_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_DELETE_DIALOG
	});
}

const deleteGasCampaignAssignmentTimeframeConfirm = (id) => {

	dispatch({
		type: DELETE_GAS_ASSIGNMENT_TIMEFRAME_CONFIRM,
		payload: id,
	});
}

const deleteGasCampaignAssignmentTimeframe = () => {

	let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
	let url = process.env.REACT_APP_API_URI + "/campaigns/delete_gas_campaign_assignment_timeframe.php";

	const params = new URLSearchParams();
	params.append("jwt", sessionStorage.jwt);
	params.append("id", state.activeGasCampaignAssignmentTimeframeId);

	axios
		.post(url, params, axiosConfig)
		.then((response) => {
		if (response.status === 200) {

			// console.log(response.data);
			closeGasCampaignAssignmentTimeframeDeleteDialog();
			getGasCampaignTimeframes();

		} else if (response.status === 401) {
			window.location.href = "#/logout";
		} else {
			console.log("Anderer Fehler");
		}
		})
		.catch(function (error) {
		if (error.response) {
			// The server answered with an error
			if (error.response.status === 401) {
			window.location.href = "#/logout";
			} else {
			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);
			}
		} else if (error.request) {
			//  The request was made but the server didn't respond
			console.log(error.request);
		} else {
			//  Something else went wrong
			console.log("Error", error.message);
		}
	});
};

const gasCampaignAssignmentTimeframeApChange = (e) => {

	if(state.gasCampaignAssignmentTimeframeDialogError !== "" && state.gasCampaignAssignmentTimeframeAp === "")
		setGasCampaignAssignmentError("");

	dispatch({
		type: GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_AP_CHANGE,
		payload: e.target.value,
	});
}

const gasCampaignAssignmentTimeframeBehgChange = (e) => {

	dispatch({
		type: GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_BEHG_CHANGE,
		payload: e.target.value,
	});
}

const gasCampaignAssignmentTimeframeBio5Change = (e) => {

	dispatch({
		type: GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_BIO5_CHANGE,
		payload: e.target.value
	});
}

const gasCampaignAssignmentTimeframeBio10Change = (e) => {

	dispatch({
		type: GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_BIO10_CHANGE,
		payload: e.target.value,
	});
}

const gasCampaignAssignmentTimeframeClimatePlusChange = (e) => {

	dispatch({
		type: GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_CLIMATE_PLUS_CHANGE,
		payload: e.target.value,
	});
}

const gasCampaignAssignmentTimeframeGpChange = (e) => {
	
	if(state.gasCampaignAssignmentTimeframeDialogError !== "" && state.gasCampaignAssignmentTimeframeGp === "")
		setGasCampaignAssignmentError("");

	dispatch({
		type: GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_GP_CHANGE,
		payload: e.target.value,
	});
}

const gasCampaignAssignmentTimeframeTehgChange = (e) => {

	dispatch({
		type: GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_TEHG_CHANGE,
		payload: e.target.value,
	});
}

const gasCampaignAssignmentTimeframeValidFromChange = (e) => {

	if(state.gasCampaignAssignmentTimeframeDialogError !== "" && (state.gasCampaignAssignmentTimeframeValidFrom === "" || Number.parseInt(state.gasCampaignAssignmentTimeframeValidFrom) < 2020 || Number.parseInt(state.gasCampaignAssignmentTimeframeValidFrom) > 2050))
		setGasCampaignAssignmentTimeframeError("");

	if(state.gasCampaignAssignmentTimeframeIsNewYearsOnly === 1) {

		dispatch({
			type: GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_VALID_FROM_CHANGE,
			payload: e.target.value,
		});

		dispatch({
			type: GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_VALID_TO_CHANGE,
			payload: e.target.value,
		});
	} else {
		
		dispatch({
			type: GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_VALID_FROM_CHANGE,
			payload: e.target.value,
		});
	}
}

const gasCampaignAssignmentTimeframeValidToChange = (e) => {

	if(state.gasCampaignAssignmentTimeframeDialogError !== "" && (state.gasCampaignAssignmentTimeframeValidTo === "" || Number.parseInt(state.gasCampaignAssignmentTimeframeValidTo) < 2020 || Number.parseInt(state.gasCampaignAssignmentTimeframeValidTo) > 2050))
		setGasCampaignAssignmentTimeframeError("");

	dispatch({
		type: GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_VALID_TO_CHANGE,
		payload: e.target.value,
	});
}

const saveGasCampaignAssignmentTimeframe = () => {

	//	Check mandatory fields
	if(state.gasCampaignAssignmentTimeframeValidFrom === "" ||
        Number.parseInt(state.gasCampaignAssignmentTimeframeValidFrom) < 2020 ||
        Number.parseInt(state.gasCampaignAssignmentTimeframeValidFrom ) > 2050) {
			
			return setGasCampaignAssignmentTimeframeError("Bitte geben Sie einen validen Beginn für die Laufzeit der Zeitscheibe ein.");
	}

	if(state.gasCampaignAssignmentTimeframeValidTo === "" ||
		Number.parseInt(state.gasCampaignAssignmentTimeframeValidTo) < 2020 ||
		Number.parseInt(state.gasCampaignAssignmentTimeframeValidTo) > 2050)	{

			return setGasCampaignAssignmentTimeframeError("Bitte geben Sie ein valides Ende für die Laufzeit der Zeitscheibe ein.");
	}

	if(state.gasCampaignAssignmentTimeframeGp === "")
		return setGasCampaignAssignmentTimeframeError("Bitte geben Sie einen Grundpreis für die Zeitscheibe ein.");
		
	if(state.gasCampaignAssignmentTimeframeAp === "")
		return setGasCampaignAssignmentTimeframeError("Bitte geben Sie einen Arbeitspreis für die Zeitscheibe ein.");

	
	let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
	let url = process.env.REACT_APP_API_URI + "/campaigns/set_gas_campaign_assignment_timeframe.php";

	let gp = state.gasCampaignAssignmentTimeframeGp.replace(".", "").replace(",", ".") * 1;
	let ap = state.gasCampaignAssignmentTimeframeAp.replace(".", "").replace(",", ".") * 1;
	let climatePlus = state.gasCampaignAssignmentTimeframeClimatePlus.replace(".", "").replace(",", ".") * 1;
	let bio5 = state.gasCampaignAssignmentTimeframeBio5.replace(".", "").replace(",", ".") * 1;
	let bio10 = state.gasCampaignAssignmentTimeframeBio10.replace(".", "").replace(",", ".") * 1;
	let behg = state.gasCampaignAssignmentTimeframeBehg.replace(".", "").replace(",", ".") * 1;
	let tehg = state.gasCampaignAssignmentTimeframeTehg.replace(".", "").replace(",", ".") * 1;

	const params = new URLSearchParams();
	params.append("jwt", sessionStorage.jwt);
	params.append("id", state.activeGasCampaignAssignmentTimeframeId);
	params.append("gasCampaignAssignmentId", state.activeCampaignAssignmentId);
	params.append("validFrom", state.gasCampaignAssignmentTimeframeValidFrom);
	params.append("validTo", state.gasCampaignAssignmentTimeframeValidTo);
	params.append("isNewYearsOnly", state.gasCampaignAssignmentTimeframeIsNewYearsOnly);
	params.append("gp", gp);
	params.append("ap", ap);
	params.append("climatePlus", climatePlus);
	params.append("bio5", bio5);
	params.append("bio10", bio10);
	params.append("behg", behg);
	params.append("tehg", tehg);

	axios
		.post(url, params, axiosConfig)
		.then((response) => {
		if (response.status === 200) {
			// console.log(response.data);

			dispatch({
			type: CLOSE_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_DIALOG,
			});

			getGasCampaignTimeframes();
		} else if (response.status === 401) {
			window.location.href = "#/logout";
		} else {
			console.log("Anderer Fehler");
		}
		})
		.catch(function (error) {
		if (error.response) {
			// The server answered with an error
			if (error.response.status === 401) {
			window.location.href = "#/logout";
			} else {
			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);
			}
		} else if (error.request) {
			//  The request was made but the server didn't respond
			console.log(error.request);
		} else {
			//  Something else went wrong
			console.log("Error", error.message);
		}
	});
}

const setGasCampaignAssignmentTimeframeError = (errMsg) => {

	dispatch({
		type: SET_GAS_CAMPAIGN_ASSIGNMENT_TIMEFRAME_ERROR,
		payload: errMsg,
	});
}


/*--------------------------------------------
	ENERGY CAMPAIGN ASSIGNMENT TIMEFRAMES
--------------------------------------------*/

const closeEnergyCampaignAssignmentTimeframeDialog = () => {

	dispatch({
		type: CLOSE_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_DIALOG
	});
}

const closeEnergyCampaignAssignmentTimeframeDeleteDialog = () => {
	dispatch({
		type: CLOSE_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_DELETE_DIALOG
	});
}

const deleteEnergyCampaignAssignmentTimeframe = () => {

	let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
	let url = process.env.REACT_APP_API_URI + "/campaigns/delete_energy_campaign_assignment_timeframe.php";

	const params = new URLSearchParams();
	params.append("jwt", sessionStorage.jwt);
	params.append("id", state.activeEnergyCampaignAssignmentTimeframeId);

	axios
		.post(url, params, axiosConfig)
		.then((response) => {
		if (response.status === 200) {

			// console.log(response.data);
			closeEnergyCampaignAssignmentTimeframeDeleteDialog();
			getEnergyCampaignTimeframes();

		} else if (response.status === 401) {
			window.location.href = "#/logout";
		} else {
			console.log("Anderer Fehler");
		}
		})
		.catch(function (error) {
		if (error.response) {
			// The server answered with an error
			if (error.response.status === 401) {
			window.location.href = "#/logout";
			} else {
			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);
			}
		} else if (error.request) {
			//  The request was made but the server didn't respond
			console.log(error.request);
		} else {
			//  Something else went wrong
			console.log("Error", error.message);
		}
	});
};

const deleteEnergyCampaignAssignmentTimeframeConfirm = (id) => {

	dispatch({
		type: DELETE_ENERGY_ASSIGNMENT_TIMEFRAME_CONFIRM,
		payload: id,
	});
}

const energyCampaignAssignmentTimeframeApChange = (e) => {

	if(state.energyCampaignAssignmentTimeframeDialogError !== "" && state.energyCampaignAssignmentTimeframeAp === "")
		setEnergyCampaignAssignmentError("");

	dispatch({
		type: ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_AP_CHANGE,
		payload: e.target.value,
	});
}

const energyCampaignAssignmentTimeframeApNtChange = (e) => {

	if(state.energyCampaignAssignmentTimeframeDialogError !== "" && Number.parseInt(state.isApNt) === 1 && state.energyCampaignAssignmentTimeframeApNt === "")
		setEnergyCampaignAssignmentError("");

	dispatch({
		type: ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_AP_NT_CHANGE,
		payload: e.target.value,
	});
}

const energyCampaignAssignmentTimeframeClimatePlusChange = (e) => {

	dispatch({
		type: ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_CLIMATE_PLUS_CHANGE,
		payload: e.target.value,
	});
}

const energyCampaignAssignmentTimeframeGpChange = (e) => {
	
	if(state.energyCampaignAssignmentTimeframeDialogError !== "" && state.energyCampaignAssignmentTimeframeGp === "")
		setEnergyCampaignAssignmentError("");

	dispatch({
		type: ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_GP_CHANGE,
		payload: e.target.value,
	});
}


const energyCampaignAssignmentTimeframeValidFromChange = (e) => {
	
	if(state.energyCampaignAssignmentTimeframeDialogError !== "" && (state.energyCampaignAssignmentTimeframeValidFrom === "" || Number.parseInt(state.energyCampaignAssignmentTimeframeValidFrom) < 2020 || Number.parseInt(state.energyCampaignAssignmentTimeframeValidFrom) > 2050))
		setEnergyCampaignAssignmentTimeframeError("");

	if(state.energyCampaignAssignmentTimeframeIsNewYearsOnly === 1) {

		dispatch({
			type: ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_VALID_FROM_CHANGE,
			payload: e.target.value,
		});

		dispatch({
			type: ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_VALID_TO_CHANGE,
			payload: e.target.value,
		});
	} else {

		dispatch({
			type: ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_VALID_FROM_CHANGE,
			payload: e.target.value,
		});
	}
}

const energyCampaignAssignmentTimeframeValidToChange = (e) => {

	if(state.energyCampaignAssignmentTimeframeDialogError !== "" && (state.energyCampaignAssignmentTimeframeValidTo === "" || Number.parseInt(state.energyCampaignAssignmentTimeframeValidTo) < 2020 || Number.parseInt(state.energyCampaignAssignmentTimeframeValidTo) > 2050))
		setEnergyCampaignAssignmentTimeframeError("");

	dispatch({
		type: ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_VALID_TO_CHANGE,
		payload: e.target.value,
	});
}

const saveEnergyCampaignAssignmentTimeframe = () => {

	//	Check mandatory fields
	if(state.energyCampaignAssignmentTimeframeValidFrom === "" ||
        Number.parseInt(state.energyCampaignAssignmentTimeframeValidFrom) < 2020 ||
        Number.parseInt(state.energyCampaignAssignmentTimeframeValidFrom ) > 2050) {
			
			return setEnergyCampaignAssignmentTimeframeError("Bitte geben Sie einen validen Beginn für die Laufzeit der Zeitscheibe ein.");
	}

	if(state.energyCampaignAssignmentTimeframeValidTo === "" ||
		Number.parseInt(state.energyCampaignAssignmentTimeframeValidTo) < 2020 ||
		Number.parseInt(state.energyCampaignAssignmentTimeframeValidTo) > 2050)	{

			return setEnergyCampaignAssignmentTimeframeError("Bitte geben Sie ein valides Ende für die Laufzeit der Zeitscheibe ein.");
	}

	if(state.energyCampaignAssignmentTimeframeGp === "")
		return setEnergyCampaignAssignmentTimeframeError("Bitte geben Sie einen Grundpreis für die Zeitscheibe ein.");
		
	if(Number.parseInt(state.energyCampaignAssignmentTimeframeIsApNt) === 0 && state.energyCampaignAssignmentTimeframeAp === "")
		return setEnergyCampaignAssignmentTimeframeError("Bitte geben Sie einen Arbeitspreis für die Zeitscheibe ein.");

	if(Number.parseInt(state.energyCampaignAssignmentTimeframeIsApNt) === 1 && state.energyCampaignAssignmentTimeframeAp === "")
		return setEnergyCampaignAssignmentTimeframeError("Bitte geben Sie einen Arbeitspreis HT für die Zeitscheibe ein.");

	if(Number.parseInt(state.energyCampaignAssignmentTimeframeIsApNt) === 1 && state.energyCampaignAssignmentTimeframeApNt === "")
		return setEnergyCampaignAssignmentTimeframeError("Bitte geben Sie einen Arbeitspreis NT für die Zeitscheibe ein.");

	
	let axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
	let url = process.env.REACT_APP_API_URI + "/campaigns/set_energy_campaign_assignment_timeframe.php";

	let gp = state.energyCampaignAssignmentTimeframeGp.replace(".", "").replace(",", ".") * 1;
	let ap = state.energyCampaignAssignmentTimeframeAp.replace(".", "").replace(",", ".") * 1;
	let apNt = state.energyCampaignAssignmentTimeframeApNt.replace(".", "").replace(",", ".") * 1;
	let climatePlus = state.energyCampaignAssignmentTimeframeClimatePlus.replace(".", "").replace(",", ".") * 1;
	
	const params = new URLSearchParams();
	params.append("jwt", sessionStorage.jwt);
	params.append("id", state.activeEnergyCampaignAssignmentTimeframeId);
	params.append("energyCampaignAssignmentId", state.activeCampaignAssignmentId);
	params.append("validFrom", state.energyCampaignAssignmentTimeframeValidFrom);
	params.append("validTo", state.energyCampaignAssignmentTimeframeValidTo);
	params.append("isNewYearsOnly", state.energyCampaignAssignmentTimeframeIsNewYearsOnly);
	params.append("gp", gp);
	params.append("ap", ap);
	params.append("apNt", apNt);
	params.append("climatePlus", climatePlus);
	
	axios
		.post(url, params, axiosConfig)
		.then((response) => {
		if (response.status === 200) {
			// console.log(response.data);

			dispatch({
				type: CLOSE_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_DIALOG,
			});

			getEnergyCampaignTimeframes();
		} else if (response.status === 401) {
			window.location.href = "#/logout";
		} else {
			console.log("Anderer Fehler");
		}
		})
		.catch(function (error) {
		if (error.response) {
			// The server answered with an error
			if (error.response.status === 401) {
			window.location.href = "#/logout";
			} else {
			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);
			}
		} else if (error.request) {
			//  The request was made but the server didn't respond
			console.log(error.request);
		} else {
			//  Something else went wrong
			console.log("Error", error.message);
		}
	});
}

const setEnergyCampaignAssignmentTimeframeError = (errMsg) => {

	dispatch({
		type: SET_ENERGY_CAMPAIGN_ASSIGNMENT_TIMEFRAME_ERROR,
		payload: errMsg,
	});
}

  return (
    <CampaignsContext.Provider
      value={{
		  //	Variables
		  activeCampaignAssignmentId:							state.activeCampaignAssignmentId,
		  activeEnergyCampaignAssignmentTimeframeId:			state.activeEnergyCampaignAssignmentTimeframeId,
		  activeGasCampaignAssignmentTimeframeId:				state.activeGasCampaignAssignmentTimeframeId,
		  activeCampaignId:										state.activeCampaignId,
		  campaignDetailDialogError:							state.campaignDetailDialogError,
		  campaignDetailDialogState:							state.campaignDetailDialogState,
		  campaignDetailDialogTitle:							state.campaignDetailDialogTitle,
		  campaignDivisions:									state.campaignDivisions,
		  campaignDivisionsId:									state.campaignDivisionsId,
		  campaignName:											state.campaignName,
		  campaigns:											state.campaigns,
		  deleteCampaignAssignmentConfirmName:					state.deleteCampaignAssignmentConfirmName,
		  deleteCampaignAssignmentConfirmState:					state.deleteCampaignAssignmentConfirmState,
		  deleteCampaignConfirmName:							state.deleteCampaignConfirmName,
		  deleteCampaignConfirmState:							state.deleteCampaignConfirmState,
		  energyCampaignAssignmentDialogError:					state.energyCampaignAssignmentDialogError,
		  energyCampaignAssignmentDialogState:					state.energyCampaignAssignmentDialogState,
		  energyCampaignAssignmentDialogTitle:					state.energyCampaignAssignmentDialogTitle,
		  energyCampaignAssignments:							state.energyCampaignAssignments,
		  energyCampaignAssignmentLoadProfiles:					state.energyCampaignAssignmentLoadProfiles,
		  energyCampaignAssignmentLoadProfilesId:				state.energyCampaignAssignmentLoadProfilesId,
		  energyCampaignAssignmentProducts:						state.energyCampaignAssignmentProducts,
		  energyCampaignAssignmentProductsId:					state.energyCampaignAssignmentProductsId,
		  energyCampaignAssignmentProductTypes:					state.energyCampaignAssignmentProductTypes,
		  energyCampaignAssignmentProductTypesId:				state.energyCampaignAssignmentProductTypesId,
		  energyCampaignAssignmentProductValidFrom:				state.energyCampaignAssignmentProductValidFrom,
		  energyCampaignAssignmentSwitchingTimes:				state.energyCampaignAssignmentSwitchingTimes,
		  energyCampaignAssignmentSwitchingTimesId:				state.energyCampaignAssignmentSwitchingTimesId,
		  energyCampaignAssignmentTarifOld:						state.energyCampaignAssignmentTarifOld,
		  energyCampaignAssignmentTimeframes:					state.energyCampaignAssignmentTimeframes,
		  energyCampaignAssignmentTimeframeDialogError:			state.energyCampaignAssignmentTimeframeDialogError,
		  energyCampaignAssignmentTimeframeDialogState:			state.energyCampaignAssignmentTimeframeDialogState,
		  energyCampaignAssignmentTimeframeDialogTitle:			state.energyCampaignAssignmentTimeframeDialogTitle,
		  energyCampaignAssignmentTimeframeDeleteDialogName:	state.energyCampaignAssignmentTimeframeDeleteDialogName,
		  energyCampaignAssignmentTimeframeDeleteDialogState:	state.energyCampaignAssignmentTimeframeDeleteDialogState,
		  energyCampaignAssignmentTimeframeAp:					state.energyCampaignAssignmentTimeframeAp,
		  energyCampaignAssignmentTimeframeApNt:				state.energyCampaignAssignmentTimeframeApNt,
		  energyCampaignAssignmentTimeframeClimatePlus:			state.energyCampaignAssignmentTimeframeClimatePlus,
		  energyCampaignAssignmentTimeframeGp:					state.energyCampaignAssignmentTimeframeGp,
		  energyCampaignAssignmentTimeframeIsApNt:				state.energyCampaignAssignmentTimeframeIsApNt,
		  energyCampaignAssignmentTimeframeIsNewYearsOnly:		state.energyCampaignAssignmentTimeframeIsNewYearsOnly,
		  energyCampaignAssignmentTimeframeValidFrom:			state.energyCampaignAssignmentTimeframeValidFrom,
		  energyCampaignAssignmentTimeframeValidTo:				state.energyCampaignAssignmentTimeframeValidTo,
		  energyProducts:										state.energyProducts,
		  energyProductTypes:									state.energyProductTypes,
		  gasCampaignAssignments:								state.gasCampaignAssignments,
		  gasCampaignAssignmentDeleteDialogName:				state.gasCampaignAssignmentDeleteDialogName,
		  gasCampaignAssignmentDeleteDialogState:				state.gasCampaignAssignmentDeleteDialogState,
		  gasCampaignAssignmentDialogError:						state.gasCampaignAssignmentDialogError,
		  gasCampaignAssignmentDialogState:						state.gasCampaignAssignmentDialogState,
		  gasCampaignAssignmentDialogTitle:						state.gasCampaignAssignmentDialogTitle,
		  gasCampaignAssignmentLoadProfiles:					state.gasCampaignAssignmentLoadProfiles,
		  gasCampaignAssignmentLoadProfilesId:					state.gasCampaignAssignmentLoadProfilesId,
		  gasCampaignAssignmentProducts:						state.gasCampaignAssignmentProducts,
		  gasCampaignAssignmentProductsId:						state.gasCampaignAssignmentProductsId,
		  gasCampaignAssignmentProductTypes:					state.gasCampaignAssignmentProductTypes,
		  gasCampaignAssignmentProductTypesId:					state.gasCampaignAssignmentProductTypesId,
		  gasCampaignAssignmentProductValidFrom:				state.gasCampaignAssignmentProductValidFrom,
		  gasCampaignAssignmentTarifOld:						state.gasCampaignAssignmentTarifOld,
		  gasCampaignAssignmentTimeframes:						state.gasCampaignAssignmentTimeframes,
		  gasCampaignAssignmentTimeframeDialogError:			state.gasCampaignAssignmentTimeframeDialogError,
		  gasCampaignAssignmentTimeframeDialogState:			state.gasCampaignAssignmentTimeframeDialogState,
		  gasCampaignAssignmentTimeframeDialogTitle:			state.gasCampaignAssignmentTimeframeDialogTitle,
		  gasCampaignAssignmentTimeframeDeleteDialogName:		state.gasCampaignAssignmentTimeframeDeleteDialogName,
		  gasCampaignAssignmentTimeframeDeleteDialogState:		state.gasCampaignAssignmentTimeframeDeleteDialogState,
		  gasCampaignAssignmentTimeframeAp:						state.gasCampaignAssignmentTimeframeAp,
		  gasCampaignAssignmentTimeframeBehg:					state.gasCampaignAssignmentTimeframeBehg,
		  gasCampaignAssignmentTimeframeBio5:					state.gasCampaignAssignmentTimeframeBio5,
		  gasCampaignAssignmentTimeframeBio10:					state.gasCampaignAssignmentTimeframeBio10,
		  gasCampaignAssignmentTimeframeClimatePlus:			state.gasCampaignAssignmentTimeframeClimatePlus,
		  gasCampaignAssignmentTimeframeGp:						state.gasCampaignAssignmentTimeframeGp,
		  gasCampaignAssignmentTimeframeIsNewYearsOnly:			state.gasCampaignAssignmentTimeframeIsNewYearsOnly,
		  gasCampaignAssignmentTimeframeTehg:					state.gasCampaignAssignmentTimeframeTehg,
		  gasCampaignAssignmentTimeframeValidFrom:				state.gasCampaignAssignmentTimeframeValidFrom,
		  gasCampaignAssignmentTimeframeValidTo:				state.gasCampaignAssignmentTimeframeValidTo,
		  gasProducts:											state.gasProducts,
		  gasProductTypes:										state.gasProductTypes,
		  loadProfiles:											state.loadProfiles,
		  switchingTimes:										state.switchingTimes,

		  //	Methods
		  campaignDivisionsIdChange,
		  campaignNameChange,
		  closeCampaignDetailDialog,
		  closeDeleteCampaignConfirm,
		  closeCampaignAssignmentDeleteDialog,
		  closeEnergyCampaignAssignmentDialog,
		  closeEnergyCampaignAssignmentTimeframeDialog,
		  closeEnergyCampaignAssignmentTimeframeDeleteDialog,
		  closeGasCampaignAssignmentDialog,
		  closeGasCampaignAssignmentTimeframeDialog,
		  closeGasCampaignAssignmentTimeframeDeleteDialog,
		  deleteCampaign,
		  deleteCampaignAssignment,
		  deleteCampaignAssignmentConfirm,
		  deleteCampaignConfirm,
		  deleteEnergyCampaignAssignmentTimeframe,
		  deleteEnergyCampaignAssignmentTimeframeConfirm,
		  deleteGasCampaignAssignmentTimeframe,
		  deleteGasCampaignAssignmentTimeframeConfirm,
		  editCampaign,
		  editCampaignAssignment,
		  editEnergyCampaignAssignment,
		  editEnergyCampaignAssignmentTimeframe,
		  editGasCampaignAssignment,
		  editGasCampaignAssignmentTimeframe,
		  energyCampaignAssignmentLoadProfilesIdChange,
		  energyCampaignAssignmentProductsIdChange,
		  energyCampaignAssignmentProductTypesIdChange,
		  energyCampaignAssignmentProductValidFromChange,
		  energyCampaignAssignmentTarifOldChange,
		  energyCampaignAssignmentSwitchingTimesIdChange,
		  energyCampaignAssignmentTimeframeApChange,
		  energyCampaignAssignmentTimeframeApNtChange,
		  energyCampaignAssignmentTimeframeClimatePlusChange,
		  energyCampaignAssignmentTimeframeGpChange,
		  energyCampaignAssignmentTimeframeValidFromChange,
		  energyCampaignAssignmentTimeframeValidToChange,
		  gasCampaignAssignmentLoadProfilesIdChange,
		  gasCampaignAssignmentProductsIdChange,
		  gasCampaignAssignmentProductTypesIdChange,
		  gasCampaignAssignmentProductValidFromChange,
		  gasCampaignAssignmentTarifOldChange,
		  gasCampaignAssignmentTimeframeApChange,
		  gasCampaignAssignmentTimeframeBehgChange,
		  gasCampaignAssignmentTimeframeBio5Change,
		  gasCampaignAssignmentTimeframeBio10Change,
		  gasCampaignAssignmentTimeframeClimatePlusChange,
		  gasCampaignAssignmentTimeframeGpChange,
		  gasCampaignAssignmentTimeframeTehgChange,
		  gasCampaignAssignmentTimeframeValidFromChange,
		  gasCampaignAssignmentTimeframeValidToChange,
		  getCampaignAssignments,
		  getCampaigns,
		  getEnergyCampaignTimeframes,
		  getGasCampaignTimeframes,
		  saveCampaignDetailDialog,
		  saveEnergyCampaignAssignment,
		  saveEnergyCampaignAssignmentTimeframe,
		  saveGasCampaignAssignment,
		  saveGasCampaignAssignmentTimeframe,
		  setCampaignDetailError,
		  setCampaignForeigns,
		  setEnergyCampaignAssignmentError,
		  setEnergyCampaignAssignmentTimeframeError,
		  setGasCampaignAssignmentError,
		  setGasCampaignAssignmentTimeframeError,

      }}
    >
      {props.children}
    </CampaignsContext.Provider>
  );
};

export default CampaignsState;
